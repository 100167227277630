import React from 'react'
import {withGoogleMap, GoogleMap, Marker} from 'react-google-maps'

const noop = () => {}
const defaultOptions = {
  scrollwheel: false,
  zoomControl: false,
  scaleControl: false,
  navigationControl: false,
  mapTypeControl: false,
  disableDoubleClickZoom: false,
  fullscreenControl: false
}

export const GmapUrl = (latlng = '', marker) => {
  latlng = typeof latlng == 'object' ? latlng.string || `${latlng.lat},${latlng.lng}` : latlng
  marker = marker ? `/place/${marker}` : ''
  return `https://www.google.com/maps${marker}/@${latlng},17z`
}

const toMarker = (latlng) => ({
  position: latlng
  // defaultAnimation: 2
})

/*
 * Add <script src="https://maps.googleapis.com/maps/api/js"></script> to your HTML to provide google.maps reference
 */
const RawMap = withGoogleMap(
  ({
    zoom = 15,
    center,
    onMapLoad,
    onMapClick,
    onMapRightClick,
    markers = [],
    onMarkerClick = () => {},
    onMarkerRightClick = () => {}
  } = {}) => (
    <GoogleMap
      ref={onMapLoad || noop}
      options={defaultOptions}
      defaultZoom={zoom}
      defaultCenter={center || {}}
      onClick={onMapClick}
      onRightClick={
        onMapRightClick ||
        (() =>
          // by default rightClick open map in gmaps website
          window.open(GmapUrl(center), '_blank'))
      }
    >
      {markers.map((marker, i) => (
        <Marker
          {...marker}
          key={i}
          onClick={() => onMarkerClick(marker)}
          onRightClick={() => onMarkerRightClick(marker)}
        />
      ))}
    </GoogleMap>
  )
)

const MapView = (props) => {
  const {className = 'mapview-container', borderRadius = '4px'} = props
  return (
    <div className={className} style={{height: props.height || `300px`}}>
      <RawMap
        containerElement={<div style={{height: `100%`}} />}
        mapElement={<div style={{height: `100%`, borderRadius}} />}
        {...props}
      />
    </div>
  )
}

export const MapViewFromLatLng = (props) => {
  const {latlng} = props
  const markers = [toMarker(latlng)]
  return <MapView center={latlng} markers={markers} {...props} />
}

export default MapView
