import React from 'react'

const InputQuantities = ({
  onChange = () => {},
  value = 1,
  min = 1,
  max = 100,
  step = 1,
  className = '',
  textValue = () => {}
}) => {
  const incrementValue = () => {
    let newValue = value + step
    if (newValue > max) newValue = max

    onChange(newValue)
  }

  const decrementValue = () => {
    let newValue = value - 1
    if (newValue < min) newValue = min

    onChange(newValue)
  }

  return (
    <div className={`${className}`}>
      <div className='d-flex flex-row align-items-center justify-content-center h-100'>
        <div className='cursor' onClick={decrementValue}>
          <i className='text-primary fas fa-minus-circle' />
        </div>
        <div className='flex-fill text-center mx-1'>{textValue(value)}</div>
        <div className='cursor' onClick={incrementValue}>
          <i className='text-primary fas fa-plus-circle' />
        </div>
      </div>

      <style jsx>{`
        i {
          font-size: 20px;
        }
      `}</style>
    </div>
  )
}

export default InputQuantities
