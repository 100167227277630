import React, {useEffect, useState} from 'react'
import {withGlobal} from '../../shared/app'
import Timeline from '../../widgets/timeline'
import Login from '../../shared/login'
import CardProfile from '../cards/card-profile'

const Header = () => (
  <div className='text-center mx-auto py-3'>
    <img
      src='/img/brand/logo.png'
      className='logo'
      alt='inmemori.com'
      height='80px'
      style={{filter: 'invert(0)'}}
    />
  </div>
)

const BaseLine = ({page, t}) => {
  return (
    <div className='mt-auto mb-auto d-flex flex-column mx-auto w-100'>
      <div className='col-10 mx-auto text-left text-md-center'>
        <div className='h1' dangerouslySetInnerHTML={{__html: t(`onboarding.user.h1`, page)}} />
      </div>

      <style jsx>
        {`
          @media (max-width: 991px) {
            // lg down
            h1 {
              font-size: 28px;
            }
          }
          @media (max-width: 500px) {
            // sm down
            h1 {
              font-size: 22px;
            }
          }
        `}
      </style>
    </div>
  )
}

const Onboarding = ({t, page, flow, authed, cloudinary}) => {
  const [step, setStep] = useState('home')

  useEffect(() => {
    if (authed) {
      flow.set('tab-infos')
      return
    }

    const h = $(window).height()
    $('.splash-right').height(h)
    const h2 = $('.splash-left').height()
    $('.splash-right').height(h2)
  }, [])

  return (
    <div className='splashbox h-100'>
      <div className='d-flex d-md-none flex-column justify-content-between h-100'>
        {' '}
        {/*Mobile Layout*/}
        <CardProfile forceDisplay />
        {step === 'home' && <Timeline />}
        <div
          className={`d-flex flex-column ${step === 'home' ? 'fix-footer' : 'flex-grow-1'} w-100`}>
          <Login
            needSignup={() => setStep('signup')}
            className={`${step === 'signup' ? 'flex-grow-1' : ''}`}
            errorsScope='errors-scope-mobile'
          />
        </div>
      </div>

      <div className='d-none d-md-flex h-100'>
        {' '}
        {/*Desktop Layout*/}
        <div className='splash-left container-fluid d-flex flex-column justify-content-between px-0'>
          <div className='d-flex flex-column flex-grow-1 justify-content-between'>
            <Header />
            <BaseLine page={page} t={t} cloudinary={cloudinary} />
          </div>
          <Login className='flex-grow-1' errorsScope='errors-scope-desktop' />
        </div>
        <div className='splash-right px-4'>
          <Timeline />
        </div>
      </div>

      <style jsx>
        {`
          .splashbox {
            background: rgb(255, 255, 255);
            // background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
          .splash-left {
            flex: 1;
          }
          .splash-right {
            flex: 1.4;
          }

          .fix-footer {
            position: fixed !important;
            bottom: 0;
            padding-top: 60px;
            background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 1) 84%,
              rgba(255, 255, 255, 0) 100%
            );
          }

          @media (max-width: 767px) {
            // md down
            .splash-right {
              display: none;
            }
          }

          @media (max-width: 991px) {
            // lg down
            .splash-right {
              flex: 1;
            }
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Onboarding)
