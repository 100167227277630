import React, {useState, useEffect, useMemo, useCallback} from 'react'
import {withGlobal} from '../../shared/app'
import Popup from '../../../lib/popup'
import {string, scrollTo} from '../../../lib/utils'

import {Controlled as ControlledZoom} from 'react-medium-image-zoom'
import LazyLoad, {forceCheck} from 'react-lazyload'
import {Btn, BtnNext, BtnPrev} from '../../misc/buttons'
import {BigMenu, ActionMenu, DotBtn} from '../../misc/actions-menu'
import Timeline from '../../widgets/timeline'
import Spinner from '../../misc/spinner'
import Products from '../../../lib/products'

const Block = ({
  _profile,
  hasRole,
  page,
  authed,
  t,
  flow,
  api,
  cloudinary,
  toaster,
  Memories,
  query,
  currentStep,
  fullscreen,
  setFullscreen,
  thankHelper = false,
  panel,
  splash = false,
  setNavbarHidden,
  Products
}) => {
  const steps = {
    memory: {
      key: 'memory',
      title: t('tab-memories.segment.memory.title'),
      category: 'memory',
      menuItems: [1, 2, 3]
    },
    ceremony: {
      key: 'ceremony',
      title: t('tab-memories.segment.ceremony.title'),
      category: 'ceremony',
      menuItems: [4, 5]
    }
  }

  const gridGap = '20px'
  const canEdit = (memory) =>
    hasRole('manager') || (memory.profile && _profile._id == memory.profile)

  const [activeStep, _setActiveStep] = useState((currentStep && steps[currentStep]) || steps.memory)

  const [isMenuOpen, toggleMenu] = useState(false)
  const [isZoomed, setIsZoomed] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const handleZoomChange = useCallback((shouldZoom, img) => {
    if (shouldZoom) setIsZoomed(img)
    else setIsZoomed(false)
  }, [])

  useEffect(() => {
    if (currentStep && steps[currentStep]) setActiveStep(steps[currentStep])
    else if (query.rubric && steps[query.rubric]) setActiveStep(steps[query.rubric])
  }, [])

  const setActiveStep = async (step) => {
    _setActiveStep(step)
    Memories.load(step)
  }

  const memories = useMemo(() => {
    return Memories.list || []
  }, [activeStep, Memories.list])

  const menuItems = useMemo(() => {
    return (t(`new-memory.menu`, true) || []).filter((m) => activeStep.menuItems.includes(m.type))
  }, [activeStep])

  const justifyBigMenu = useMemo(() => {
    return activeStep.category === 'memory' ? 'between' : 'around'
  }, [activeStep])

  const longMode = Memories.list && Memories.list.length > 49

  const editActions = (memory) => {
    let actions = ''
    let ownMemory = memory.profile && _profile._id == memory.profile

    if (hasRole('team')) actions = 'edit,delete,thank,convert'
    else if (hasRole('manager')) actions = ownMemory ? 'edit,delete,thank' : 'delete,thank'
    else if (ownMemory) actions = 'edit,delete'
    actions = actions.split(',')
    return actions
  }

  const deletePopup = (memory) => {
    Popup.areYouSure(t(`popups.delete-memory`, true), () => deleteMemory(memory))
  }

  const deleteMemory = async (memory) => {
    try {
      Memories.delete(memory)
      toaster.success(t(`popups.delete-memory.success`))
    } catch (e) {
      if (e.message) toaster.error(e.message)
      else toaster.error()
    }
  }

  const endSplash = () => {
    flow.set('tab-infos')

    try {
      api.post(`/profiles/checklist/splash-manager/true`)
    } catch (e) {
      console.log(e)
    }
  }

  const transfertMemory = async (m) => {
    try {
      const body = {type: m.category === 'ceremony' ? 1 : 4}
      await api.put(`/memories/${m._id}`, {body})

      toaster.success()
      // sync page update
      Memories.reload({category: activeStep.key})
    } catch (e) {
      if (e.err) toaster.error(e.err)
      else toaster.error()
      console.log('err.submitEdit', e)
    }
  }

  useEffect(() => {
    if (thankHelper) {
      panel.show('helper-thank', {isModal: true})
    }

    if (splash) setNavbarHidden(true)

    // grid flicker fix
    setTimeout(forceCheck, 100)

    const load = async () => {
      await Memories.load({category: activeStep.key})
      setLoaded(true)
    }

    load()

    return () => {
      setNavbarHidden(false)
    }
  }, [])

  const fullscreenBtn = (
    <div className='text-center mb-4'>
      <Btn
        onClick={() => setFullscreen(!fullscreen)}
        className='d-none d-md-inline-block btn btn-sm text-lighter'
        icon={`fas fa-${fullscreen ? 'compress' : 'expand'}`}>
        {t(`fullscreen.${fullscreen ? 'stop' : 'start'}`)}
      </Btn>
    </div>
  )

  const scrollUpBtn = (
    <div className='text-center mb-4'>
      <Btn
        onClick={() => scrollTo('body', {duration: 2000})}
        className='btn btn-sm text-lighter'
        icon='far fa-arrow-alt-circle-up'>
        {t(`fullscreen.scrollup`)}
      </Btn>
    </div>
  )

  return (
    <div>
      <div className={`card ${fullscreen ? 'fullscreen' : ''}`}>
        {authed && !splash && (
          <div className='pt-3'>
            <div className='d-flex'>
              {Object.values(steps).map((step) => (
                <div
                  key={step.key}
                  className={`flex-grow-1 text-center segment pb-3 ${
                    activeStep.category === step.category ? 'active' : ''
                  }`}
                  onClick={() => setActiveStep(step)}>
                  <span className='f1 sbold'>{step.title}</span>
                </div>
              ))}
            </div>
          </div>
        )}

        {authed && !splash && (
          <div className='card-body d-none d-md-block'>
            <BigMenu
              namespace='memories-menu'
              items={menuItems}
              onClick={(item) =>
                flow.set('new-memory', {currentStep: item.type, rubric: activeStep.key})
              }
              justify={justifyBigMenu}
            />
          </div>
        )}

        {authed && !splash && (
          <ActionMenu
            namespace='memories-menu'
            items={menuItems}
            onClick={(item) =>
              flow.set('new-memory', {currentStep: item.type, rubric: activeStep.key})
            }
            show={isMenuOpen}
            toggle={toggleMenu}
          />
        )}

        {authed && !splash && !isMenuOpen && <DotBtn onClick={() => toggleMenu(!isMenuOpen)} />}

        {Memories.isLoading && (
          <div className='card-body text-center mb-5 pb-5 text-center' style={{minHeight: '200px'}}>
            <Spinner />
            <h3 className='mt-4'>{t(`common.loading`) || 'Loading'}</h3>
          </div>
        )}

        {!Memories.isLoading && !memories.length && (
          <div className='skeleton text-center pt-0'>
            <div className='text text-lighter col-12 col-md-10 mx-auto my-3'>
              {t(`skeleton.${activeStep.key}`, page)}
            </div>
            {activeStep.category === 'ceremony' && (
              <img
                className='img-fluid'
                src='https://res.cloudinary.com/inmemori/image/upload/v1589456093/web-assets/Skeletons_2x.svg'
              />
            )}
            {activeStep.category === 'memory' && (
              <div className='mx-0 mx-md-4 text-left'>
                <Timeline auto={false} limit={false} />
              </div>
            )}
          </div>
        )}

        {!Memories.isLoading && memories.length > 0 && (
          <div className={`card-items grid-fluid ${authed ? '' : 'blur-zone'}`}>
            {longMode && fullscreenBtn}
            <i
              className='fullscreen-toggle fa fa-circle text-white'
              onClick={() => setFullscreen(!fullscreen)}
            />

            {memories.map((m, i) => {
              const actions = editActions(m)
              const showMenu = canEdit(m)

              return (
                <div className='cell' key={i}>
                  <div className='z1 shadow-box'>
                    <div className='d-flex justify-content-between align-items-top mb-4'>
                      <h3 className='text-break'>{string.name(m.name)}</h3>
                      {showMenu && (
                        <div className='dropleft ml-2'>
                          <button
                            className='btn btn-outline-primary'
                            type='button'
                            data-toggle='dropdown'
                            style={{padding: '0px 8px'}}>
                            <i className='fas fa-ellipsis-v' />
                          </button>
                          <div className='dropdown-menu'>
                            {actions.includes('edit') && (
                              <a
                                className='dropdown-item text-primary text-bold action-edit'
                                onClick={() =>
                                  flow.set('new-memory', {memory: m, rubric: activeStep.key})
                                }>
                                <img
                                  width='18px'
                                  className='mr-3'
                                  src='https://res.cloudinary.com/inmemori/image/upload/v1589971318/web-assets/picto/edit.svg'
                                />
                                {t('common.edit')}
                              </a>
                            )}
                            {actions.includes('thank') && Products.Letter.isActive() && (
                              <a
                                className='dropdown-item text-primary text-bold action-thank'
                                onClick={() =>
                                  flow.set('ft-letter', {
                                    uiref: {
                                      page: flow.current,
                                      name: 'thank',
                                      entity: 'memories',
                                      position: i
                                    }
                                  })
                                }>
                                <img
                                  width='18px'
                                  className='mr-3'
                                  src='https://res.cloudinary.com/inmemori/image/upload/v1589971453/web-assets/picto/thank.svg'
                                />
                                {t('thank.cta.thank')}
                              </a>
                            )}
                            {actions.includes('convert') && (
                              <a
                                className='dropdown-item text-primary text-bold action-convert'
                                onClick={() => {
                                  transfertMemory(m)
                                }}>
                                <img
                                  width='18px'
                                  className='mr-3'
                                  src='https://res.cloudinary.com/inmemori/image/upload/v1589971318/web-assets/picto/roads.svg'
                                />
                                {t('common.convert')}
                              </a>
                            )}
                            {actions.includes('delete') && (
                              <a
                                className='dropdown-item text-danger text-bold action-delete'
                                onClick={(e) => {
                                  e.preventDefault()
                                  deletePopup(m)
                                }}>
                                <img
                                  width='18px'
                                  className='mr-3'
                                  src='https://res.cloudinary.com/inmemori/image/upload/v1589971318/web-assets/picto/trash.svg'
                                />
                                {t('common.delete')}
                              </a>
                            )}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className='message pre-line text-light'>{m.message}</div>

                    {m.images.length > 0 && (
                      <div>
                        <LazyLoad offset={100} height={300} once>
                          <ul className={`photos list-unstyled m-0 ${m.message ? 'mt-4' : ''}`}>
                            {m.images.map((img, i) => {
                              if (!img) return null

                              const zoom = isZoomed && isZoomed === img.hash
                              return (
                                <li className='' key={i}>
                                  <ControlledZoom
                                    isZoomed={zoom}
                                    onZoomChange={(shouldZoom) =>
                                      handleZoomChange(shouldZoom, img.hash)
                                    }
                                    overlayBgColorStart='rgba(0, 0, 0, 0.1)'
                                    overlayBgColorEnd='rgba(0, 0, 0, 0.9)'>
                                    <img
                                      src={cloudinary.url(img.hash)}
                                      className={`img-fluid ${!zoom ? 'rounded-lg' : ''}`}
                                    />
                                  </ControlledZoom>
                                </li>
                              )
                            })}
                          </ul>
                        </LazyLoad>
                        {!!m.images[0].caption && (
                          <div className='message pre-line text-light text-italic w-100'>
                            {m.images[0].caption}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )
            })}

            {longMode && scrollUpBtn}
          </div>
        )}
        {!Memories.isLoading && memories.length > 0 && Memories.hasPager && (
          <div className='card-items d-flex flex-row justify-content-between mx-auto'>
            <BtnPrev
              onClick={() => Memories.prev({category: activeStep.key})}
              disabled={!Memories.hasPrev}
              value={t(`common.prev`)}
            />
            <BtnNext
              onClick={() => Memories.next({category: activeStep.key})}
              disabled={!Memories.hasNext}
              value={t(`common.next`)}
            />
          </div>
        )}
        {/* end grid */}
      </div>

      {splash && (
        <div className='d-block d-md-none splash-footer w-100 pb-3 text-center'>
          <Btn
            onClick={endSplash}
            value={t(`onboarding.manager-partner.cta.customize`)}
            className='splash-memories-cta btn btn-sm btn-primary w-75 mx-auto'
          />
        </div>
      )}

      <style jsx>
        {`
          .z1 {
            padding: 20px;
            border: 1px solid #dadada;
            border-radius: 16px;
          }

          .splash-footer {
            z-index: 500;
            position: fixed !important;
            bottom: 0;
            padding-top: 30px;
            background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 1) 84%,
              rgba(255, 255, 255, 0) 100%
            );
          }

          // md down
          @media (max-width: 767px) {
            .z1 {
              border-radius: 0px;
              border: none;
              border-bottom: 1px solid #dadada;
              padding: 10px;
              padding-bottom: 40px;
            }
          }

          .fullscreen-toggle {
            position: absolute;
            margin-top: -16px;
          }

          .photos li {
            margin-bottom: 10px;
          }
          .photos li:last-child {
            margin-bottom: 0px;
          }

          .grid-fluid.blur-zone {
            user-select: none;
          }
          .grid-fluid.blur-zone .z1 > * {
            filter: blur(10px);
          }
          .grid-fluid.blur-zone:after {
            content: ' ';
            // background: #ff000040;
            width: 100%;
            height: 100%;
            position: absolute;
            display: block;
            top: 0;
            left: 0;
          }

          .skeleton {
            position: relative;
          }
          .skeleton .text {
            // position: absolute;
            // left: 0;
            // right: 0;
            // top: 0;
            // margin: 0 auto;
            // margin-top: 60px;
          }

          // GRID

          .grid-fluid {
            position: relative;
            column-count: 2;
            column-gap: ${gridGap};
          }

          .fullscreen {
            position: inherit !important;
          }
          .fullscreen .grid-fluid {
            column-count: 4;
            position: absolute !important;
            background: white;
            top: 0;
            left: 0;
            right: 0;
            padding: 30px !important;
          }

          .grid-fluid .cell {
            break-inside: avoid;
            -webkit-column-break-inside: avoid;
            padding-bottom: ${gridGap};
          }

          @media (max-width: 1199px) {
            // xl down
            .grid-fluid {
              column-count: 1;
              -webkit-column-count: 1;
            }
            .fullscreen .grid-fluid {
              column-count: 3 !important;
              -webkit-column-count: 3 !important;
            }
          }

          @media (max-width: 991px) {
            // lg down
            .grid-fluid {
              column-count: 1;
              -webkit-column-count: 1;
            }
            .fullscreen .grid-fluid {
              column-count: 2 !important;
              -webkit-column-count: 2 !important;
            }
          }

          .card-items {
            padding: 60px;
          }
          @media (max-width: 767px) {
            // md down
            .card-items {
              padding: 10px;
            }
          }
          @media (max-width: 767px) {
            // md down
            .grid-fluid {
              column-count: 1;
              -webkit-column-count: 1;
            }
            .fullscreen .grid-fluid {
              column-count: 1 !important;
              -webkit-column-count: 1 !important;
            }
          }

          @media (max-width: 605px) {
            // sm down
            .grid-fluid {
              column-count: 1;
              -webkit-column-count: 1;
            }
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Block)
