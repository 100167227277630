import {useEffect, useState} from 'react'
import {withGlobal} from '../shared/app'
import {Btn} from '../misc/buttons'

const Cookies = ({t, cookies}) => {
  const [show, toggle] = useState(false)

  useEffect(() => {
    if (!cookies.get('cookie')) toggle(true)
  }, [])

  const accept = () => {
    cookies.set('cookie', true, {expires: Infinity})
    toggle(false)
  }

  if (!show) return null

  if (show)
    return (
      <div className='cookies bg-light'>
        <div className='d-flex flex-column flex-md-row align-items-center p-4'>
          <div className='text text-light text-justify pr-md-4'>
            {t('cookies.text')} 
            <a href={t('cookies.link')} target='_new'>
              {t('cookies.link-text')}
            </a>
          </div>
          <div className='d-flex'>
            <div className='pt-3 pt-md-0 px-4'>
              <Btn
                className='btn btn-link text-light'
                onClick={() => accept()}
                value={t('cookies.cancel')}
              />
            </div>
            <div className='pt-3 pt-md-0'>
              <Btn
                className='btn btn-outline-light'
                onClick={() => accept()}
                value={t('cookies.accept')}
              />
            </div>
          </div>
        </div>

        <style jsx>
          {`
            .cookies {
              z-index: 10000;
              position: fixed;
              bottom: 0;
              left: 0;
              right: 0;
            }

            // md down
            @media (max-width: 767px) {
              .cookies .text {
                font-size: 14px;
              }
            }
          `}
        </style>
      </div>
    )
}

export default withGlobal(Cookies)
