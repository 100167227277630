import React from 'react'
import DynamicFeatures from '../../lib/dynamicFeatures'
import {withGlobal} from '../shared/app'

import CardProfile from './cards/card-profile'

const Sidebar = ({flow, features, page, hasRole, Products}) => {
  return (
    <React.Fragment>
      <div className='mb-3'>
        <CardProfile />
      </div>

      <div className='d-none d-md-block'>
        {DynamicFeatures({features: features, flow, page, hasRole, Products}).sidebar()}
      </div>
    </React.Fragment>
  )
}

export default withGlobal(Sidebar)
