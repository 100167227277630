import React, {useState, useEffect} from 'react'
import {withGlobal} from '../../shared/app'
import {setErrors} from '../../../lib/utils'
import {BtnSpin} from '../../misc/buttons'
import NavBar from '../../widgets/nav-bar'
import CardTop from '../../widgets/card-top'
import _ from 'lodash'
import moment from 'moment'

const Block = ({context = {}, t, page, flow, toaster, api, query, Memories}) => {
  const [form, setForm] = useState({message: ''})

  const [ctx, setCtx] = useState(context)
  const type = 'memories'

  const [isLoading, setLoading] = useState(false)

  const onFormChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value})
  }

  const submit = async () => {
    setLoading(true)
    try {
      const body = {...form, type, _id: ctx._id}
      const update = await api.post(`/pages/${page.slug}/thank`, {body})
      const errs = setErrors(update)
      if (errs) throw errs

      const newCtx = update[0]

      setCtx(newCtx)
      setForm({message: ''})
    } catch (e) {
      toaster.error(e)
      console.log('err.submit', e)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!ctx._id && query._id) {
      ;(async () => {
        let m = undefined
        m = await Memories.get(query._id)

        if (m) setCtx(m)
      })()
    }
  }, [])

  if (!ctx) return <div>no context</div>

  // max 2 replies
  const canReply = (ctx.replies || []).length < 2

  return (
    <div className='card'>
      <NavBar onClick={() => flow.set('prev')}>{t(`thank.title`)}</NavBar>

      <div className='card-body errors-scope'>
        <CardTop title={t(`thank.title`)} />

        {type == 'memories' && ctx._id && (
          <div className='form-group mb-5'>
            <label className='form-label'>
              <h4>{t(`thank.labels.chat-him`, ctx)}</h4>
              <small>{moment(ctx.cat).fromNow()}</small>
            </label>
            <p className='memory-body bg-light rounded pre-line'>{ctx.message}</p>
          </div>
        )}

        {(ctx.replies || []).map((reply, i) => (
          <div className='form-group chat' key={i}>
            <label className='form-label'>
              <h4>{t(`thank.labels.chat-you`)}</h4>
              <small>{moment(reply.cat).fromNow()}</small>
            </label>
            <p className='reply-body bg-light rounded'>{reply.message}</p>
          </div>
        ))}

        {canReply && (
          <div className='form-group'>
            <label className='form-label'>{t(`thank.labels.message`, ctx)}</label>
            <textarea
              name='message'
              value={form.message}
              onChange={onFormChange}
              className='form-control'
              rows='4'
              placeholder={t(`new-memory.ph.message`)}></textarea>
          </div>
        )}
      </div>

      {/* CARD-FOOTER */}
      <div className='card-footer d-flex justify-content-end'>
        <button
          onClick={() => flow.set('prev')}
          className='d-none d-md-block m-0 btn btn-outline-primary mr-2'>
          {t('common.back')}
        </button>
        {canReply && (
          <BtnSpin isLoading={isLoading} onClick={() => submit()} className='btn btn-primary m-0 '>
            {t('common.share')}
          </BtnSpin>
        )}
      </div>

      <style jsx>
        {`
          .form-label {
            display: flex !important;
            justify-content: space-between;
          }

          .memory-body {
            font-size: 12px;
            padding: 10px;
            // height: 110px;
            max-height: 110px;
            overflow: scroll;
          }

          .reply-body {
            font-size: 12px;
            padding: 10px;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Block)
