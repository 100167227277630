import React from 'react'

import App from '../../components/shared/app'
import Layout from '../../components/shared/layout'
import Footer from '../../components/shared/footer'
import SpaceApp from '../../components/app/index'

const Page = (props) => {
  return (
    <App {...props}>
      <Layout bg=''>
        <SpaceApp />
        <Footer />
      </Layout>
    </App>
  )
}

Page.getInitialProps = async (ctx) => {
  const props = await App.getInitialProps(ctx)
  return props
}

export default Page
