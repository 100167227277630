import React, {useState} from 'react'
import {withGlobal} from '../../shared/app'
import {withAuth} from '../../shared/auth'
import {BtnSpin, Btn} from '../../misc/buttons'
import NavBar from '../../widgets/nav-bar'
import CardTop from '../../widgets/card-top'
import InputPhone, {getPhone} from '../../misc/input-phone'

import {setErrors, failValidation} from '../../../lib/utils'
import {FlowStep} from '../../../lib/constants'
import AddressForm from '../../misc/address-form'

const Block = ({
  page,
  _profile,
  setProfile,
  hasRole,
  t,
  flow,
  toaster,
  countries,
  defaultCountry,
  defaultProfile,
  Auth,
  Page,
  Profile
}) => {
  const [isLoading, setLoading_] = useState({cta: false, sms: false, email: false, log: false})
  const setLoading = (key, value) => setLoading_({...isLoading, [key]: value})

  const profile = defaultProfile || _profile

  const defaultForm = {
    _id: profile._id,
    name: profile.name || '',
    phone: getPhone(profile.phone, defaultCountry),
    email: profile.email || ''
  }

  const [form, setForm] = useState(defaultForm)

  const onFormChange = (e) => {
    const newForm = {...form, [e.target.name]: e.target.value}
    setForm(newForm)
  }

  const onPhoneChange = (phone) => {
    const newForm = {...form, phone}
    setForm(newForm)
  }

  const [address, setAddress] = useState(_profile.location || {})

  const save = async () => {
    if (!hasRole('team') && failValidation('email,name', t)) return
    setLoading('cta', true)

    try {
      const result = await Profile.updateProfile({
        ...form,
        phone: form.phone.clean,
        slug: page.slug,
        locationData: address
      })

      if (result) {
        toaster.success()
        flow.set(FlowStep.prev)
      }
    } catch (e) {
      console.log('err.save', e)

      toaster.error()
    }
    setLoading('cta', false)
  }

  const reinvite = async (channel) => {
    if (!confirm('are you sure ?')) return
    setLoading(channel, true)
    const isManager = profile.tribe && profile.tribe.isManager

    try {
      const {fails} = await Page.sendInvitations(
        {...form, phone: form.phone.clean},
        {canals: channel, isManager}
      )
      if (fails[0]) throw fails
      toaster.success()
    } catch (e) {
      console.log('catch reinvite', e)
      toaster.error()
    }
    setLoading(channel, false)
  }

  const logAs = async () => {
    if (!profile._id) return
    setLoading('log', true)
    const claim = {role: 'user', u: profile._id}
    try {
      Auth.logAs(claim)
    } catch (e) {
      console.log('logAs:catch', e)
    }
    setLoading('log', false)
  }

  return (
    <div className='card'>
      <NavBar onClick={() => flow.set(FlowStep.prev)}>{t(`profile.title`)}</NavBar>

      <div className='card-body d-flex flex-column col errors-scope'>
        <CardTop title={t('profile.title')} subtitle={t('profile.subtitle')} />

        <div className='form-group'>
          <label className='form-label'>{t('profile.label.name')}</label>
          <input
            type='text'
            name='name'
            value={form.name}
            onChange={onFormChange}
            className='form-control'
            placeholder={t('profile.ph.name')}
          />
        </div>

        <div className='form-group d-flex'>
          <label className='form-label'>{t('profile.label.email')}</label>
          <input
            errkey='email'
            type='email'
            name='email'
            value={form.email}
            onChange={onFormChange}
            className='form-control'
            placeholder={t('profile.ph.email')}
            required
          />
          {profile.email && hasRole('team') && (
            <BtnSpin
              isLoading={isLoading.email}
              className='btn btn-sm btn-link ml-2'
              onClick={() => reinvite('email')}
              icon='fa fa-envelope'
              title={t('profile.cta.reinvite')}
            />
          )}
        </div>

        <div className='form-group d-flex'>
          <label className='form-label'>{t('profile.label.phone')}</label>
          <InputPhone
            status={form.phone.raw ? (form.phone.clean ? true : false) : ''}
            defaultPhone={form.phone}
            onChange={onPhoneChange}
            countries={countries}
            defaultCountry={defaultCountry}
            placeholder={t(`profile.ph.phone`)}
          />
          {profile.phone && hasRole('team') && (
            <BtnSpin
              isLoading={isLoading.sms}
              className='btn btn-sm btn-link ml-2'
              onClick={() => reinvite('sms')}
              icon='fa fa-comment'
              title={t('profile.cta.reinvite')}
            />
          )}
        </div>

        <AddressForm className='mt-2' form={address} onChange={setAddress} />

        {hasRole('team') && (
          <div className='dropdown'>
            <a
              className='btn btn-outline-light btn-sm'
              href='#'
              role='button'
              data-toggle='dropdown'>
              <i className='fas fa-ellipsis-v'></i>
            </a>
            <div className='dropdown-menu dropdown-menu-arrow'>
              <div className='px-3 text-white bg-dark'>
                don&apos;t use this menu !<br />
                tech debug only
              </div>
              <Btn onClick={() => logAs()} className='dropdown-item' value='log as' />
            </div>
          </div>
        )}
      </div>

      <div className='card-footer text-right'>
        <Btn
          onClick={() => flow.set(FlowStep.prev)}
          className='btn btn-outline-primary mr-3'
          value={t('common.back')}
        />
        <BtnSpin isLoading={isLoading.cta} onClick={() => save()} className='m-0 btn btn-primary'>
          {t('common.save')}
        </BtnSpin>
      </div>

      <style jsx>
        {`
          .form-label {
            display: none;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(withAuth(Block))
