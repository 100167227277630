import CardFlowers from './card-flowers'
import CardBook from './card-book'
import CardFtDonation from './card-ft-donation'
import CardIntro from './card-intro'
import CardLocations from './card-locations'
import CardStats from './card-stats'
import CardProfile from './card-profile'
import CardShare from './card-share'
import CardLetter from './card-letter'

export const Cards = {}

Cards['flowers'] = CardFlowers
Cards['book'] = CardBook
Cards['donation'] = CardFtDonation
Cards['infos'] = CardIntro
Cards['locations'] = CardLocations
Cards['stats'] = CardStats
Cards['profile'] = CardProfile
Cards['share'] = CardShare
Cards['letter'] = CardLetter
