import React from 'react'
import {withGlobal} from '../../shared/app'
import CardStats from '../cards/card-stats'
import CardTop from '../../widgets/card-top'
import NavBar from '../../widgets/nav-bar'
import {Btn} from '../../misc/buttons'
import Popup from '../../../lib/popup'

const openInNewTab = (url) => {
  var win = window.open(url, '_blank')
  win.focus()
}

const PromoPartner = ({page, t}) => {
  const {partner, agency} = page
  const {promo, logo} = partner.config || {}
  const item = agency.key == 'root-agency' ? partner : agency

  if (!promo) return null

  return (
    <div className='partner-promo d-flex flex-column justify-content-between align-items-center'>
      <div className='pre-line text-center mb-3'>{t(`partner.promo-${promo}`, item)}</div>
      <div>
        {logo && (
          <img
            src={`https://res.cloudinary.com/inmemori/image/upload/fl_lossy,f_auto/partners/${partner.key}`}
          />
        )}
      </div>

      <style jsx>
        {`
          .partner-promo {
            margin-bottom: 50px;
          }
          img {
            max-width: 200px;
            max-height: 140px;
            margin-bottom: 20px;
          }

          @media (max-width: 991px) {
            // lg down
            img {
              max-width: 160px;
              max-height: 112px;
            }
          }
        `}
      </style>
    </div>
  )
}

const Block = ({authed, Auth, flow, page, _op, hasRole, api, t, Products}) => {
  const deletePopup = () => {
    Popup.areYouSure(t(`popups.delete-page`, true), () => deletePage())
  }

  const deletePage = async () => {
    try {
      await api.delete(`/pages/${page.slug}`)
      hasRole('team') ? window.location.replace('/') : Auth.logout({redirect: '/'})
    } catch (e) {
      console.log('err.deletePage', e)
    }
  }

  if (!authed) return null

  const Title = ({ns, ctx}) => (
    <span className='pr-2'>{t(`tab-settings.options.${ns}.title`, ctx)}</span>
  )
  const Cta = ({ns, ctx, className, ...rest}) => (
    <Btn
      className={`text-right btn btn-link text-primary ${className}`}
      value={t(`tab-settings.options.${ns}.cta`, ctx)}
      icon={t(`tab-settings.options.${ns}.icon`)}
      {...rest}
    />
  )

  const connectedAs = () => {
    const r = hasRole('team,broker') ? ` [${_op.role}]` : ''
    const n = _op.name || _op.email
    return `${n}${r}`
  }

  return (
    <div className='card'>
      <NavBar showPrev={false} onClick={() => flow.set('prev')}>
        {t(`tab-settings.title`)}
      </NavBar>

      <div className='card-body'>
        <CardTop title={t('tab-settings.title')} />
        <PromoPartner page={page} t={t} hasRole={hasRole} />

        {hasRole('manager,broker') && <CardStats />}

        <div className='mt-3'>
          <ul className='list-group list-group-flush'>
            {hasRole('manager,broker') && (
              <li
                className='list-group-item d-flex align-items-center justify-content-between cursor'
                onClick={() => flow.set('managers', {currentStep: hasRole('broker') ? 1 : 0})}>
                <Title ns={`managers${hasRole('broker') ? '-as-broker' : ''}`} />
                <Cta ns={`managers${hasRole('broker') ? '-as-broker' : ''}`} />
              </li>
            )}
            {false &&
              !page.products.donation.disabled &&
              hasRole('manager') &&
              !page.donation.state && (
                <li className='list-group-item d-flex align-items-center justify-content-between cursor'>
                  <label className='switch w-100 d-flex align-items-center justify-content-between cursor'>
                    <span>{t(`tab-settings.options.donation-start.title`)}</span>
                    <input
                      checked={!!page.donation.state && 'checked'}
                      type='checkbox'
                      onChange={() => flow.set('ft-donation-settings')}
                    />
                    <span className='switcher my-3'></span>
                  </label>
                </li>
              )}
            {
              /*!page.products.donation.disabled &&*/ hasRole('manager') && !!page.donation.state && (
                <li
                  className='list-group-item d-flex align-items-center justify-content-between cursor'
                  onClick={() => flow.set('ft-donation-settings')}>
                  <Title ns='donation' />
                  <Cta ns='donation' />
                </li>
              )
            }
            {hasRole('manager') && Products.Letter.isActive() && (
              <li
                className='list-group-item d-flex align-items-center justify-content-between cursor'
                onClick={() =>
                  flow.set('ft-letter', {
                    uiref: {
                      page: flow.current,
                      name: 'letter',
                      entity: 'settings'
                    }
                  })
                }>
                <Title ns='letter' />
                <Cta ns='letter' />
              </li>
            )}
            {hasRole('team,user') && (
              <li
                className='list-group-item d-flex align-items-center justify-content-between cursor'
                onClick={() => flow.set('profile')}>
                <Title ns='profile' />
                <Cta ns='profile' />
              </li>
            )}
            {hasRole('team,user') && (
              <li className='list-group-item d-flex align-items-center justify-content-between'>
                <Title ns='unsubscribe' />
                <Cta ns='unsubscribe' onClick={() => flow.set('unsubscribe')} />
              </li>
            )}
            <li className='list-group-item d-flex align-items-center justify-content-between'>
              <Title ns='logout' ctx={{as: connectedAs()}} />
              <Cta ns='logout' onClick={() => flow.set('logout')} />
            </li>
            <li className='list-group-item d-flex align-items-center justify-content-between'>
              <Title ns='cgv' />
              <Cta ns='cgv' onClick={() => openInNewTab('https://fr.inmemori.com/conditions/')} />
            </li>
            <li className='list-group-item d-flex align-items-center justify-content-between'>
              <Title ns='abuse' />
              <Cta ns='abuse' onClick={() => (window.location.href = 'mailto:abus@inmemori.com')} />
            </li>
            {hasRole('manager') && (
              <li className='list-group-item d-flex align-items-center justify-content-between'>
                <Title ns='delete' ctx={page} />
                <Cta className='text-danger' ns='delete' onClick={() => deletePopup()} />
              </li>
            )}
          </ul>
        </div>

        <div className='card mt-3'>
          <ul className='list-group list-group-flush'></ul>
        </div>
      </div>

      <style jsx>
        {`
          .cta-icon {
            margin-right: 10px;
            font-size: 18px;
            width: 30px;
            text-align: center;
            color: red;
          }

          li {
            min-height: 58px;
          }
          li span {
            font-size: 16px;
          }
          li button {
            margin: 0;
          }

          .list-group-item:last-child {
            border-bottom: none !important;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Block)
