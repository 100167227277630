import React from 'react'
import {withGlobal} from '../components/shared/app'

import {FilePond} from 'react-filepond'
import {StripeFilePond} from './stripe'

const filePondi18n = (t, maxFiles, placeholder, allowMultiple, files) => {
  let labelIdle = placeholder ? placeholder : t('filepond.idle')

  if (allowMultiple && maxFiles > 1) {
    if (files.length == 0)
      labelIdle = `${labelIdle}<br/><span class="text-lighter text-italic">${t(
        'filepond.maxFiles',
        {maxFiles}
      )}</span>`
    else
      labelIdle = `${labelIdle}<br/><span class="text-lighter text-italic">${t(
        'filepond.remainingFiles',
        {remainingFiles: maxFiles - files.length}
      )}</span>`
  }

  return {
    labelIdle,
    labelInvalidField: t('filepond.invalidField'),
    labelFileWaitingForSize: t('filepond.fileWaitingForSize'),
    labelFileSizeNotAvailable: t('filepond.fileSizeNotAvailable'),
    labelFileLoading: t('filepond.fileLoading'),
    labelFileLoadError: t('filepond.fileLoadError'),
    labelFileProcessing: t('filepond.fileProcessing'),
    labelFileProcessingComplete: t('filepond.fileProcessingComplete'),
    labelFileProcessingAborted: t('filepond.fileProcessingAborted'),
    labelFileProcessingError: t('filepond.fileProcessingError'),
    labelFileProcessingRevertError: t('filepond.fileProcessingRevertError'),
    labelFileRemoveError: t('filepond.fileRemoveError'),
    labelFileTypeNotAllowed: t('filepond.fileTypeNotAllowed'),
    labelTapToCancel: t('filepond.tapToCancel'),
    labelTapToRetry: t('filepond.tapToRetry'),
    labelTapToUndo: t('filepond.tapToUndo'),
    labelButtonRemoveItem: t('filepond.buttonRemoveItem'),
    labelButtonAbortItemLoad: t('filepond.buttonAbortItemLoad'),
    labelButtonRetryItemLoad: t('filepond.buttonRetryItemLoad'),
    labelButtonAbortItemProcessing: t('filepond.buttonAbortItemProcessing'),
    labelButtonUndoItemProcessing: t('filepond.buttonUndoItemProcessing'),
    labelButtonRetryItemProcessing: t('filepond.buttonRetryItemProcessing'),
    labelButtonProcessItem: t('filepond.buttonProcessItem'),
    fileValidateTypeLabelExpectedTypesMap: {
      'image/jpeg': '.jpg',
      'image/png': '.png',
      'application/pdf': '.pdf'
    },
    fileValidateTypeLabelExpectedTypes: t('filepond.fileValidateTypeLabelExpectedTypes'),
    labelMaxFileSizeExceeded: t('filepond.maxFileSizeExceeded'),
    labelMaxFileSize: t('filepond.maxFileSize'),
    labelMaxTotalFileSizeExceeded: t('filepond.maxTotalFileSizeExceeded'),
    labelMaxTotalFileSize: t('filepond.maxTotalFileSize'),
    imageValidateSizeLabelFormatError: t('filepond.imageValidateSizeLabelFormatError'),
    imageValidateSizeLabelImageSizeTooSmall: t('filepond.imageValidateSizeLabelImageSizeTooSmall'),
    imageValidateSizeLabelImageSizeTooBig: t('filepond.imageValidateSizeLabelImageSizeTooBig'),
    imageValidateSizeLabelExpectedMinSize: t('filepond.imageValidateSizeLabelExpectedMinSize'),
    imageValidateSizeLabelExpectedMaxSize: t('filepond.imageValidateSizeLabelExpectedMaxSize')
  }
}

const pintura18n = (t) => {
  const IconCross =
    '<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><path d="M18 6L6 18M6 6l12 12"></path></path></g>'

  return {
    // generic
    labelReset: t('doka.labelReset'),
    labelDefault: t('doka.labelDefault'),
    labelAuto: t('doka.labelAuto'),
    labelNone: t('doka.labelNone'),
    labelEdit: t('doka.labelEdit'),
    labelClose: t('doka.labelClose'),
    labelSupportError: (features) => t('doka.labelSupportError', {features: features.join(', ')}),

    // sizes
    labelSizeExtraSmall: t('doka.labelSizeExtraSmall'),
    labelSizeSmall: t('doka.labelSizeSmall'),
    labelSizeMediumSmall: t('doka.labelSizeMediumSmall'),
    labelSizeMedium: t('doka.labelSizeMedium'),
    labelSizeMediumLarge: t('doka.labelSizeMediumLarge'),
    labelSizeLarge: t('doka.labelSizeLarge'),
    labelSizeExtraLarge: t('doka.labelSizeExtraLarge'),

    // unused?
    labelButtonRevert: t('doka.labelButtonRevert'),
    labelButtonCancel: t('doka.labelButtonCancel'),

    labelButtonUndo: t('doka.labelButtonUndo'),
    labelButtonRedo: t('doka.labelButtonRedo'),
    labelButtonExport: t('doka.labelButtonExport'),

    iconSupportError: `<g fill="none" stroke="currentColor" stroke-width="2"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"/><g><line x1="12" y1="9" x2="12" y2="13"/><line x1="12" y1="17" x2="12.01" y2="17"/></g>`,
    iconButtonClose: IconCross,
    iconButtonRevert: `<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><path d="M7.388 18.538a8 8 0 10-2.992-9.03"/><path fill="currentColor" d="M2.794 11.696L2.37 6.714l5.088 3.18z"/><path d="M12 8v4M12 12l4 2"/></g>`,
    iconButtonUndo: `<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><path d="M10 8h4c2.485 0 5 2 5 5s-2.515 5-5 5h-4"/><path fill="currentColor" d="M5 8l4-3v6z"/></g>`,
    iconButtonRedo: `<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><path d="M14 8h-4c-2.485 0-5 2-5 5s2.515 5 5 5h4"/><path fill="currentColor" d="M19 8l-4-3v6z"/></g>`,
    iconButtonExport: `<polyline points="20 6 9 17 4 12" fill="none" stroke="currentColor" stroke-width=".125em"></polyline>`,

    // status
    statusLabelButtonClose: t('doka.statusLabelButtonClose'),
    statusIconButtonClose: IconCross,
    statusLabelLoadImage: (state) => {
      if (!state || !state.task) return t('doka.statusLabelLoadImageWaiting')
      if (state.error) return t('doka.statusLabelLoadImageError')
      if (state.task === 'blob-to-bitmap') return t('doka.statusLabelLoadImageWorking')
      return t('doka.statusLabelLoadImageLoading')
    },

    // processing status message
    statusLabelProcessImage: (state) => {
      if (!state || !state.task) return undefined
      if (state.task === 'store') {
        if (state.error) return t('doka.statusLabelProcessImageErrorUploading')
        return t('doka.statusLabelProcessImageUploading')
      }
      if (state.error) return t('doka.statusLabelProcessImageErrorProcessing')
      return t('doka.statusLabelProcessImageProcessing')
    },

    // Crop
    cropLabel: t('doka.cropLabel'),
    cropIcon:
      '<g stroke-width=".125em" stroke="currentColor" fill="none"><path d="M23 17H9a2 2 0 0 1-2-2v-5m0-3V1"/><path d="M1 7h14a2 2 0 0 1 2 2v7m0 4v3"/></g>',
    cropIconButtonRecenter: `<path stroke="currentColor" fill="none" stroke-width="2" stroke-linejoin="bevel" d="M1.5 7.5v-6h6M1.5 16.5v6h6M22.5 16.5v6h-6M22.5 7.5v-6h-6"/><circle cx="12" cy="12" r="3.5" fill="currentColor" stroke="none"/>`,
    cropIconButtonRotateLeft:
      '<g stroke="none" fill="currentColor"><path fill="none" d="M-1-1h582v402H-1z"/><rect x="3" rx="1" height="12" width="12" y="9"/><path d="M15 5h-1a5 5 0 015 5 1 1 0 002 0 7 7 0 00-7-7h-1.374l.747-.747A1 1 0 0011.958.84L9.603 3.194a1 1 0 000 1.415l2.355 2.355a1 1 0 001.415-1.414l-.55-.55H15z"/></g>',
    cropIconButtonRotateRight:
      '<g stroke="none" fill="currentColor"><path fill="none" d="M-1-1h582v402H-1z"/><path d="M11.177 5H10a5 5 0 00-5 5 1 1 0 01-2 0 7 7 0 017-7h1.374l-.747-.747A1 1 0 0112.042.84l2.355 2.355a1 1 0 010 1.415l-2.355 2.354a1 1 0 01-1.415-1.414l.55-.55z"/><rect rx="1" height="12" width="12" y="9" x="9"/></g>',
    cropIconButtonFlipVertical:
      '<g stroke="none" fill="currentColor"><path d="M19.993 12.143H7a1 1 0 0 1-1-1V5.994a1 1 0 0 1 1.368-.93l12.993 5.15a1 1 0 0 1-.368 1.93z"/><path d="M19.993 14a1 1 0 0 1 .368 1.93L7.368 21.078A1 1 0 0 1 6 20.148V15a1 1 0 0 1 1-1h12.993z" opacity=".6"/></g>',
    cropIconButtonFlipHorizontal:
      '<g stroke="none" fill="currentColor"><path d="M11.93 7.007V20a1 1 0 0 1-1 1H5.78a1 1 0 0 1-.93-1.368l5.15-12.993a1 1 0 0 1 1.929.368z"/><path d="M14 7.007V20a1 1 0 0 0 1 1h5.149a1 1 0 0 0 .93-1.368l-5.15-12.993A1 1 0 0 0 14 7.007z" opacity=".6"/></g>',
    cropLabelButtonRecenter: t('doka.cropLabelButtonRecenter'),
    cropLabelButtonRotateLeft: t('doka.cropLabelButtonRotateLeft'),
    cropLabelButtonRotateRight: t('doka.cropLabelButtonRotateRight'),
    cropLabelButtonFlipHorizontal: t('doka.cropLabelButtonFlipHorizontal'),
    cropLabelButtonFlipVertical: t('doka.cropLabelButtonFlipVertical'),

    cropLabelSelectPreset: t('doka.cropLabelSelectPreset'),

    cropLabelCropBoundary: t('doka.cropLabelCropBoundary'),
    cropLabelCropBoundaryEdge: t('doka.cropLabelCropBoundaryEdge'),
    cropLabelCropBoundaryNone: t('doka.cropLabelCropBoundaryNone'),

    cropLabelTabRotation: t('doka.cropLabelTabRotation'),
    cropLabelTabZoom: t('doka.cropLabelTabZoom')
  }
}

export const MemoryFileUpload = withGlobal(
  ({
    t,
    cloudinary,
    allowImagePreview = true,
    allowMultiple = true,
    allowImageValidateSize = true,
    acceptedFileTypes = ['image/png', 'image/jpeg'],
    placeholder,
    files = [],
    name = 'file',
    maxFiles = 10,
    minFileSize = null,
    maxFileSize = null,
    maxTotalFileSize = null,
    className = '',
    onupdatefiles = () => {},
    onaddfile = () => {},
    onprocessfilestart = () => {},
    onprocessfiles = () => {},
    onprocessfile = () => {},
    onremovefile = () => {}
  }) => {
    return (
      <FilePond
        allowImagePreview={allowImagePreview}
        allowImageTransform
        imagePreviewHeight={256}
        files={files}
        allowMultiple={allowMultiple}
        maxFiles={maxFiles}
        minFileSize={minFileSize}
        maxFileSize={maxFileSize}
        maxTotalFileSize={maxTotalFileSize}
        allowImageValidateSize={allowImageValidateSize}
        imageValidateSizeMinWidth={320}
        imageValidateSizeMinHeight={320}
        {...filePondi18n(t, maxFiles, placeholder, allowMultiple, files)}
        server={cloudinary.filepond('memory')}
        name={name}
        className={className}
        acceptedFileTypes={acceptedFileTypes}
        onaddfile={onaddfile}
        onprocessfilestart={onprocessfilestart}
        onprocessfile={onprocessfile}
        onprocessfiles={onprocessfiles}
        onremovefile={onremovefile}
        onupdatefiles={onupdatefiles}
      />
    )
  }
)

export const ProfilePictureUpload = withGlobal(
  ({
    t,
    cloudinary,
    allowImageValidateSize = true,
    acceptedFileTypes = ['image/png', 'image/jpeg'],
    placeholder,
    files = [],
    name = 'file',
    className = '',
    oninit = () => {},
    onupdatefiles = () => {},
    onprocessfilestart = () => {},
    onprocessfiles = () => {},
    onprocessfile = () => {},
    onremovefile = () => {},
    onaddfile = () => {}
  }) => {
    // pintura
    const isIE = typeof document !== 'undefined' && /*@cc_on!@*/ (false || !!document.documentMode)

    if (isIE) {
      return (
        <FilePond
          ref={oninit}
          allowFilePoster
          allowProcess={false}
          allowImagePreview={false}
          {...filePondi18n(t, 1, placeholder, false, files)}
          files={files}
          filePosterHeight={256}
          allowMultiple={false}
          maxFiles={1}
          allowImageValidateSize={allowImageValidateSize}
          imageValidateSizeMinWidth={320}
          imageValidateSizeMinHeight={320}
          instantUpload={false}
          server={cloudinary.filepond('profile')}
          name={name}
          className={className}
          acceptedFileTypes={acceptedFileTypes}
          onupdatefiles={onupdatefiles}
          onprocessfilestart={onprocessfilestart}
          onprocessfile={onprocessfile}
          onprocessfiles={onprocessfiles}
          onremovefile={onremovefile}
          onaddfile={onaddfile}
        />
      )
    } else {
      const {
        // editor
        openEditor,
        createDefaultImageReader,
        createDefaultImageWriter,
        processImage,
        setPlugins,
        plugin_crop
      } = require('./pintura/pintura')

      setPlugins(plugin_crop)

      return (
        <FilePond
          ref={oninit}
          allowFilePoster
          allowProcess={false}
          allowImagePreview={false}
          {...filePondi18n(t, 1, placeholder, false, files)}
          files={files}
          filePosterHeight={256}
          allowMultiple={false}
          maxFiles={1}
          allowImageValidateSize={allowImageValidateSize}
          imageValidateSizeMinWidth={320}
          imageValidateSizeMinHeight={320}
          instantUpload={false}
          server={cloudinary.filepond('profile')}
          styleImageEditorButtonEditItemPosition='bottom right'
          imageEditorInstantEdit
          imageEditor={{
            createEditor: openEditor,
            imageReader: [createDefaultImageReader],
            imageWriter: [createDefaultImageWriter],
            imageProcessor: processImage,
            // editor options
            editorOptions: {
              locale: {
                ...pintura18n(t)
              },
              enableNavigateHistory: false,
              imageCropAspectRatio: 1,
              cropEnableButtonFlipHorizontal: false,
              cropEnableButtonRotateRight: true,
              cropEnableRotationInput: false
            }
          }}
          name={name}
          className={className}
          acceptedFileTypes={acceptedFileTypes}
          onupdatefiles={onupdatefiles}
          onprocessfilestart={onprocessfilestart}
          onprocessfile={onprocessfile}
          onprocessfiles={onprocessfiles}
          onremovefile={onremovefile}
          onaddfile={onaddfile}
        />
      )
    }
  }
)

export const LetterImageUpload = withGlobal(
  ({
    t,
    cloudinary,
    allowImageValidateSize = true,
    acceptedFileTypes = ['image/png', 'image/jpeg'],
    placeholder,
    files = [],
    name = 'file',
    className = '',
    aspectRatio = 1,
    oninit = () => {},
    onupdatefiles = () => {},
    onprocessfilestart = () => {},
    onprocessfiles = () => {},
    onprocessfile = () => {},
    onremovefile = () => {},
    onaddfile = () => {}
  }) => {
    // pintura
    const isIE = typeof document !== 'undefined' && /*@cc_on!@*/ (false || !!document.documentMode)

    if (isIE) {
      return (
        <FilePond
          ref={oninit}
          allowFilePoster={false}
          allowProcess={false}
          allowImagePreview={false}
          {...filePondi18n(t, 1, placeholder, false, files)}
          files={files}
          filePosterHeight={256}
          allowMultiple={false}
          maxFiles={1}
          allowImageValidateSize={allowImageValidateSize}
          imageValidateSizeMinWidth={320}
          imageValidateSizeMinHeight={320}
          instantUpload
          server={cloudinary.filepond('letter')}
          name={name}
          className={className}
          acceptedFileTypes={acceptedFileTypes}
          onupdatefiles={onupdatefiles}
          onprocessfilestart={onprocessfilestart}
          onprocessfile={onprocessfile}
          onprocessfiles={onprocessfiles}
          onremovefile={onremovefile}
          onaddfile={onaddfile}
        />
      )
    } else {
      const {
        // editor
        openEditor,
        createDefaultImageReader,
        createDefaultImageWriter,
        processImage,
        setPlugins,
        plugin_crop
      } = require('./pintura/pintura')

      setPlugins(plugin_crop)

      return (
        <FilePond
          ref={oninit}
          allowFilePoster={false}
          allowProcess={false}
          allowImagePreview={false}
          {...filePondi18n(t, 1, placeholder, false, files)}
          files={files}
          filePosterHeight={256}
          allowMultiple={false}
          maxFiles={1}
          allowImageValidateSize={allowImageValidateSize}
          imageValidateSizeMinWidth={320}
          imageValidateSizeMinHeight={320}
          instantUpload
          server={cloudinary.filepond('letter')}
          styleImageEditorButtonEditItemPosition='bottom right'
          imageEditorInstantEdit
          imageEditor={{
            createEditor: openEditor,
            imageReader: [createDefaultImageReader],
            imageWriter: [createDefaultImageWriter],
            imageProcessor: processImage,
            // editor options
            editorOptions: {
              locale: {
                ...pintura18n(t)
              },
              enableNavigateHistory: false,
              imageCropAspectRatio: aspectRatio,
              cropEnableButtonFlipHorizontal: false,
              cropEnableButtonRotateRight: true,
              cropEnableRotationInput: false
            }
          }}
          name={name}
          className={className}
          acceptedFileTypes={acceptedFileTypes}
          onupdatefiles={onupdatefiles}
          onprocessfilestart={onprocessfilestart}
          onprocessfile={onprocessfile}
          onprocessfiles={onprocessfiles}
          onremovefile={onremovefile}
          onaddfile={onaddfile}
        />
      )
    }
  }
)

export const StripeDocumentUpload = withGlobal(
  ({
    t,
    purpose,
    pk,
    mock = true,
    allowImagePreview = true,
    allowMultiple = false,
    allowImageValidateSize = false,
    acceptedFileTypes = ['image/png', 'image/jpeg'],
    placeholder,
    files = [],
    name = 'file',
    maxFiles = 1,
    minFileSize = null,
    maxFileSize = '5MB',
    maxTotalFileSize = null,
    className = '',
    onupdatefiles = () => {},
    onaddfile = () => {},
    onprocessfiles = () => {},
    onprocessfile = () => {},
    onremovefile = () => {}
  }) => {
    return (
      <FilePond
        allowImageTransform
        imagePreviewHeight={200}
        allowImagePreview={allowImagePreview}
        allowFilePoster={false}
        {...filePondi18n(t, maxFiles, placeholder, allowMultiple, files)}
        files={files}
        onupdatefiles={onupdatefiles}
        allowMultiple={allowMultiple}
        maxFiles={maxFiles}
        minFileSize={minFileSize}
        maxFileSize={maxFileSize}
        maxTotalFileSize={maxTotalFileSize}
        allowImageValidateSize={allowImageValidateSize}
        imageValidateSizeMinWidth={320}
        imageValidateSizeMinHeight={320}
        server={StripeFilePond(purpose, pk, mock)}
        name={name}
        className={className}
        acceptedFileTypes={acceptedFileTypes}
        onprocessfilestart={onaddfile}
        onprocessfile={onprocessfile}
        onprocessfiles={onprocessfiles}
        onremovefile={onremovefile}
      />
    )
  }
)
