import {useState, useCallback} from 'react'
import {withGlobal} from '../../shared/app'
import {BtnEdit} from '../../widgets/btn-edit'
import {Controlled as ControlledZoom} from 'react-medium-image-zoom'

const Block = ({
  _bnr,
  page,
  hasRole,
  flow,
  cloudinary,
  className = '',
  forceDisplay = false,
  disableEdit = false,
  blockEdit,
  uiref = {}
}) => {
  const banner = _bnr.getCurrent('has-photo')
  const editMode =
    hasRole('manager,broker') &&
    !disableEdit &&
    !blockEdit &&
    ['tab-infos', 'tab-memories', 'tab-share'].includes(flow.current)
  let hide =
    (!['tab-infos', 'tab-memories', 'profile-setup'].includes(flow.current) && 'hide-element') || ''

  if (forceDisplay) hide = ''

  const [isZoomed, setIsZoomed] = useState(false)

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom)
  }, [])


  const { defunct } = page

  return (
    <div
      className={`d-flex flex-row flex-md-column align-items-center align-items-md-start card py-2 py-md-0 ${hide} ${className}`}>
      <div className={`card-profile ${banner ? 'banner-mode' : ''}`}>
        <ControlledZoom
          isZoomed={isZoomed}
          onZoomChange={handleZoomChange}
          overlayBgColorStart='rgba(0, 0, 0, 0.1)'
          overlayBgColorEnd='rgba(0, 0, 0, 0.9)'>
          <img
            src={cloudinary.url(defunct.image, {filter: 'w_1000,h_1000,c_fill,g_auto'})}
            className={`img-fluid ${isZoomed ? '' : 'rounded-lg'} page-image`}
          />
        </ControlledZoom>
      </div>

      <div className='pt-1 pt-md-3'>
        <div className='name text-bold'>{defunct.fullname}</div>
        {defunct.blaze && <div className='small blaze'>{defunct.blaze}</div>}
        <div className='years'>{defunct.years}</div>

        {!['profile-setup'].includes(flow.current) && editMode && (
          <BtnEdit className='d-block mt-md-3' onClick={() => flow.set('edit-profile', {uiref})} />
        )}
      </div>

      <style jsx>
        {`
          // md down
          @media (max-width: 767px) {
            .card {
              background-color: #eff2f2 !important;
            }
          }

          .card-profile.banner-mode {
            z-index: 11;
          }

          .name {
            font-size: 24px;
          }

          // md down
          @media (max-width: 767px) {
            .hide-element {
              display: none !important;
            }
            .card-title {
              font-size: 16px;
            }
            .name {
              font-size: 20px;
            }
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Block)
