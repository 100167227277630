import React from 'react'

const IFrame = ({iframeUrl}) => {
  return (
    <div className='modal-content panel-container align-items-center'>
      <iframe className='panel-iframe' src={iframeUrl} allowFullScreen></iframe>
      <style jsx>
        {`
          .panel-container {
            position: relative;
            overflow: hidden;
            width: 800px;
            padding-top: 100%;
          }

          .panel-iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }

          @media (max-width: 605px) {
            // sm down
            .panel-container {
              width: 360px;
              padding-top: 150%;
            }
          }
        `}
      </style>
    </div>
  )
}

export default IFrame
