import TileBook, {checkBookTile} from './tile-book'
import TileFlowers, {checkFlowersTile} from './tile-flowers'
import TileDonation, {checkDonationTile} from './tile-donation'
import TileAddManager, {checkAddManagerTile} from './tile-add-managers'
import TileRequestManager, {checkRequestManagerTile} from './tile-request-manager'
import TileShare, {checkShareTile} from './tile-share'
import TileMemories, {checkMemoriesTile} from './tile-memories'
import TileLetter, {checkLetterTile} from './tile-letter'
import TileEditProfile, {checkEditProfileTile} from './tile-edit-profile'

const checkFunctions = {}

checkFunctions['flowers'] = checkFlowersTile
checkFunctions['book'] = checkBookTile
checkFunctions['donation'] = checkDonationTile
checkFunctions['add-manager'] = checkAddManagerTile
checkFunctions['request-manager'] = checkRequestManagerTile
checkFunctions['share'] = checkShareTile
checkFunctions['edit-profile'] = checkEditProfileTile
checkFunctions['memories'] = checkMemoriesTile
checkFunctions['letter'] = checkLetterTile

export const checkTile = (name, page, hasRole, Products) => {
  const fn = checkFunctions[name]
  if (fn) return fn(page, hasRole, Products)
  return false
}

export const Tiles = {}

Tiles['flowers'] = TileFlowers
Tiles['book'] = TileBook
Tiles['donation'] = TileDonation
Tiles['add-manager'] = TileAddManager
Tiles['request-manager'] = null // TileRequestManager
Tiles['share'] = TileShare
Tiles['edit-profile'] = TileEditProfile
Tiles['memories'] = TileMemories
Tiles['letter'] = TileLetter
