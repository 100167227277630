import React, {useRef, useEffect, useState} from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import {animateScroll} from 'react-scroll'
import fetch from 'isomorphic-fetch'

import {withGlobal} from '../shared/app'
import {asArray, shuffleArray, string} from '../../lib/utils'
import _ from 'lodash'

const MIN_FEED_SIZE = 25
const MICRO_FEED_SIZE = 10

const Block = ({page, cloudinary, auto = true, show = true, limit = true, Memories, lang}) => {
  const [memories, setMemories] = useState([])

  const loadFakeDataFile = async () => {
    const url = `https://raw.githubusercontent.com/imstack/features/main/feed/${lang}.json`

    let unfilteredFeed = {}
    try {
      const response = await fetch(url)
      unfilteredFeed = await response.json()
    } catch (e) {
      unfilteredFeed = require(`../files/feed/${lang}.json`)
    }

    return unfilteredFeed
  }

  const convertFakeArray = (array) => {
    return array.map((m) => {
      return {
        ...m,
        ...(m.i && {
          i: `https://res.cloudinary.com/inmemori/image/upload/v1610724899/splash/${m.i}`
        })
      }
    })
  }

  const loadFakeData = async () => {
    let unfilteredFeed = await loadFakeDataFile()

    const {age, gender} = page

    const asGender = !!gender && ['m', 'f'].includes(gender)
    let baseMemories = []

    for (let key in unfilteredFeed) {
      if (key === 'default') continue

      const s = asArray(key)
      const min = parseInt(s[0])
      const max = parseInt(s[1])

      if (age < min || age > max) continue

      if (asGender && s.length === 3) {
        const g = s[2]
        if (g === gender) baseMemories = unfilteredFeed[key]
      } else if (!asGender && s.length === 2) baseMemories = unfilteredFeed[key]
    }

    if (baseMemories.length === 0) {
      baseMemories = unfilteredFeed['default']
    }

    baseMemories = convertFakeArray(baseMemories)

    return baseMemories
  }

  const computeRealData = () => {
    if (!Memories.list || Memories.list.length === 0) return []

    if (Memories.list.length > MIN_FEED_SIZE) Memories.list.length = MIN_FEED_SIZE

    const res = Memories.list.map((m) => {
      let i = null
      if (m.images && m.images.length) i = cloudinary.url(m.images[0].hash)

      let p = 0
      if (m.message && m.message.length) {
        p = Math.ceil(m.message.length / 48)
        if (p > 12) p = 12
      }

      return {i, p, t: string.name(m.name)}
    })

    return res
  }

  const loadMemories = async () => {
    let realMemories = computeRealData()

    if (realMemories.length) {
      if (realMemories.length < MICRO_FEED_SIZE) {
        const diff = MICRO_FEED_SIZE - realMemories.length
        const unfilteredFeed = await loadFakeDataFile()
        const defaultFeed = convertFakeArray(unfilteredFeed.default)

        defaultFeed.length = diff

        realMemories = [...realMemories, ...defaultFeed]
      }

      setMemories(realMemories)
      return
    }

    const fakeMemories = await loadFakeData()

    if (!limit) {
      shuffleArray(fakeMemories)
      setMemories(fakeMemories)
      return
    }

    if (fakeMemories.length) {
      while (realMemories.length < MIN_FEED_SIZE) {
        let newSet = [...fakeMemories]
        shuffleArray(newSet)

        realMemories = [...realMemories, ...newSet]
      }
    }

    shuffleArray(realMemories)

    setMemories(realMemories)
  }

  const scrollRef = useRef(null)

  let timer = null

  const scroll = () => {
    if (scrollRef.current)
      animateScroll.scrollToBottom({
        container: scrollRef.current,
        duration: 80000,
        smooth: 'linear'
      })
  }

  const handleStopScrolling = () => {
    $('.autoscroll').scroll(
      _.debounce(function () {
        scroll()
      }, 150)
    )
  }

  useEffect(() => {
    loadMemories() // load memory infinite loop possible
  }, [Memories.list])

  useEffect(() => {
    if (auto) {
      timer = setTimeout(() => {
        handleStopScrolling()
        scroll()
      }, 1000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [])

  if (!show) return null

  return (
    <React.Fragment>
      <div ref={scrollRef} className='container pt-3 no-select autoscroll'>
        <div className='grid-fluid'>
          {memories &&
            memories.map((m, i) => {
              if (!m.p) {
                if (m.i) m.p = Math.floor(Math.random() * Math.floor(4)) + 2
                else m.p = Math.floor(Math.random() * Math.floor(6)) + 6
              }

              if (m.p) {
                var message = []
                for (let i = 0; i < m.p; i++) {
                  if (i + 1 < m.p)
                    message.push(
                      <Skeleton
                        variant='text'
                        animation={false}
                        height={10}
                        width='100%'
                        style={{marginBottom: 6}}
                        key={i}
                      />
                    )
                  else
                    message.push(
                      <Skeleton
                        variant='text'
                        animation={false}
                        height={10}
                        width='60%'
                        style={{marginBottom: 6}}
                        key={i}
                      />
                    )
                }
              }

              return (
                <div className='cell' key={i}>
                  <div className='z1'>
                    <div className='d-flex justify-content-between align-items-top mb-1'>
                      <h4 className='text-break'>{m.t}</h4>
                    </div>

                    <div className='message pre-line text-light'>{message}</div>

                    {m.i && (
                      <ul className={`photos list-unstyled m-0 ${m.m && m.m > 0 ? 'mt-2' : ''}`}>
                        <li className='mt-3'>
                          <img src={m.i} className='d-block img-fluid w-100 mx-auto mt-2' />
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              )
            })}
        </div>
        {/* end grid */}
      </div>
      <style jsx>
        {`
          .z1 {
            padding: 10px;
            border: 1px solid #dadada;
            border-radius: 16px;
          }

          .photos li {
            margin-bottom: 10px;
          }
          .photos li:last-child {
            margin-bottom: 0px;
          }
          .photos li img {
            border-radius: 16px;
          }

          // GRID
          .container::-webkit-scrollbar {
            display: none;
          }

          .container {
            height: 100%;
            overflow-y: scroll;
            padding: 0px;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }

          .grid-fluid {
            position: relative;
            column-count: 3;
            column-gap: 30px;
            scroll-behavior: smooth;
          }

          @media (max-width: 1199px) {
            // xl down
            .grid-fluid {
              column-count: 3;
              -webkit-column-count: 3;
              column-gap: 30px;
            }
            .grid-fluid .cell {
              padding-bottom: 20px;
            }
          }

          @media (max-width: 991px) {
            // lg down
            .grid-fluid {
              column-count: 2;
              -webkit-column-count: 2;
              column-gap: 15px;
            }
            .grid-fluid .cell {
              padding-bottom: 15px;
            }
          }

          @media (max-width: 767px) {
            // md down
            .grid-fluid {
              column-count: 2;
              -webkit-column-count: 2;
              column-gap: 20px;
            }
            .z1 {
              border-radius: 8px;
            }
            .grid-fluid .cell {
              padding-bottom: 10px;
            }
            .photos li img {
              border-radius: 8px;
            }
          }

          @media (max-width: 605px) {
            // sm down
            .grid-fluid {
              column-gap: 10px;
              padding: 0 10px;
            }
          }

          .grid-fluid .cell {
            break-inside: avoid;
            -webkit-column-break-inside: avoid;
            padding-bottom: 20px;
          }
        `}
      </style>
    </React.Fragment>
  )
}

export default withGlobal(Block)
