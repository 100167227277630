import React from 'react'
import {withGlobal} from '../../shared/app'

const Block = ({flow, t, page, uiref, hasRole, Products}) => {
  const onClick = () => {
    flow.set(`ft-book`, {uiref})
  }

  if (!checkBookTile(page, hasRole, Products)) return null

  return (
    <div>
      <div onClick={onClick} className='actions py-3 mb-3 cursor tile-book text-center'>
        <img width='100px' src={t(`tiles.book.icon`)} />
        <h4 className='mt-3 mb-0'>{t(`tiles.book.title`)}</h4>
      </div>

      <style jsx>
        {`
          .actions {
            border: 1px solid #dadada;
            border-radius: 8px;
          }
          .actions:hover {
            background: #eff2f2;
          }
        `}
      </style>
    </div>
  )
}

export const checkBookTile = (page, hasRole, Products) => {
  return Products.Book.isVisible()
}

export default withGlobal(Block)
