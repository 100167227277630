import React, {useEffect} from 'react'
import {withGlobal} from './app'

import Auth from '../../lib/auth'
import Spinner from '../misc/spinner'

// if i visit inmemori.com/logout
// this component will be called without "page" and "t" function.
// if i visit inmemori.com/someslug-123/logout, it will benefit thoses props

const Page = ({page = {}, t = () => {}}) => {
  const {slug} = page
  const redirect = slug ? `/${slug}` : `/`

  useEffect(() => {
    setTimeout(() => {
      Auth().logout({redirect})
    }, 2000)
  }, [])

  return (
    <div className='card'>
      <div className='card-body text-center mb-5 pb-5 text-center' style={{minHeight: '200px'}}>
        <Spinner />
        <h3 className='mt-4'>{t(`common.logout`) || 'Log Out'}</h3>
      </div>
    </div>
  )
}

export default withGlobal(Page)
