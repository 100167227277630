import React, {useState, useMemo} from 'react'
import {withGlobal} from '../shared/app'
import moment from 'moment'
import {Btn, BtnSpin} from '../misc/buttons'

import InputMask from 'react-input-mask'
import {setErrors} from '../../lib/utils'
import DateInput from '../misc/date-input'

const EditBroadcast = ({page, setPage, t, api, lang, flow, toaster}) => {
  const [pristine, setPristine] = useState(true)
  const [isLoading, setLoading] = useState(false)

  const broadcast = useMemo(() => page.settings.broadcast, [page])

  const defaultForm = broadcast || {url: null, date: null, info: null}

  const [form, setForm] = useState(defaultForm)

  const onTimeChange = (e) => {
    let time = e.target.value.replace(/\s/g, '')
    let [hour, minute] = time.split(':')
    let date = moment(form.date || undefined)
    // if we get 4 digit go on
    if (/[0-9]{4}/.test(`${hour}${minute}`)) {
      hour = parseInt(hour, 10)
      minute = parseInt(minute, 10)
      // if time is valid update place.date
      if (hour < 24 && minute < 60) {
        date.startOf('day')
        date.add(hour, 'hours')
        date.add(minute, 'minutes')

        setForm({...form, date: date.toDate()})
        setPristine(false)
      }
    }
  }

  const onDateChange = (date) => {
    setForm({...form, date: date ? date.toDate() : null})
    setPristine(false)
  }

  const save = async () => {
    setLoading(true)
    try {
      const update = await api.put(`/pages/${page.slug}`, {
        body: {settings: {broadcast: form}}
      })
      const errs = setErrors(update)
      if (errs) throw errs

      setPage(update)
      toaster.success()
      flow.set('prev')
    } catch (e) {
      console.log('err.save', e)
      toaster.error()
    }
    setLoading(false)
  }

  const onDelete = async () => {
    setLoading(true)
    try {
      const update = await api.put(`/pages/${page.slug}`, {
        body: {settings: {broadcast: null}}
      })
      const errs = setErrors(update)
      if (errs) throw errs

      setPage(update)
      setForm({url: '', date: null, info: null})
      toaster.success()
    } catch (e) {
      console.log('err.save', e)
      toaster.error()
    }
    setLoading(false)
  }

  const adviceChannels = t(`broadcast.labels.setup.channels`, true) || []

  return (
    <div className='mt-5'>
      <div>
        <div>
          <div className='mb-3'>{t('broadcast.labels.desc')}</div>
          <div className='form-group'>
            <input
              type='text'
              name='name'
              value={form.url}
              onChange={(e) => {
                setForm({...form, url: e.target.value})
                setPristine(false)
              }}
              className='form-control'
              placeholder={t('broadcast.labels.link')}
            />
          </div>
          <div className='form-group date-input d-flex justify-content-between'>
            <div style={{flex: '1'}}>
              <DateInput
                value={form.date ? moment(form.date).tz(form.tz || moment.tz.guess()) : null}
                onChange={onDateChange}
                locale={lang}
                minDate={moment().add(-10, 'years')}
                maxDate={moment().add(10, 'years')}
                placeholderText={t('broadcast.labels.date')}
                attached
              />
            </div>
            <div className='' style={{width: '120px', marginLeft: '-1px'}}>
              <InputMask
                inputMode='numeric'
                defaultValue={
                  form.date &&
                  moment(form.date)
                    .tz(form.tz || moment.tz.guess())
                    .format('HH:mm')
                }
                mask='H9 : 99'
                maskChar='_'
                formatChars={{9: '[0-9]', a: '[A-Za-z]', '*': '[A-Za-z0-9]', H: '[0-2]'}}
                onChange={(e) => onTimeChange(e)}
                className='form-control text-center radius-left-none'
                placeholder='hh : mm'
              />
            </div>
          </div>

          <div className='form-group'>
            <textarea
              name='info'
              value={form.info}
              onChange={(e) => {
                setForm({...form, info: e.target.value})
                setPristine(false)
              }}
              maxLength='200'
              placeholder={t('broadcast.ph.info')}
              rows='3'
              className='form-control'
            />
          </div>
          <div className='d-inline-flex w-100 flex-row justify-content-end'>
            {page.settings.broadcast && (
              <div className='d-inline flex-grow-1'>
                <Btn
                  className='btn btn-link text-danger ml-2'
                  onClick={() => onDelete()}
                  value={t('broadcast.cta.delete')}
                  icon='https://res.cloudinary.com/inmemori/image/upload/v1589806556/web-assets/picto/delete.svg'
                />
              </div>
            )}
            <div>
              <button onClick={() => flow.set('prev')} className='btn btn-outline-primary mr-3'>
                {t('common.back')}
              </button>
            </div>
            <div>
              <BtnSpin
                pristine={pristine}
                isLoading={isLoading}
                onClick={() => save()}
                className='m-0 btn btn-primary'
              >
                {t('common.save')}
              </BtnSpin>
            </div>
          </div>
          <div className='p-3 advice rounded-lg pre-line mt-4'>
            <div>{t('broadcast.labels.setup.title')}</div>
            {adviceChannels.map((channel, index) => {
              const advice = channel.advice
              const title = channel.title
              return (
                <div key={`advice-channels.${index}`} className='mt-1'>
                  {title && <span>{title}</span>}
                  {advice && advice.length > 0 && (
                    <ul>
                      {advice.map((advice, index) => {
                        const link = advice.link
                        return (
                          <li key={`advice-channels.link.${index}`}>
                            <span>
                              {advice.label}
                              {link && (
                                <a rel='noreferrer' target='_blank' href={link}>
                                  {link}
                                </a>
                              )}
                            </span>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .advice {
            border: dashed 1px #32735f;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(EditBroadcast)
