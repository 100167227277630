import {useState, useEffect} from 'react'
import _ from 'lodash'

const getChange = (e) => {
  const {target} = e
  const {type, name} = target

  let value = target.value

  if (type == 'checkbox') value = target.checked
  if (type == 'number' && typeof value == 'string') {
    value = parseInt(value, 10)
    if (isNaN(value)) value = undefined
  }

  return {[name]: value}
}

const cleanObject = (o = {}, fn) => {
  const cleaned = _.reduce(
    Object.keys(o),
    (acc, key) => {
      const value = o[key]
      if (value) acc[key] = value
      if (value && fn) fn(acc, key)
      return acc
    },
    {}
  )

  return Object.keys(cleaned).length ? cleaned : undefined
}

const cleanOptions = (o = {}) => {
  const cleaned = _.reduce(
    Object.keys(o),
    (acc, key) => {
      const value = o[key]
      if (value && value.selected) {
        acc[key] = _.pickBy({...value})
        delete acc[key].selected
      }
      return acc
    },
    {}
  )

  return Object.keys(cleaned).length ? cleaned : undefined
}

const defaultForm = (env = {}) => ({
  client: {
    firstname: env.debug ? 'foo' : '',
    lastname: env.debug ? 'bar' : '',
    email: '',
    phone: ''
  },
  tx: {
    amount: 0,
    ch_id: ''
  },
  item: {
    _id: '',
    name: '',
    product: '',
    qty: 0,
    amount: 0,
    price: 0,
    price_int: 0,
    catalog: {},
    specs: {},
    format: 'default'
  },
  shipping: {
    name: '',
    phone: '',
    price: 0,
    price_int: 0,
    address: '',
    address_short: '',
    city: '',
    zipcode: '',
    country: '',
    country_code: '',
    latlng: '',
    place_id: '',
    street_number: '',
    street: '',
    state: '',
    region: '',
    info: '',
    additionalInfo: ''
  },
  invoice: {
    name: '',
    address: '',
    city: '',
    zipcode: '',
    vat: '',
    phone: '',
    email: ''
  },
  meta: {
    print: [],
    message: '',
    uiref: {}
  },
  options: {},
  config: {}
})

export const useOrder = (data = {}, {env, feature, currency}) => {
  const [order, setOrder] = useState(defaultForm(env))
  const dsl = {}

  const Keys = ['client', 'tx', 'item', 'invoice', 'shipping', 'meta', 'options', 'config']
  Keys.map((ns) => {
    const object = {...order[ns], ...data[ns]}
    const [state, setState] = useState(object)

    dsl[ns] = state
    dsl[`set${ns.capitalize()}`] = (input, merge = true) => {
      if (merge) setState({...state, ...input})
      else setState(input)

      if (merge) setOrder({...order, [ns]: {...order[ns], ...input}})
      else setOrder({...order, [ns]: input})
    }
  })

  dsl.setOrder = (mergeOrder) => {
    const newOrder = {...order}
    Object.keys(mergeOrder).map((k) => {
      newOrder[k] = {...newOrder[k], ...mergeOrder[k]}
    })
    setOrder(newOrder)
    return this
  }

  dsl.feature = feature

  dsl.reset = () => {
    dsl.setTx({}, false)
    dsl.setMeta({}, false)
  }

  dsl.raw = () => _.pick(dsl, ...Keys)

  dsl.vanilla = () => {
    const order = dsl.raw()
    let data = {}

    data.feature = feature
    data.client = cleanObject(order.client)

    data.item = cleanObject(
      _.pick(
        order.item,
        '_id',
        'name',
        'amount',
        'discount',
        'qty',
        'format',
        'product',
        'catalog',
        'specs',
        'ctx'
      )
    )
    if (data.item.catalog) data.item.catalog = cleanObject(_.pick(data.item.catalog, 'key'))
    if (data.item.specs) data.item.specs = cleanObject(data.item.specs)
    if (order.options) data.item.options = cleanOptions(order.options)
    if (order.config)
      data.item.config = cleanObject(_.cloneDeep(order.config), (acc, key) => delete acc[key].files)

    data.shipping = cleanObject(order.shipping)
    data.invoice = cleanObject(order.invoice)
    data.meta = cleanObject(order.meta)

    data.tx = cleanObject(order.tx) || {}
    data.tx.amount = dsl.getTotalPrice(false).times100()
    data.tx.item = dsl.getItemPrice(false, true).times100()
    data.tx.shipping = dsl.getShippingPrice(false).times100()
    data.tx.options = dsl.getOptionsPrice(false).times100()

    return cleanObject(data)
  }

  dsl.onChange = (ns) => (e) => {
    const update = getChange(e)
    dsl[`set${ns.capitalize()}`](update)
  }

  dsl.onQtyChange = (qty) => {
    const {metagrid = {}, format} = dsl.item
    const q = metagrid[format] && metagrid[format][qty]
    q && dsl.setItem({amount: q.amount, discount: (q.discountInt || 0).times100()})
  }

  dsl.hasDiscount = () => {
    const format = dsl.item.format
    const metagrid = dsl.item.metagrid && dsl.item.metagrid[format]
    const discounts = metagrid
      ? Object.keys(metagrid)
          .map((qty) => metagrid[qty].discountInt)
          .filter((discount) => discount > 0)
      : []
    return !!discounts.length
  }

  dsl.hasOption = (opt) => {
    const {key} = opt
    const current = {...dsl.options}
    return !!current[key].selected
  }

  dsl.sanitizeOptions = (input) => {
    let copy = {...input}
    copy = _.reduce(
      Object.keys(copy),
      (acc, key) => {
        if (copy[key].active) {
          acc[key] = {...copy[key]}
          delete acc[key].active
        }
        return acc
      },
      {}
    )
    return copy
  }

  dsl.toggleOption = (opt) => {
    const {key} = opt
    const current = {...dsl.options}
    current[key].selected = !current[key].selected
    if (!current[key].selected) delete current[key].selected
    dsl.setOptions(current, false)
  }

  dsl.onOptionChange = (opt) => (e) => {
    const update = getChange(e)
    const current = {...dsl.options}
    current[opt.key] = {...current[opt.key], ...update}
    dsl.setOptions(current)
  }

  dsl.getOptionsList = (fromOrder = false) => {
    const {options = {}} = fromOrder ? dsl : dsl.item
    const keys = Object.keys(options)

    const list = _(keys)
      .map((k) => {
        const opt = options[k]
        return {...opt, key: k}
      })
      .compact()
      .value()
    return list
  }

  dsl.getDiscount = (formated = true) => {
    if (!dsl.hasDiscount()) return 0
    const {qty, format, metagrid} = dsl.item
    let discount = 0

    if (metagrid && metagrid[format] && metagrid[format][qty])
      discount = metagrid[format][qty].discountTotalInt

    return formated ? discount.money(currency.ticker) : discount
  }

  dsl.getItemPrice = (formated = true, withQuantity) => {
    const {qty = 1, format, metagrid} = dsl.item
    let price = dsl.item.price_int

    if (metagrid && metagrid[format] && metagrid[format][qty])
      price = metagrid[format][qty].totalInt
    else price = withQuantity ? price * qty : price

    return formated ? price.money(currency.ticker) : price
  }

  dsl.getOptionPrice = (optionKey, formated = true) => {
    const {amount = 0, scale} = dsl.options[optionKey]
    const {qty = 1} = dsl.item
    const price = (amount * qty) / 100
    return formated ? `+${price.money(currency.ticker)}` : price
  }

  dsl.getOptionsPrice = (formated = true) => {
    const {options = {}} = dsl
    const {qty = 1} = dsl.item

    const keys = Object.keys(options)
    let price = _.reduce(
      keys,
      (acc, key) => {
        const {selected, amount} = options[key]
        if (selected && amount) acc += qty * amount
        return acc
      },
      0
    )
    price = price / 100
    return formated ? price.money(currency.ticker) : price
  }

  dsl.getShippingPriceForCodezone = () => {
    const {format, shipping} = dsl.item
    const {codezones = {}, shipzones = {}} = shipping

    const cc = dsl.shipping.country_code.toLowerCase()
    const zone = codezones[cc]
    const formats = shipzones[zone]
    const p = formats[format]
    return p
  }

  dsl.getShippingPrice = (formated = true) => {
    const {shipping} = dsl
    let priceInt = shipping.priceInt || 0

    // if codezones present, get codezone shipping price
    if (dsl.item.shipping && dsl.item.shipping.codezones && shipping.country_code) {
      try {
        priceInt = dsl.getShippingPriceForCodezone()
        return formated ? priceInt.money(currency.ticker) : priceInt
      } catch (e) {
        console.log('Codezone shipping price error')
      }
    }
    return formated ? priceInt.money(currency.ticker) : priceInt
  }

  dsl.getTotalPrice = (formated = true) => {
    const itemPrice = dsl.getItemPrice(false, true)
    const shippingPrice = dsl.getShippingPrice(false)
    const optionsPrice = dsl.getOptionsPrice(false)
    const price = itemPrice + shippingPrice + optionsPrice
    return formated ? price.money(currency.ticker) : price
  }

  dsl.getTotalPriceWithoutShipping = (formated = true) => {
    const price = dsl.getItemPrice(false, true, true) + dsl.getOptionsPrice(false)
    return formated ? price.money(currency.ticker) : price
  }

  useEffect(() => {
    dsl.onQtyChange(dsl.item.qty || 1)
  }, [dsl.item.qty])

  return dsl
}
