import {withGlobal} from '../../shared/app'
import {BtnEdit} from '../../widgets/btn-edit'
import moment from 'moment'

const Block = ({page, hasRole, flow, t, uiref = {}, className = ''}) => {
  const editMode = hasRole('manager,broker')

  const getIntro = (index, page = {}) => {
    return t(`edit-intro.templates.${index}`, {
      firstname: page.firstname,
      fullname: page.fullname,
      dod: (page.dod && moment(page.dod).format(t('dates.short'))) || '',
      age: page.age || (page.dob && page.dod && moment(page.dod).diff(page.dob, 'years'))
    })
  }

  const intro = page.intro || getIntro(0, page)

  return (
    <div className={`card ${className}`}>
      <div className='card-body py-4 col-12'>
        <p className='intro f2 pre-line'>{intro}</p>
        {/*<Linkify className="f2 bold" text={page.intro || getIntro(0, page)} />*/}
        {editMode && <BtnEdit onClick={() => flow.set('edit-intro', {uiref})} />}
      </div>

      <style jsx>
        {`
          .intro {
            font-size: 24px;
          }

          .intro:first-letter {
            font-family: 'newyork-bold';
            float: left;
            font-size: 78px;
            line-height: 72px;
            padding-top: 0px;
            padding-right: 10px;
          }

          // md down
          @media (max-width: 767px) {
            .intro {
              font-size: 20px;
              line-height: 28px;
            }

            .intro:first-letter {
              font-family: 'newyork-bold';
              float: left;
              font-size: 60px;
              line-height: 50px;
              padding-top: 4px;
              padding-right: 10px;
            }
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Block)
