import React from 'react'
import {withGlobal} from '../../shared/app'
import CardTop from '../../widgets/card-top'
import NavBar from '../../widgets/nav-bar'
import {openInNewTab} from '../../../lib/utils'

const Block = ({flow, page, t, lang}) => {
  return (
    <div className='card'>
      <NavBar showPrev={false} onClick={() => flow.set('prev')}>
        {t(`tab-help.title`)}
      </NavBar>

      <div className='card-body'>
        <CardTop title={t('tab-help.title')} />

        <div className='d-flex flex-column justify-content-between align-items-stretch text-center mt-md-5'>
          <a href={`tel:${page.support.phone}`} className='action py-4 mb-3 cursor'>
            <img width='100px' src={t(`tab-help.contact.icon`)} />
            <h4 className='mt-3 mb-0'>{page.support.phone}</h4>
          </a>
          <div
            onClick={() => openInNewTab(`https://inmemori-${lang}.helpscoutdocs.com`)}
            className='action py-4 mb-3 cursor'
          >
            <img width='100px' src={t(`tab-help.faq.icon`)} />
            <h4 className='mt-3 mb-0'>{t(`tab-help.faq.title`)}</h4>
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .action {
            border: 1px solid #dadada;
            border-radius: 8px;
            color: #222222;
          }
          .action:hover {
            background: #eff2f2;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Block)
