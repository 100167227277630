import React, {useState, useEffect, useMemo} from 'react'
import {withGlobal} from '../../shared/app'
import {StripeInput, PayToEndpoint} from '../../../lib/stripe'
import {setErrors, failValidation, asArray} from '../../../lib/utils'
import CardTop from '../../widgets/card-top'
import NavBar from '../../widgets/nav-bar'
import {Btn, BtnSpin} from '../../misc/buttons'
import {Flux, FluxBar, useFlux} from '../../misc/flux'
import {loadStripe} from '@stripe/stripe-js'
import Countries from 'i18n-iso-countries'
import ImageGallery from '../../misc/image-gallery'
import LetterSlideBuilder from '../../misc/letter-slide-builder'
import {useOrder} from '../../store/order'
import Cart from '../../widgets/cart'
import AddressForm from '../../misc/address-form'
import Popup from '../../../lib/popup'

const dummyConfig = {
  slide1: {mode: 'icon', value: 'bird'},
  slide2: {mode: 'text', value: 'DUMMY'},
  slide3: {mode: 'empty'},
  slide4: {mode: 'empty'}
}

const Block = ({
  page,
  Page,
  flow,
  t,
  lang,
  toaster,
  api,
  query,
  env,
  _profile,
  tracker,
  Beacons,
  uiref,
  Products
}) => {
  const [isLoading, setLoading] = useState(false)
  const [stripe, setStripe] = useState(null)
  const [stripeCard, setStripeCard] = useState(null)
  const [withInvoice, setWithInvoice] = useState(false)

  const {currency} = page

  const order = useOrder(
    {
      client: {email: _profile.email},
      shipping: {
        firstname: _profile.firstname,
        lastname: _profile.lastname,
        phone: _profile.phone,
        email: _profile.email
      },
      meta: {uiref},
      config: {slide1: {}, slide2: {}, slide3: {}, slide4: {}}
    },
    {
      feature: 'letter',
      featureCfg: Products.feature('letter'),
      currency,
      env
    }
  )

  const defaultSubs = useMemo(() => {
    return {
      page,
      product: order.item
    }
  }, [page, order.item])

  const flux = useFlux({
    steps: t(`ft-letter.steps`, true) || [],
    defaultStep: () => query.step,
    onCheckpoint: tracker.funnelOrder('letter', false, order),
    onChange: () => {
      document.documentElement.scrollTop = 0
    },
    hasPrev: ({step}) => !['intro', 'success'].includes(step.key),
    onPrevMiss: () => flow.set('prev'),
    t: ({step}) => (key, subs, ref) =>
      t(`ft-letter.steps.${(ref || step || {}).index}.${key}`, subs)
  })

  const {step, setStep, isStep, getSteps} = flux

  const popupValidation = () => {
    Popup.letterValidation(
      {
        title: t('ft-letter.popup.title', defaultSubs),
        message: t('ft-letter.popup.message', defaultSubs),
        btns: [t('ft-letter.popup.validate', defaultSubs), t('ft-letter.popup.edit', defaultSubs)],
        config: order.config,
        Products,
        t
      },
      () => setStep('next')
    )
  }

  const submit = async () => {
    if (failValidation('client.firstname,client.lastname,client.email', t)) return
    setLoading(true)
    try {
      const payload = order.vanilla()

      let update = await api.post(`/pages/${page.slug}/letter`, {
        body: {...payload, validate: true}
      })
      if (!update.success) {
        // exit flow if update fails
        if (update.err) toaster.error(`oups! ${update.err}`)
        setErrors(update)
        return setLoading(false)
      }

      // pass auto generated order_id
      payload.order_id = update.order_id

      const paymentParams = [
        stripe,
        stripeCard,
        `${process.env.API_URI}/pages/${page.slug}/payment`,
        {billing_details: {name: `${order.client.firstname} ${order.client.lastname}`}},
        payload
      ]

      // try payment
      const payment = await PayToEndpoint(...paymentParams)

      // payment error
      if (!(payment && payment.status == 'succeeded')) {
        console.log('payment.error', payment)
        toaster.error(t('common.errors.missing.payment'))
        return setLoading(false)
      }

      // assign tx info to payload
      payload.tx = payment

      // save order
      let letterOrder = await api.post(`/pages/${page.slug}/letter`, {body: payload})
      let errs = setErrors(letterOrder)
      if (errs) throw errs

      order.setTx(letterOrder.tx)

      Page.reloadPage()
      setStep('next')
    } catch (e) {
      console.log('err.submit', e)
    }
    setLoading(false)
  }

  const submitShipping = () => {
    if (
      failValidation(
        'shipping.firstname,shipping.lastname,shipping.address,shipping.email,shipping.phone',
        t
      )
    )
      return

    if (!order.shipping.country || !order.shipping.address || !order.shipping.city) return

    if (
      withInvoice &&
      failValidation('invoice.name,invoice.address,invoice.zipcode,invoice.city,invoice.email', t)
    )
      return

    if (withInvoice) {
      order.setClient({email: order.invoice.email, phone: order.invoice.phone})
    } else {
      order.setClient({
        email: order.shipping.email,
        phone: order.shipping.phone,
        firstname: order.shipping.firstname,
        lastname: order.shipping.lastname
      })
    }

    setStep('next')
  }

  const reset = () => {
    order.reset()
    flow.set('tab-infos')
  }

  const isConfigPristine = useMemo(() => {
    return (
      [1, 2, 3, 4]
        .map((slide) => Products.Letter.slideStatus(slide, order.config))
        .filter((status) => status === 'on').length != 4
    )
  }, [order.config])

  useEffect(() => {
    asArray(process.env.LANGS).forEach((l) => {
      Countries.registerLocale(require(`i18n-iso-countries/langs/${l}.json`))
    })
    ;(async () => {
      // init stripe
      setStripe(await loadStripe(process.env.STRIPE_KEY))
    })()

    if (query.dummy) order.setConfig(dummyConfig)
  }, [])

  useEffect(() => {
    if (Object.keys(Products.Letter.product).length) {
      order.setItem({...Products.Letter.product, qty: 1})
      order.setOptions(order.sanitizeOptions(Products.Letter.product.options), false)
    }
  }, [Products.products])

  if (!step) return <div className='py-5 text-center'>{`step "${query.step}"does not exist`}</div>

  return (
    <Flux flux={flux} debug={env.debug}>
      <div className='card'>
        {env.local && (
          <pre className='d-none d-md-block debug-pre'>
            {JSON.stringify(order.vanilla(), null, 2)}
          </pre>
        )}

        <NavBar onClick={() => setStep('prev')}>{step.title}</NavBar>

        <div className='card-body errors-scope'>
          {!isStep('intro,success') && (
            <FluxBar steps={getSteps('intro,success', true)} current={step} flux={flux} />
          )}

          <CardTop
            title={flux.t('title', defaultSubs)}
            subtitle={flux.t('subtitle', defaultSubs)}
          />

          <React.Fragment>
            {
              /* STEP */ isStep('intro') && order.item.name && (
                <div className='d-flex flex-column flex-lg-row align-items-center align-items-lg-stretch'>
                  <div className='left-col pr-md-3'>
                    <ImageGallery
                      className='w-100'
                      images={Products.Letter.defaultImages(t)}
                      optimize={false}
                      shadow
                      zoomMargin={150}
                    />
                    <div className='d-flex flex-column text-center'>
                      <BtnSpin
                        isLoading={isLoading}
                        onClick={() => setStep('next')}
                        className='d-lg-none btn btn-primary mt-3 mb-5'>
                        {flux.t('cta')}
                      </BtnSpin>
                    </div>
                  </div>

                  <div className='right-col px-1'>
                    <div className='d-flex flex-column justify-content-between h-100'>
                      <div className='desc text-light'>
                        <b className='ml-3'>{t('ft-letter.intro.content.key', defaultSubs)}</b>
                        <ul className='bullets mt-2'>
                          {t('ft-letter.intro.content.values', true) &&
                            t('ft-letter.intro.content.values', true).map((v, index) => (
                              <li key={index} className='pre-line'>
                                {t(`ft-letter.intro.content.values.${index}`, defaultSubs)}
                              </li>
                            ))}
                        </ul>

                        <div className='mt-4'>
                          <b className='ml-3'>{t('ft-letter.intro.technical.key', defaultSubs)}</b>
                          <ul className='bullets mt-2'>
                            {t('ft-letter.intro.technical.values', true) &&
                              t('ft-letter.intro.technical.values', true).map((v, index) => (
                                <li key={index} className='pre-line'>
                                  {t(`ft-letter.intro.technical.values.${index}`, defaultSubs)}
                                </li>
                              ))}
                          </ul>
                        </div>
                      </div>

                      <div className='d-flex justify-content-end mt-5'>
                        <button
                          onClick={() => setStep('prev')}
                          className='d-none d-md-block m-0 btn btn-outline-primary mr-2'>
                          {t('common.back')}
                        </button>
                        <BtnSpin
                          isLoading={isLoading}
                          onClick={() => setStep('next')}
                          className='d-none d-lg-block btn btn-primary m-0 '>
                          {flux.t('cta')}
                        </BtnSpin>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

            {
              /* STEP */ isStep('config') && order.item.name && (
                <div className='d-flex flex-column'>
                  {Products.Letter.settings().map((slide) => (
                    <LetterSlideBuilder
                      key={`letter-slide-builder-${slide.index}`}
                      settings={slide}
                      config={order.config}
                      onChange={order.setConfig}
                      subs={defaultSubs}
                    />
                  ))}
                </div>
              )
            }

            {
              /* STEP */ isStep('cart') && order.item.name && (
                <div className='row d-flex align-items-center'>
                  <ImageGallery
                    className='col-12 col-sm-6 col-md-12 col-lg-6 px-md-5 mb-4 mb-lg-0'
                    images={Products.Letter.slides(order.config, t)}
                    optimize={false}
                    shadow
                    zoomMargin={150}
                  />
                  <div className='col-12 col-sm-6 col-md-12 col-lg-6'>
                    <Cart
                      order={order}
                      editQty
                      showOptions
                      showPrice={false}
                      customComponent={() => (
                        <button
                          onClick={() =>
                            order.setItem({
                              qty: Math.ceil(
                                order.item.ctx.nbContributors / parseInt(order.item.specs.bundle)
                              )
                            })
                          }
                          className='w-100 mb-3 px-2 btn text-light'
                          style={{background: '#fbfbfb', marginTop: '-13px', fontSize: '11px'}}>
                          {t('ft-letter.cta.presetQty', defaultSubs)}
                          <i className='fas fa-cart-plus ml-2'></i>
                        </button>
                      )}
                    />
                    <div className='d-flex justify-content-center justify-content-md-end'>
                      <button
                        onClick={() => {
                          order.setItem({...Products.Letter.product, qty: 1}, false)
                          order.setOptions(
                            order.sanitizeOptions(Products.Letter.product.options),
                            false
                          )
                          order.setConfig({step1: {}, step2: {}, step3: {}, step4: {}}, false)
                          setStep('intro')
                        }}
                        className='d-none d-md-block m-0 btn btn-outline-primary mr-2'>
                        {t('common.cancel')}
                      </button>
                      <BtnSpin
                        isLoading={isLoading}
                        onClick={() => setStep('next')}
                        className='btn btn-primary m-0 '>
                        {flux.t('cta')}
                      </BtnSpin>
                    </div>
                  </div>
                </div>
              )
            }

            {
              /* STEP */ isStep('shipping') && (
                <div>
                  <div className='form-group row'>
                    <div className='col-6' style={{paddingRight: '8px'}}>
                      <input
                        errkey='shipping.firstname'
                        name='firstname'
                        value={order.shipping.firstname}
                        onChange={order.onChange('shipping')}
                        type='text'
                        className='form-control'
                        placeholder={t('common.firstname')}
                      />
                    </div>
                    <div className='col-6' style={{paddingLeft: '8px'}}>
                      <input
                        errkey='shipping.lastname'
                        name='lastname'
                        value={order.shipping.lastname}
                        onChange={order.onChange('shipping')}
                        type='text'
                        className='form-control'
                        placeholder={t('common.lastname')}
                      />
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-6' style={{paddingRight: '8px'}}>
                      <input
                        errkey='shipping.email'
                        name='email'
                        value={order.shipping.email}
                        onChange={order.onChange('shipping')}
                        type='email'
                        className='form-control'
                        placeholder={t('common.email')}
                      />
                      <div className='err-alert alert alert-danger-light p-1 my-2 d-none'>
                        email err zone
                      </div>
                    </div>
                    <div className='col-6' style={{paddingLeft: '8px'}}>
                      <input
                        errkey='shipping.phone'
                        name='phone'
                        value={order.shipping.phone}
                        onChange={order.onChange('shipping')}
                        type='text'
                        className='form-control'
                        placeholder={t('common.phone')}
                      />
                      <div className='err-alert alert alert-danger-light p-1 my-2 d-none'>
                        phone err zone
                      </div>
                    </div>
                  </div>

                  <AddressForm
                    className='mt-2'
                    form={order.shipping}
                    onChange={order.setShipping}
                    countries={Object.keys(Products.Letter.product.shipping.codezones).map((c) =>
                      c.toUpperCase()
                    )}
                  />

                  {order.shipping.zipcode && (
                    <form>
                      <div className='form-group text-lighter'>
                        <label className='switch w-100 d-flex align-items-center justify-content-center cursor m-0'>
                          <span className='small'>{t('order.invoice.with')}</span>
                          <input
                            type='checkbox'
                            name='privacy'
                            checked={withInvoice}
                            onChange={() => setWithInvoice(!withInvoice)}
                          />
                          <span className='switcher ml-3'></span>
                        </label>
                      </div>
                    </form>
                  )}

                  {withInvoice && (
                    <div>
                      <hr />
                      <div className='form-group'>
                        <input
                          errkey='invoice.name'
                          name='name'
                          value={order.invoice.name}
                          onChange={order.onChange('invoice')}
                          type='text'
                          className='form-control'
                          placeholder={t('common.name')}
                        />
                      </div>
                      <div className='form-group'>
                        <input
                          name='vat'
                          value={order.invoice.vat}
                          onChange={order.onChange('invoice')}
                          type='text'
                          className='form-control'
                          placeholder={t('common.vat')}
                        />
                      </div>
                      <div className='form-group'>
                        <input
                          errkey='invoice.address'
                          name='address'
                          value={order.invoice.address}
                          onChange={order.onChange('invoice')}
                          type='text'
                          className='form-control'
                          placeholder={t('common.address')}
                        />
                      </div>
                      <div className='form-group row'>
                        <div className='col-6' style={{paddingRight: '8px'}}>
                          <input
                            errkey='invoice.zipcode'
                            name='zipcode'
                            value={order.invoice.zipcode}
                            onChange={order.onChange('invoice')}
                            type='text'
                            className='form-control'
                            placeholder={t('common.zipcode')}
                          />
                        </div>
                        <div className='col-6' style={{paddingLeft: '8px'}}>
                          <input
                            errkey='invoice.city'
                            name='city'
                            value={order.invoice.city}
                            onChange={order.onChange('invoice')}
                            type='text'
                            className='form-control'
                            placeholder={t('common.city')}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-6' style={{paddingRight: '8px'}}>
                          <input
                            errkey='invoice.email'
                            name='email'
                            value={order.invoice.email}
                            onChange={order.onChange('invoice')}
                            type='email'
                            className='form-control'
                            placeholder={t('common.email')}
                          />
                        </div>
                        <div className='col-6' style={{paddingLeft: '8px'}}>
                          <input
                            errkey='invoice.phone'
                            name='phone'
                            value={order.invoice.phone}
                            onChange={order.onChange('invoice')}
                            type='text'
                            className='form-control'
                            placeholder={t('common.phone')}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            }

            {
              /* STEP */ isStep('payment') && (
                <div className='row'>
                  <div className='col-12 col-sm-6 col-md-12 col-lg-6 mb-5'>
                    <div className='form-group row'>
                      <div className='col-6' style={{paddingRight: '8px'}}>
                        <input
                          errkey='client.firstname'
                          name='firstname'
                          value={order.client.firstname}
                          onChange={order.onChange('client')}
                          type='text'
                          className='form-control'
                          placeholder={t('common.firstname')}
                        />
                      </div>
                      <div className='col-6' style={{paddingLeft: '8px'}}>
                        <input
                          errkey='client.lastname'
                          name='lastname'
                          value={order.client.lastname}
                          onChange={order.onChange('client')}
                          type='text'
                          className='form-control'
                          placeholder={t('common.lastname')}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <div className='col-12'>
                        <input
                          errkey='client.email'
                          name='email'
                          value={order.client.email}
                          onChange={order.onChange('client')}
                          type='email'
                          className='form-control'
                          placeholder={t('common.email')}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <div className='col-12'>
                        <input
                          name='phone'
                          value={order.client.phone}
                          onChange={order.onChange('client')}
                          type='text'
                          className='form-control'
                          placeholder={t('common.phone')}
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='form-control stripe-control mb-2'>
                        <StripeInput onReady={setStripeCard} stripe={stripe} locale={lang} />
                      </div>
                      <div className='stripe-error d-none alert alert-danger-light'></div>
                      <div className='err-message d-none alert alert-danger-light'>
                        future error message
                      </div>
                    </div>
                    <div className='text-center'>
                      <img
                        src='https://res.cloudinary.com/inmemori/image/upload/v1591786960/web-assets/misc/cards.png'
                        height='38px'></img>
                      <img src='https://res.cloudinary.com/inmemori/image/upload/v1591786760/web-assets/misc/powered_by_stripe.svg'></img>
                    </div>
                  </div>
                  <div className='col-12 col-sm-6 col-md-12 col-lg-6'>
                    <Cart order={order} readOnlyOptions />
                  </div>
                </div>
              )
            }

            {
              /* STEP */ isStep('success') && (
                <div className='flux-success form text-center'>
                  <img src='https://res.cloudinary.com/inmemori/image/upload/v1589456091/web-assets/Livre.svg' />
                  <div className='col-12 col-md-8 col-lg-6 mx-auto mt-4 '>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='text-light'>{t(`order.payment.amount`)}</div>
                      <div>{order.getTotalPrice()}</div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='text-light'>{t(`order.payment.ref`)}</div>
                      <small>{order.tx.ch_id || 'ch_xxxxxxxxxxxxxxxxxxxxxxxx'}</small>
                    </div>
                  </div>
                  <Btn onClick={() => reset()} className='btn btn-primary mt-5'>
                    {flux.t('cta')}
                  </Btn>
                </div>
              )
            }
          </React.Fragment>
        </div>
        {/* END CARD-BODY */}

        {/* CARD-FOOTER */}
        <div className='card-footer d-flex justify-content-end'>
          {!isStep('success,intro,cart') && (
            <button
              onClick={() => setStep('prev')}
              className='d-none d-md-block m-0 btn btn-outline-primary mr-2'>
              {t('common.back')}
            </button>
          )}

          {isStep('config') && (
            <BtnSpin
              pristine={isConfigPristine}
              onClick={() => popupValidation()}
              className='btn btn-primary m-0 '>
              {flux.t('cta')}
            </BtnSpin>
          )}

          {isStep('shipping') && (
            <BtnSpin
              isLoading={isLoading}
              onClick={() => submitShipping()}
              className='btn btn-primary m-0 '>
              {flux.t('cta')}
            </BtnSpin>
          )}
          {isStep('payment') && (
            <BtnSpin
              isLoading={isLoading}
              onClick={() => submit()}
              className='btn btn-primary m-0 '>
              {flux.t('cta', {total: order.getTotalPrice()})}
            </BtnSpin>
          )}
        </div>
      </div>

      <style jsx>
        {`
          .item-pic {
            max-height: 100px;
          }
          .item-pics {
            max-height: 100px;
            margin: 5px 0;
          }

          .flux-success > div {
            border-top: 1px solid #dadada;
          }
          .flux-success > div > div {
            padding-top: 20px;
          }

          .meta-info {
            margin-top: -20px;
            margin-bottom: 80px;
          }

          .feedback-message {
            font-size: 12px;
          }

          .desc {
            font-size: 14px;
          }

          .tech-label {
            font-size: 10px;
            text-transform: uppercase;
            color: #000;
          }

          .bullets {
            list-style: none;
          }

          .bullets li {
            margin-bottom: 10px;
          }
          .bullets li::before {
            color: #4c8371;
            content: '•';
            font-size: 20px;
            float: left;
            margin-top: -3px;
            margin-left: -1em;
          }
          .bullets .no-bullet::before {
            display: none;
          }

          @media (min-width: 992px) {
            // lg up
            .left-col,
            .right-col {
              width: 50%;
            }
          }
        `}
      </style>
    </Flux>
  )
}

export default withGlobal(Block)
