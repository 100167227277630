import HelperThank from './helpers/thank'
import IFrame from './iframe'
import Confirm from './confirm'

const Panels = []

Panels['helper-thank'] = HelperThank
Panels['iframe'] = IFrame
Panels['confirm'] = Confirm

export default Panels
