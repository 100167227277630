import React from 'react'
import {withGlobal} from '../../shared/app'

const Block = ({flow, t, page, hasRole, Products, uiref = {}}) => {
  const onClick = () => {
    page.donation.state == 0
      ? flow.set('ft-donation-settings', {uiref})
      : flow.set('ft-donation', {uiref})
  }

  if (!checkDonationTile(page, hasRole, Products)) return null
  return (
    <div>
      <div onClick={onClick} className='actions py-3 mb-3 cursor tile-donation text-center'>
        <img width='100px' src={t(`tiles.donation.icon`)} />
        <h4 className='mt-3 mb-0'>{t(`tiles.donation.title`)}</h4>
      </div>

      <style jsx>
        {`
          .actions {
            border: 1px solid #dadada;
            border-radius: 8px;
          }
          .actions:hover {
            background: #eff2f2;
          }
        `}
      </style>
    </div>
  )
}

export const checkDonationTile = (page, hasRole, Products) => {
  return Products.Donation.isActive()
}

export default withGlobal(Block)
