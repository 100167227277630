import React from 'react'
import {withGlobal} from '../../shared/app'
import {withAuth} from '../../shared/auth'
import NavBar from '../../widgets/nav-bar'
import CardTop from '../../widgets/card-top'
import EditBroadcast from '../../widgets/broadcast-edit'

const Block = ({t, flow}) => {
  return (
    <div className='card'>
      <NavBar onClick={() => flow.set('prev')}>{t(`broadcast.h1`)}</NavBar>

      <div className='card-body'>
        <CardTop title={t('broadcast.h1')} />

        <EditBroadcast />
      </div>
    </div>
  )
}

export default withGlobal(withAuth(Block))
