import React, {useState, useEffect} from 'react'
import {withGlobal} from '../../shared/app'
import Spinner from '../../misc/spinner'
import {Btn} from '../../misc/buttons'

const Block = ({api, t, page}) => {
  const [ready, setReady] = useState(false)

  const toggleUnsubscribe = async (trueOnly = false) => {
    try {
      const data = await api.get(`/pages/${page.slug}/meta/stopAssistance?trueOnly=${trueOnly}`)
      if (!data.err) {
        setReady(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      toggleUnsubscribe(true)
    }, 2000)
  }, [])

  return (
    <div>
      {ready ? (
        <div className='card'>
          <div className='card-body text-center mb-5 pb-5 text-center' style={{minHeight: '200px'}}>
            <h1 className='mt-4'>{t(`stop-assistance.title`) || 'Loading'}</h1>
            <p>{t(`stop-assistance.subtitle`)}</p>
            <Btn
              className='btn btn-primary mt-3'
              onClick={() => {
                toggleUnsubscribe()
                window.location.replace(`/${page.slug}`)
              }}
              value={t('stop-assistance.cta')}
            />
          </div>
        </div>
      ) : (
        <div className='card'>
          <div className='card-body text-center mb-5 pb-5 text-center' style={{minHeight: '200px'}}>
            <Spinner />
            <h3 className='mt-4'>{t(`common.loading`) || 'Loading'}</h3>
          </div>
        </div>
      )}
    </div>
  )
}

export default withGlobal(Block)
