import React, {useState, useEffect} from 'react'
import {withGlobal} from '../../shared/app'
import {withAuth} from '../../shared/auth'
import {BtnSpin} from '../../misc/buttons'
import NavBar from '../../widgets/nav-bar'
import CardTop from '../../widgets/card-top'

import {setErrors} from '../../../lib/utils'

import moment from 'moment'
import {ProfilePictureUpload} from '../../../lib/fileupload'
import DateInput from '../../misc/date-input'

const Block = ({page, setPage, t, lang, flow, api, toaster, _bnr}) => {
  const banner = _bnr.getCurrent('has-photo')
  const [pristine, setPristine] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [pond, setPond] = useState()
  const { defunct } = page

  const [form, setForm] = useState({
    image: defunct.image,
    firstname: defunct.firstname,
    lastname: defunct.lastname,
    dob: defunct.dob || '',
    dod: defunct.dod || '',
    blaze: defunct.blaze || '',
  })

  const defaultImage = form.image ? [{source: form.image, options: {type: 'local'}}] : []
  const [needImageProcessing, setNeedImageProcessing] = useState(false)

  const [image, setImage] = useState(defaultImage)

  const onFormChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value})
    setPristine(false)
  }

  const onDateChange = (field) => (date) => {
    if (!date) {
      setForm({...form, [field]: ''})
      return
    }

    date.startOf('day')
    setForm({...form, [field]: date.toDate()})
    setPristine(false)
  }

  const save = async (rawForm) => {
    setLoading(true)
    try {
      if (needImageProcessing && pond.getFiles().length) {
        const image = await pond.processFile()
        form.image = image.serverId.hash
      }
      const body = rawForm || form
      const update = await api.put(`/pages/${page.slug}`, {body})
      const errs = setErrors(update)
      if (errs) throw errs

      setPage(update)
      if (needImageProcessing && banner) _bnr.clearBanner(banner.id)

      flow.set('tab-infos')

      toaster.success()
    } catch (e) {
      console.log('err.save', e)
      toaster.error()
    }
    setLoading(false)
  }

  useEffect(() => {
    banner && $('.input-file').trigger('click')
  }, [])

  const setProcessedImage = (error, file) => {
    if (!error) setForm({...form, image: file.serverId.hash})
    setPristine(false)
  }

  const setImages = (files) => {
    if (files.length == 0) {
      setImage([])
      setForm({...form, image: null})
      setPristine(false)
    } else {
      setImage(files)
    }
  }

  return (
    <div className='card'>
      <NavBar onClick={() => flow.set('prev')}>{t(`edit-profile.title`)}</NavBar>

      <div className='card-body'>
        <CardTop title={t('edit-profile.title')} subtitle={t('edit-profile.subtitle')} />

        <div className='d-flex flex-column'>
          <div className='form-group filepond--profile'>
            <ProfilePictureUpload
              files={image}
              oninit={setPond}
              onprocessfile={setProcessedImage}
              onupdatefiles={setImages}
              onprocessfiles={() => {
                setLoading(false)
              }}
              onprocessfilestart={() => {
                setLoading(true)
              }}
              onaddfile={() => {
                setNeedImageProcessing(true)
                setPristine(false)
              }}
            />
          </div>

          <div className='row'>
            <div className='form-group d-flex flex-column col-12 col-lg-6'>
              <input
                type='text'
                name='firstname'
                value={form.firstname}
                onChange={onFormChange}
                className='form-control'
                placeholder={t('edit-profile.ph.firstname')}
              />
            </div>
            <div className='form-group d-flex flex-column col-12 col-lg-6'>
              <input
                type='text'
                name='lastname'
                value={form.lastname}
                onChange={onFormChange}
                className='form-control'
                placeholder={t('edit-profile.ph.lastname')}
              />
            </div>
            {defunct.blaze && <div className='form-group d-flex flex-column col-12'>
              <input
                type='text'
                name='blaze'
                value={form.blaze}
                onChange={onFormChange}
                className='form-control'
                placeholder={t('edit-profile.ph.blaze')}
              />
            </div>}
            <div className='form-group d-flex flex-column col-12 col-lg-6'>
              <DateInput
                value={form.dob ? moment(form.dob).tz(page.zoneInfo.tz) : null}
                onChange={onDateChange('dob')}
                locale={lang}
                minDate={moment().add(-130, 'years')}
                disableFuture
                placeholderText={t('edit-profile.ph.dob')}
              />
            </div>
            <div className='form-group d-flex flex-column col-12 col-lg-6'>
              <DateInput
                value={form.dod ? moment(form.dod).tz(page.zoneInfo.tz) : null}
                onChange={onDateChange('dod')}
                locale={lang}
                minDate={moment().add(-130, 'years')}
                disableFuture
                placeholderText={t('edit-profile.ph.dod')}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='card-footer text-right'>
        <button
          onClick={() => flow.set('prev')}
          className='d-none d-md-inline m-0 btn btn-outline-primary mr-3'>
          {t('common.back')}
        </button>
        <BtnSpin
          pristine={pristine}
          isLoading={isLoading}
          onClick={() => save()}
          className='m-0 btn btn-primary'>
          {t('common.save')}
        </BtnSpin>
      </div>

      <style jsx>
        {`
          .form-label {
            display: none;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(withAuth(Block))
