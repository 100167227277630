import React, {useState, useEffect} from 'react'
import {withGlobal} from '../shared/app'
import SearchInput from '../misc/search-input'
import {DebugBlock} from '../misc/debug'
import {Btn} from '../misc/buttons'
import {FlowStep} from '../../lib/constants'

const List = ({
  isManager,
  onChange,
  _profile,
  t,
  env,
  flow,
  api,
  page,
  toaster,
  hasRole,
  className = ''
}) => {
  const [search, setSearch] = useState({
    data: [],
    pager: {count: 0}
  })

  const [filteredList, setFilteredList] = useState(search.data)

  const loadProfiles = async () => {
    try {
      const body = {slug: page.slug, isManager}
      const search = await api.get(`/profiles/list`, {body})
      if (search.err) throw search.err
      setSearch(search)
      setFilteredList(search.data)
      onChange && onChange(search)
    } catch (e) {
      toaster.error(e)
    }
  }

  const deleteManager = async (profile) => {
    if (!isManager || !confirm('Are you sure ?')) return
    await api.delete(`/pages/${page.slug}/managers/${profile._id}`)
    loadProfiles()
  }

  const profileSpec = (profile) => {
    const {status, isManager} = profile.tribe.meta
    const self = profile._id === _profile._id

    let spec = {icon: '', text: '', self: !!self}
    if (status == 'pending')
      spec = {
        ...spec,
        icon: 'fa fa-hourglass-half',
        text: t(`invitations.status.${status}`),
        badge: 'light'
      }
    if (status == 'active')
      spec = {
        ...spec,
        icon: 'fa fa-check',
        text: t(`invitations.status.${status}${isManager ? '-manager' : ''}`),
        badge: 'primary'
      }
    if (status == 'revoked')
      spec = {
        ...spec,
        icon: 'fa fa-times',
        text: t(`invitations.status.${status}`),
        badge: 'danger'
      }

    return spec
  }

  useEffect(() => {
    loadProfiles()
  }, [])

  return (
    <div className={className}>
      {search.data.length > 5 && (
        <SearchInput
          className=''
          placeholder={t('invitations.ph.search')}
          data={search.data}
          indexes={['name', 'email']}
          onChange={setFilteredList}
        />
      )}

      <ul className='list-group list-group-flush'>
        {filteredList.map((profile, i) => {
          const spec = profileSpec(profile)
          return (
            <li key={i} className='list-group-item'>
              <div className='d-flex align-items-center justify-content-end'>
                <span className='info-block d-flex align-items-center flex-grow-1 overflow-hidden mr-2'>
                  <div className='d-inline-flex flex-column text-truncate'>
                    <span className='d-inline-block text-truncate'>{profile.name}</span>
                    <span className='d-inline-block text-truncate'>
                      {profile.email || profile.phone}
                    </span>
                  </div>
                </span>

                {spec.self && (
                  <span className='badge no-select no-shadow mr-md-4 bg-primary-light'>
                    <span className='fa fa-user mr-md-1' />
                    <span className='d-none d-md-inline'>{t('invitations.self')}</span>
                  </span>
                )}

                <span className={`badge badge-${spec.badge} no-select no-shadow`}>
                  <span className='d-none d-md-inline'>{spec.text}</span>
                  <span className={`${spec.icon} ml-md-2`} />
                </span>
              </div>

              {env.debug && profile._swap && (
                <DebugBlock title='swap link'>{profile._swap.link}</DebugBlock>
              )}

              {isManager && hasRole('team') && (
                <React.Fragment>
                  <Btn
                    className='btn btn-sm btn-link text-lighter'
                    onClick={() =>
                      flow.set('profile', {
                        defaultProfile: profile,
                        prevFlow: FlowStep.invitations
                      })
                    }
                    value={`[${t('common.edit')}]`}
                  />
                  <Btn
                    className='btn btn-sm btn-link text-lighter'
                    onClick={() => deleteManager(profile)}
                    value={`[${t('common.delete')}]`}
                  />
                </React.Fragment>
              )}
            </li>
          )
        })}
      </ul>

      <style jsx>
        {`
          // .list-group {
          //   max-height: 300px;
          //   overflow: scroll;
          // }
        `}
      </style>
    </div>
  )
}

export default withGlobal(List)
