import React, {useEffect, useState} from 'react'
import {withGlobal} from '../../shared/app'
import {withQuery} from '../../../lib/utils'
import Spinner from '../../misc/spinner'
import {Btn} from '../../misc/buttons'

const Block = ({page, query, api, Auth, t, utms}) => {
  const {key} = query
  const {slug} = page
  const redirect = slug ? `/${slug}` : `/`

  const [error, setError] = useState(false)

  const tryConfirm = async () => {
    const result = await api.put(`/profiles/confirm`, {body: {jwt: key, slug}})

    const {jwt, err} = result

    if (jwt) {
      let redirect = `/${slug}/profile`
      redirect = withQuery(redirect, utms) // add utms

      await Auth.login(jwt, {redirect})
    } else if (err) {
      setError(err === 'jwt expired' ? 'expired' : 'other')
    } else {
      Auth().logout({redirect})
    }
  }

  useEffect(() => {
    ;(async () => {
      tryConfirm()
    })()
  }, [])

  if (!error)
    return (
      <div className='card'>
        <div className='card-body text-center mb-5 pb-5 text-center' style={{minHeight: '200px'}}>
          <Spinner />
          <h3 className='mt-4'>{t(`common.loading`) || 'Loading'}</h3>
        </div>
      </div>
    )
  else
    return (
      <div className='card'>
        <div className='card-body text-center mb-5 pb-5 text-center' style={{minHeight: '200px'}}>
          <h1 className='mt-4'>{t(`confirm.${error}.title`) || 'Loading'}</h1>
          <p>{t(`confirm.${error}.subtitle`)}</p>
          <Btn
            className='btn btn-primary mt-3'
            onClick={() => Auth().logout({redirect})}
            value={t(`confirm.${error}.cta`)}
          />
        </div>
      </div>
    )
}

export default withGlobal(Block)
