import React, {useState, useEffect, useCallback, useMemo} from 'react'
import {withGlobal} from '../../shared/app'
import {setErrors, urlQuery, failValidation} from '../../../lib/utils'
import {BtnSpin} from '../../misc/buttons'
import NavBar from '../../widgets/nav-bar'
import CardTop, {CardTopKeepSubtitle} from '../../widgets/card-top'
import {MemoryFileUpload} from '../../../lib/fileupload'
import DynamicFeatures from '../../../lib/dynamicFeatures'
import AddressForm from '../../misc/address-form'
import {has} from 'object-path'

const defaultForm = (memory, _profile) => ({
  _id: memory._id || undefined,
  name: memory.name || _profile.name || '',
  email: _profile.email || '',
  message: memory.message || ''
})

const Block = ({
  memory = {},
  maxFiles = 10,
  _profile,
  setProfile,
  _op,
  hasRole,
  env,
  _bnr,
  t,
  page,
  flow,
  toaster,
  api,
  currentStep = memory.type || 1,
  rubric,
  features,
  Products,
  Memories
}) => {
  const [editMode, setEditMode] = useState(!!memory._id)
  const [form, setForm] = useState(defaultForm(memory, _profile, _op))
  const [images, setImages] = useState([
    ...(memory.images
      ? memory.images.map((image) => {
          return {source: image.hash, options: {type: 'local'}}
        })
      : [])
  ])
  const [pendingImages, setPendingImages] = useState([])
  const [processedImages, setProcessedImages] = useState([
    ...(memory.images
      ? memory.images.map((image) => {
          return {serverId: {...image}}
        })
      : [])
  ])
  const [step, setStep] = useState(currentStep)
  const [isLoading, setLoading] = useState(false)
  const [newMemory, setNewMemory] = useState({})
  const [caption, setCaption] = useState(
    (memory.images && memory.images.length && memory.images[0].caption) || ''
  )
  const [address, setAddress] = useState(_profile.location || {})

  const onFormChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value})
  }

  const submit = useCallback(async () => {
    if (failValidation('name', t)) return
    setLoading(true)
    try {
      const imageIds = images
        .map((image) => {
          const processedImage = processedImages.find(
            (tmpImage) =>
              image.id == tmpImage.id ||
              (image.serverId && tmpImage.serverId && image.serverId.hash == tmpImage.serverId.hash)
          )
          if (processedImage) return {...processedImage.serverId, ...(caption && {caption})}
        })
        .filter((id) => !!id)

      const body = {...form, images: imageIds, type: step, slug: page.slug}
      console.log(body)
      const update = await api.post(`/memories`, {body})
      const err = setErrors(update)
      if (err) throw err

      // sync page update
      setNewMemory(update)

      if (!hasRole('manager') && !_profile.location) setStep(-1)
      else setStep(0)

      // clear banner
      _bnr.clearBanner('has-published')
    } catch (e) {
      if (e.err) toaster.error(e.err)
      else toaster.error()
      console.log('err.submit', e)
    }
    setLoading(false)
  }, [processedImages, images, form, step, caption])

  const submitEdit = useCallback(async () => {
    if (failValidation('name', t)) return
    setLoading(true)

    try {
      const imageIds = images
        .map((image) => {
          const processedImage = processedImages.find(
            (tmpImage) =>
              image.id == tmpImage.id ||
              (image.serverId &&
                typeof image.serverId === 'object' &&
                image.serverId.hash == tmpImage.serverId.hash) ||
              (image.serverId &&
                typeof image.serverId === 'string' &&
                image.serverId == tmpImage.serverId.hash)
          )
          if (processedImage) return {...processedImage.serverId, ...(caption && {caption})}
        })
        .filter((id) => !!id)

      const body = {...form, images: imageIds}
      console.log(body)
      const update = await api.put(`/memories/${form._id}`, {body})
      const err = setErrors(update)
      if (err) throw err

      toaster.success()
      // sync page update
      setNewMemory(update)
      setStep(0)
    } catch (e) {
      if (e.err) toaster.error(e.err)
      else toaster.error()
      console.log('err.submitEdit', e)
    }
    setLoading(false)
  }, [processedImages, images, form, step, caption])

  const saveAddress = async () => {
    setLoading(true)

    try {
      const update = await api.put(`/profiles/${_profile._id}`, {
        body: {locationData: address}
      })
      const errs = setErrors(update)
      if (errs) throw errs

      setProfile(update.profile)
      toaster.success()
      setStep(0)
    } catch (e) {
      console.log('err.save', e)

      toaster.error()
    }
    setLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      const id = urlQuery('_id') // pull _id from url directly with urlQuery fn, we don't use query._id, because it persist even thought the url can change
      if (!memory._id && id) {
        memory = await Memories.get(id)
        const ownMemory = memory && memory.profile && _profile._id == memory.profile
        const canEdit = memory && (hasRole('team') || ownMemory)
        if (canEdit) {
          setEditMode(true)
          setStep(memory.type)
          setImages([
            ...(memory.images
              ? memory.images.map((image) => {
                  return {source: image.hash, options: {type: 'local'}}
                })
              : [])
          ])
          setProcessedImages([
            ...(memory.images
              ? memory.images.map((image) => {
                  return {serverId: image.hash}
                })
              : [])
          ])
          setForm(defaultForm(memory, _profile, _op))
          setCaption((images[0] && memory.images[0].caption) || '')
        } else {
          setTimeout(() => {
            // flow.set('tab-memories')
          }, 0)
        }
      }
    })()
  }, [])

  const addPendingImage = (file) => {
    setPendingImages([...pendingImages, file])
  }

  const removePendingImage = (error, file) => {
    if (error) return

    setProcessedImages([...processedImages, file])

    const index = pendingImages.findIndex((f) => f.id === file.id)

    let newImages = [...pendingImages]

    if (index != -1) newImages.splice(index, 1)

    setPendingImages(newImages)
  }

  const syncLoadedFile = (error, file) => {
    if (error) return

    const index = images.findIndex((image) => image.id === file.id)
    if (index != -1) {
      images[index] = file
      setImages(images)
    }
  }

  const uploading = useMemo(() => {
    return pendingImages.length > 0
  }, [pendingImages])

  return (
    <div className='card'>
      {false && env.debug && JSON.stringify(form)}

      <NavBar showPrev={!!step} onClick={() => flow.set('prev', {currentStep: rubric})}>
        {step >= 0 && t(`new-memory.menu.${step}.title`)}
        {step == -1 && t(`new-memory.address.title`)}
      </NavBar>

      {step > 0 && (
        <React.Fragment>
          <div className='card-body errors-scope'>
            <CardTop
              title={t(`new-memory.menu.${step}.title`)}
              subtitle={t(`new-memory.menu.${step}.subtitle`, page)}
            />

            <div className='form-group'>
              <input
                errkey='name'
                type='text'
                name='name'
                maxLength='50'
                value={form.name}
                onChange={onFormChange}
                className='form-control'
                placeholder={t('new-memory.ph.name')}
              />
              <div className='err-alert alert alert-danger-light p-1 my-2 d-none'>
                name err zone
              </div>
            </div>

            <div className={`d-flex flex-column`}>
              {step != 3 && (
                <div className='form-group'>
                  <textarea
                    errkey='message-text'
                    name='message'
                    value={form.message}
                    onChange={onFormChange}
                    className='form-control'
                    rows='4'
                    placeholder={t(`new-memory.ph.message${step == 3 ? '-opt' : ''}`)}></textarea>
                </div>
              )}

              <div className='form-group filepond--new-memory'>
                <MemoryFileUpload
                  files={images}
                  onupdatefiles={setImages}
                  maxFiles={maxFiles}
                  onprocessfile={removePendingImage}
                  onprocessfilestart={addPendingImage}
                  onaddfile={syncLoadedFile}
                />
              </div>

              {!!images.length && (
                <div className='form-group'>
                  <input
                    type='text'
                    errkey='caption'
                    name='caption'
                    value={caption}
                    maxLength='120'
                    onChange={(e) => setCaption(e.target.value)}
                    className='form-control'
                    placeholder={t(`new-memory.ph.caption`)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className='card-footer text-right'>
            <button
              onClick={() => flow.set('prev', {currentStep: rubric})}
              className='d-none d-md-inline m-0 btn btn-outline-primary mr-3'>
              {t('common.back')}
            </button>
            {!editMode && (
              <BtnSpin
                isLoading={isLoading || uploading}
                onClick={() => submit()}
                className='m-0 btn btn-primary'>
                {t('common.publish')}
              </BtnSpin>
            )}
            {editMode && (
              <BtnSpin
                isLoading={isLoading || uploading}
                onClick={() => submitEdit()}
                className='m-0 btn btn-primary'>
                {t('common.edit')}
              </BtnSpin>
            )}
          </div>
        </React.Fragment>
      )}

      {step == -1 && (
        <div className='card-body errors-scope'>
          <CardTopKeepSubtitle
            title={t(`new-memory.address.title`)}
            subtitle={
              hasRole('manager')
                ? t(`new-memory.address.subtitle.manager`, page)
                : t(`new-memory.address.subtitle.user`, page)
            }
          />
          <div>
            <div className='mx-auto mb-3'>
              <AddressForm className='mt-2' form={address} onChange={setAddress} />
            </div>
            <div className='text-right'>
              <BtnSpin
                isLoading={isLoading}
                onClick={() => saveAddress()}
                className='m-0 btn btn-primary'>
                {t('new-memory.address.cta')}
              </BtnSpin>
            </div>
          </div>
        </div>
      )}
      {step == 0 && (
        <div className='card-body errors-scope'>
          <CardTopKeepSubtitle
            title={t(`new-memory.thank.${newMemory.category}.title`)}
            subtitle={t(`new-memory.thank.${newMemory.category}.subtitle`, page)}
          />
          <div className='success-container'>
            <div className='col-9 col-md-5 text-center mx-auto mb-3'>
              {hasRole('manager') &&
                DynamicFeatures({features: features, flow, page, hasRole, Products}).getTile(
                  'share',
                  0,
                  'memory-success'
                )}
              {!hasRole('manager') &&
                DynamicFeatures({features: features, flow, page, hasRole, Products}).getTile(
                  'flowers',
                  0,
                  'memory-success'
                )}
            </div>
            <button
              onClick={() => flow.set('tab-memories', {currentStep: rubric})}
              className='btn btn-border mt-3 mt-md-4 mx-auto mx-md-none'>
              {t(`new-memory.thank.${newMemory.category}.cta`)}
            </button>
          </div>
        </div>
      )}

      <style jsx>{`

      .cell {
        position: relative;
        border-radius: 8px;
        width: min-content;
      }

      .remove-img {
        position: absolute;
        background: #fff;
        padding: 6px;
        right: 0;
        border-top-right-radius: 3px;
      }

      .uploaded-img {
        max-height: 110px;
        max-width: 130px;
      }

      .actions {
        border: 1px solid #dadada;
        border-radius: 8px;
      }
      
      .actions:hover {
        background: #eff2f2;
      }

      // GRID

      .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr); grid-gap: 20px;
      }

      .success-container {
        text-align: right;
      }

      // md down
      @media (max-width: 767px) {
        .success-container {
          text-align: center;
        }
      }

      @media (min-width: 992px) { // lg up
        .grid { grid-template-columns: repeat(4, 1fr); grid-gap: 10px; }
        .grid-fluid { column-count: 1; -webkit-column-count: 1;
      }
      // @media (max-width: 400px) { // md down
      //   .grid { grid-template-columns: repeat(4, 1fr); grid-gap: 10px; }
      //   .grid-fluid { column-count: 1; -webkit-column-count: 1;
      // }

      .filepond--item {
        width: calc(50% - .5em);
      }
    `}</style>
    </div>
  )
}

export default withGlobal(Block)
