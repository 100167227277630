import React, {useState, useMemo} from 'react'
import {withGlobal} from '../../shared/app'
import {Btn, BtnSpin} from '../../misc/buttons'
import ProfilesList from '../../widgets/profiles-list'
import CardTop, {CardTopKeepSubtitle} from '../../widgets/card-top'
import NavBar from '../../widgets/nav-bar'
import NoAccess from '../../widgets/no-access'
import * as ToastsTemplates from '../../shared/toasts'
import InputPhone, {getPhone} from '../../misc/input-phone'
import {SingleEmailInput} from '../../misc/email-input'

const Block = ({
  hasRole,
  flow,
  t,
  query,
  countries,
  currentStep = 0,
  defaultProfile,
  defaultCountry,
  toaster,
  Page
}) => {
  if (!hasRole('manager,broker')) return <NoAccess />

  const [isLoading, setLoading] = useState(false)
  const [step, setStep] = useState(parseInt(query.step) || currentStep)

  const profile = defaultProfile || {}

  const defaultForm = {
    name: profile.name || '',
    phone: getPhone(profile.phone, defaultCountry),
    email: profile.email || ''
  }

  const [form, setForm] = useState(defaultForm)

  const onFormChange = (e) => {
    const newForm = {...form, [e.target.name]: e.target.value}
    setForm(newForm)
  }

  const onPhoneChange = (phone) => {
    const newForm = {...form, phone}
    setForm(newForm)
  }

  const submit = async () => {
    const invitations = [{...form, phone: form.phone.clean}]

    setLoading(true)

    try {
      const {err, wins, fails} = await Page.sendInvitations(invitations, {isManager: true})
      if (err) throw err

      if (wins[0]) {
        hasRole('broker') ? flow.set('prev') : setStep(0)
        setForm(defaultForm)
        toaster.create({
          element: ToastsTemplates.ToastDefault({
            toaster,
            ...toaster.props,
            label: t('snackbar.default.sent')
          })
        })()
      } else {
        throw fails
      }
    } catch (e) {
      console.log('submit err', e)
      toaster.create({
        element: ToastsTemplates.ToastDefault({
          toaster,
          ...toaster.props,
          label: t('snackbar.errors.sent')
        }),
        className: 'rounded bg-error'
      })()
    }
    setLoading(false)
  }

  const emailValid = useMemo(() => {
    if (form.email && form.email.length) {
      if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          form.email
        )
      )
        return true
      else return false
    } else return undefined
  }, [form])

  const pristine = useMemo(() => {
    if (emailValid === true) return false

    if (form.phone && form.phone.clean && form.phone.clean.length) return false

    return true
  }, [form])

  // current text namesapce
  const ns = (step) => (step == 0 ? 'managers-list' : 'managers-add')

  return (
    <div className='card'>
      <NavBar onClick={() => flow.set('prev')}>{t(`${ns(step)}.title`)}</NavBar>

      <div className='card-body'>
        <CardTopKeepSubtitle title={t(`${ns(step)}.title`)} subtitle={t(`${ns(step)}.subtitle`)} />

        {step == 0 && (
          <React.Fragment>
            <Btn
              className='btn btn-link text-primary mt-3'
              onClick={() => setStep(step + 1)}
              value={t('managers-list.cta.add')}
              icon='https://res.cloudinary.com/inmemori/image/upload/v1591093454/web-assets/picto/add-email.svg'
            />
            <ProfilesList isManager />
          </React.Fragment>
        )}

        {step == 1 && (
          <div className='form'>
            <div className='form-group'>
              {/* <label className='form-label mb-3 text-light'>{t('managers-add.label.name')}</label> */}
              <input
                type='text'
                name='name'
                value={form.name}
                onChange={onFormChange}
                className='form-control'
                placeholder={t('managers-add.ph.name')}
              />
            </div>

            <div className='form-group mt-4'>
              <label className='form-label text-light'>{t('managers-add.label.contact')}</label>
            </div>

            <SingleEmailInput
              errkey='email'
              name='email'
              value={form.email}
              onChange={(email) => setForm({...form, email})}
              placeholder={t('managers-add.ph.email')}
            />

            <InputPhone
              status={form.phone.raw ? (form.phone.clean ? true : false) : ''}
              defaultPhone={form.phone}
              onChange={onPhoneChange}
              countries={countries}
              className={`form-group ${form.phone.raw ? (form.phone.clean ? '' : 'invalid') : ''}`}
              defaultCountry={defaultCountry}
              placeholder={t(`managers-add.ph.phone`)}
            />
          </div>
        )}
      </div>

      <div className='card-footer text-right'>
        {step == 0 && (
          <button
            onClick={() => flow.set('prev')}
            className='d-none d-md-inline m-0 btn btn-outline-primary'>
            {t('common.back')}
          </button>
        )}
        {step == 1 && (
          <button
            onClick={() => (hasRole('broker') ? flow.set('prev') : setStep(0))}
            className='d-none d-md-inline m-0 btn btn-outline-primary mr-3'>
            {t('common.back')}
          </button>
        )}
        {step == 1 && (
          <BtnSpin
            pristine={pristine}
            isLoading={isLoading}
            onClick={() => submit()}
            className='m-0 btn btn-primary'>
            {t('managers-add.cta.add')}
          </BtnSpin>
        )}
      </div>
    </div>
  )
}

export default withGlobal(Block)
