import React, {useState, useEffect, useRef, useMemo} from 'react'
import CreatableSelect from 'react-select/creatable'
import _ from 'lodash'

const EmailInput = ({
  onChange = () => {},
  placeholder = 'Emails',
  formatCreateLabel,
  emails = []
}) => {
  const el = useRef(null)
  const [list, setList] = useState(emails)
  const [failList, setFails] = useState([]) //["dijkd.ddd@dddd.com", "dijkd.dddsf@ddd.com", "dijkd.ddd@ddd.gcom"])
  const [inputValue, setInputValue] = useState('')

  const parseInput = (input = '') => {
    // email regex
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const wins = []
    const fails = []

    let inputs = input
      // sanatise
      .trim()
      .toLowerCase()
      .replace(/[><]/g, '.')
      .replace(/\s@/g, '@')
      .replace(/@\s/g, '@')
      .replace(/\s\./g, '.')
      .replace(/\.\s/g, '.')
      // split emails (,; or space)
      .replace(/[,,;,\s]/g, '|')
      .split('|')
    // filter valid emails

    inputs = _(inputs).compact().uniq().value()

    inputs.map((i) => {
      if (i && re.test(i)) wins.push(i)
      else fails.push(i)
    })

    return {wins, fails}
  }

  const onInputChange = (v = '', meta) => {
    if (meta.action == 'input-blur' && inputValue) {
      onSelectChange([{label: inputValue, value: inputValue, __isNew__: true}], {
        action: 'create-option'
      })
      setInputValue('')
      return ''
    }

    // we detect copy pasting by diffing lentgh of new&prev value, if diff > 3 we consider it's pasted
    const isPasted = ((v && Math.abs(v.length - inputValue.length)) || 0) > 3

    if (isPasted) {
      onSelectChange([{label: v, value: v, __isNew__: true}], {action: 'create-option'})
      return ''
    } else {
      const lastChar = v[v.length - 1]

      // on one of those chars, submit

      const filterChars = [',', ';']

      if (window.Bowser.is('desktop')) filterChars.push(' ')

      if (filterChars.includes(lastChar)) {
        v = v.slice(0, -1)
        onSelectChange([{label: v, value: v, __isNew__: true}], {action: 'create-option'})
        setInputValue('')
        return ''
      }

      setInputValue(v)
      return v
    }
  }

  // const onKeyDown = (e) => {
  //   const select = el.current.select.select
  //   const v = 'louis @sddd.fr, dddd@dddd.dd'
  //   if(e.keyCode==86 && (e.ctrlKey || e.metaKey)){
  //     select.onChange([{label: v, value: v, __isNew__: true}], { action: 'create-option' })
  //     // select.clearValue()
  //     console.log('copypaste')
  //   }
  // }

  const onSelectChange = (data, meta) => {
    try {
      const remove = () => {
        const removed = meta.removedValue.label
        const items = _.reduce(
          list,
          (acc, i) => {
            if (i != removed) acc.push(i)
            return acc
          },
          []
        )
        return items
      }

      let newList
      let newFails = []

      if (meta.action == 'clear') newList = []
      if (meta.action == 'remove-value') newList = remove()
      if (meta.action == 'pop-value') newList = remove()
      if (meta.action == 'create-option') {
        const value = data[data.length - 1].value
        const {wins, fails} = parseInput(value)
        newFails = fails
        newList = [...list, ...wins]
      }

      if (newList) {
        newList = _.uniq(newList)
        setList(newList)
        setFails(newFails)
        onChange(newList)
      }
    } catch (e) {
      console.log('oups', e)
    }
  }

  useEffect(() => {
    setList(emails)
    onChange(emails)
  }, [emails])

  return (
    <React.Fragment>
      <CreatableSelect
        ref={el}
        isClearable
        isMulti
        // onKeyDown={onKeyDown}
        onInputChange={onInputChange}
        onChange={onSelectChange}
        menuIsOpen={!!inputValue}
        components={{DropdownIndicator: null}}
        placeholder={placeholder}
        formatCreateLabel={formatCreateLabel}
        value={list.map((item) => ({value: item, label: item}))}
      />

      {!!failList.length && (
        <div className='fails d-flex flex-wrap justify-items-center mt-2'>
          {failList.map((f, i) => (
            <span key={i} className='badge bg-danger-light mb-1 mr-1'>
              {f}
            </span>
          ))}
        </div>
      )}

      <style jsx global>
        {`
          .fails {
            max-height: 58px;
            overflow: scroll;
          }
        `}
      </style>
    </React.Fragment>
  )
}

export const SingleEmailInput = ({
  value = '',
  name = 'phone',
  onChange,
  disabled,
  placeholder = 'Phone',
  className = '',
  isInvalid = false
}) => {
  const onInputChange = (e) => {
    const raw = e.target.value
    onChange && onChange(raw)
  }

  const status = useMemo(() => {
    if (isInvalid) return true

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (value.length) {
      if (re.test(value)) return true
      return false
    }
    return undefined
  }, [value, isInvalid])

  const red = '#FF8F60'
  const green = '#7BB8A5'

  return (
    <div className={`form-group d-flex position-relative ${className}`}>
      <input
        type='email'
        name={name}
        value={value}
        disabled={disabled}
        onChange={onInputChange}
        className={`form-control ${status === false ? 'is-invalid' : ''}`}
        placeholder={placeholder}
      />

      {status === false && <i className='status-ko fa fa-times-circle' />}
      {status === true && <i className='status-ok fa fa-check-circle' />}

      <style jsx>
        {`
          .input-group.invalid button,
          .input-group.invalid input {
            border-color: ${red} !important;
          }
          .input-group.invalid input:focus {
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          }
          .input-group.valid button,
          .input-group.valid input {
            border-color: ${green} !important;
          }

          .status-ok,
          .status-ko {
            position: absolute;
            right: 10px;
            top: calc(50% - 8px);
            z-index: 10;
          }
          .status-ok {
            color: ${green};
          }
          .status-ko {
            color: ${red};
          }
        `}
      </style>
    </div>
  )
}

export default EmailInput
