import {useState, useEffect, useRef} from 'react'
import Banner from '../../misc/banner'

import CardIntro from '../cards/card-intro'
import CardLocations from '../cards/card-locations'
import CardFtDonation from '../cards/card-ft-donation'

const Blocks = {
  'card-intro': CardIntro,
  'card-places': CardLocations,
  'card-ft-donation': CardFtDonation
}

const Banners = ({banner, flow, withOverlay = true, toaster, page, tempValues}) => {
  if (!banner) return null

  const el = useRef()
  const [overlay, setOverlay_] = useState(withOverlay)
  const ContextBlock = Blocks[banner.block]
  let helperTimer = null

  const setOverlay = (v) => {
    withOverlay && setOverlay_(v)
  }

  const ctaWrapper = (version = 'a') => {
    setOverlay(true)
    hideHelper()

    // exec cta
    const ctaFn = banner[`onCt${version}`]
    ctaFn && ctaFn({banner, flow})
  }

  const outsideClick = (e) => {
    if (el.current && el.current.contains(e.target)) return // inside click
    // outside click
    setOverlay(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', outsideClick)
    return () => document.removeEventListener('mousedown', outsideClick)
  }, [])

  useEffect(() => {
    helperTimer = setTimeout(showHelper, 10000)
    return () => {
      hideHelper()
    }
  }, [banner])

  const showHelper = () => {
    if (!banner.helper) return
    if (
      !tempValues.snackBarInviteManagerCta &&
      tempValues.snackBarInviteManagerCount &&
      tempValues.snackBarInviteManagerCount >= 2
    )
      return

    if (helperTimer) helperTimer = null

    toaster.inviteManager(banner.id, page)
  }

  const hideHelper = () => {
    if (helperTimer) {
      clearTimeout(helperTimer)
      helperTimer = null
    }
    toaster.removeAll()
  }

  return (
    <div ref={el}>
      {banner && (
        <div className={`banners ${overlay && 'overlay'}`}>
          <div className='card-body'>
            <Banner {...banner} onCta={() => ctaWrapper('a')} onCtb={() => ctaWrapper('b')} />

            <div className='d-none banner-analytics'>
              <span className='banner-key'>{banner.id}</span>
            </div>

            {/* {banner.block && (
              <div className={`${overlay ? 'context-block-overlay' : 'context-block'} mt-3`}>
                <ContextBlock />
              </div>
            )} */}
          </div>
        </div>
      )}

      <style jsx>
        {`
          .context-block {
            // border: 3px solid #06205f;
          }

          .banners {
            position: relative;
          }
          .banners.overlay::before {
            pointer-events: none;
            box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.85);
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            display: block;
            z-index: 10;
            border-radius: 10px;
          }

          .banner {
            border: 1px solid #32735f;
            border-radius: 8px;
          }
          .banner .action {
            // text-transform: uppercase;
            margin: 0 10px;
          }
        `}
      </style>
    </div>
  )
}

export default Banners
