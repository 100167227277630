import React, {useCallback, useEffect, useState} from 'react'
import {withGlobal} from '../../shared/app'
import {withAuth} from '../../shared/auth'
import {Btn} from '../../misc/buttons'
import {urlQuery} from '../../../lib/utils'
import NavBar from '../../widgets/nav-bar'
import CardTop from '../../widgets/card-top'
import Spinner from '../../misc/spinner'

import {FlowStep} from '../../../lib/constants'

const Block = ({page, _profile, setProfile, t, flow, api, authed, toaster, query}) => {
  const [options, setOptions] = useState(undefined)
  const [isReady, setReady] = useState(false)
  const [profileId, setProfileId] = useState(_profile && _profile._id)
  const [autoToggle, setAutoToggle] = useState(false)
  const [autoToggleDone, setAutoToggleDone] = useState(false)

  const loadOptions = async (id) => {
    try {
      const update = await api.get(`/profiles/${id}/unsubs`)
      setOptions(update)
      setReady(true)
    } catch (e) {
      toaster.error()
      window.location.replace(page.slug ? `/${page.slug}` : `/`)
    }
  }

  const toggleOption = async (option, value = undefined) => {
    const savedOptions = {...options}
    if (option === 'all') {
      Object.keys(options).forEach((o) => {
        options[o] = value ? value : !options[option]
      })
    } else {
      options[option] = value ? value : !options[option]
    }

    setOptions({...options})

    try {
      const update = await api.post(`/profiles/${profileId}/unsubs/${option}/${options[option]}`)
      const newProfile = {..._profile, unsubs: update}
      setProfile(newProfile)
    } catch (e) {
      toaster.error()
      setOptions(savedOptions)
    }
  }

  useEffect(() => {
    const id = query.profileId
    const defaultToggle = query.option

    if (!authed && !id) {
      window.location.replace(page.slug ? `/${page.slug}` : `/`)
    }

    ;(async () => {
      if (id) {
        setProfileId(id)
        await loadOptions(id)
      } else {
        setOptions(_profile.unsubs)
        setReady(true)
      }

      if (defaultToggle) {
        setAutoToggle(defaultToggle)
      }
    })()
  }, [])

  useEffect(() => {
    if (autoToggle && options && !autoToggleDone) {
      toggleOption(autoToggle, true)
      setAutoToggleDone(true)
    }
  }, [autoToggle, options, profileId, autoToggleDone])

  return (
    <div className='card'>
      <NavBar onClick={() => flow.set(FlowStep.prev)}>{t(`unsubscribe.title`)}</NavBar>

      <div className='card-body d-flex flex-column col errors-scope'>
        <CardTop title={t('unsubscribe.title')} subtitle={t('unsubscribe.subtitle')} />

        {!isReady && <Spinner />}
        {isReady && (
          <div>
            {autoToggle && (
              <div className='alert bg-primary-light text-left w-100 px-3 py-2 my-2 my-md-3 mx-0'>
                <h4 className='mb-1 f1 bold'>{t('unsubscribe.auto.title')}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('unsubscribe.auto.text', {
                      optionName: t(`unsubscribe.options.${autoToggle}.title`)
                    })
                  }}
                />
              </div>
            )}

            <div className='mt-2'>
              <ul className='list-group list-group-flush'>
                {Object.keys(options).map((option, index) => {
                  const checked = !options[option]
                  return (
                    <li
                      key={index}
                      className='list-group-item d-flex align-items-center justify-content-between cursor'>
                      <label className='switch w-100 d-flex align-items-center justify-content-between cursor'>
                        <div className='d-flex flex-column mr-2'>
                          <h4 className='f1'>{t(`unsubscribe.options.${option}.title`)}</h4>
                          <div>{t(`unsubscribe.options.${option}.text`)}</div>
                        </div>
                        <input
                          checked={checked}
                          type='checkbox'
                          onChange={() => toggleOption(option)}
                        />
                        <span className='switcher my-3'></span>
                      </label>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )}
      </div>

      {!!page.slug && (
        <div className='card-footer text-right'>
          <Btn
            onClick={() => (authed ? flow.set(FlowStep.prev) : flow.set('onboarding'))}
            className='btn btn-outline-primary mr-3'
            value={t('common.back')}
          />
        </div>
      )}

      <style jsx>
        {`
          li {
            min-height: 58px;
          }
          li span {
            font-size: 16px;
          }
          li button {
            margin: 0;
          }

          .list-group-item:last-child {
            border-bottom: none !important;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Block)
