export const H1 = ({children}) => (
  <h1>
    <span>{children}</span>
    <style jsx>
      {`
        h1 {
          position: relative;
        }

        h1:before {
          border-top: 1px solid #dadada;
          content: '';
          margin: 0 auto;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          z-index: -1;
        }

        span {
          background: #fff;
          padding-right: 20px;
        }
      `}
    </style>
  </h1>
)
