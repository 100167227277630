import React, {useState, useEffect, useMemo} from 'react'
import {withGlobal} from '../../shared/app'
import {StripeInput, PayToEndpoint} from '../../../lib/stripe'
import {setErrors, failValidation} from '../../../lib/utils'
import CardTop from '../../widgets/card-top'
import NavBar from '../../widgets/nav-bar'
import Cart from '../../widgets/cart'
import {Btn, BtnSpin} from '../../misc/buttons'
import {Flux, FluxBar, useFlux} from '../../misc/flux'
import {useOrder} from '../../store/order'
import {loadStripe} from '@stripe/stripe-js'

const Block = ({
  page,
  flow,
  t,
  lang,
  toaster,
  Page,
  api,
  query,
  env,
  _profile,
  tracker,
  uiref,
  Products
}) => {
  const [isLoading, setLoading] = useState(false)
  const [stripe, setStripe] = useState(null)
  const [stripeCard, setStripeCard] = useState(null)
  const [withInvoice, setWithInvoice] = useState(false)

  const {currency} = page

  const order = useOrder(
    {
      client: {email: _profile.email},
      meta: {uiref},
      item: {qty: 1}
    },
    {
      feature: 'donation',
      currency,
      env
    }
  )

  const flux = useFlux({
    steps: t(`ft-donation.steps`, true) || [],
    defaultStep: () => query.step,
    onCheckpoint: tracker.funnelOrder('donation', false, order),
    onChange: () => {
      document.documentElement.scrollTop = 0
    },
    hasPrev: ({step}) => !['intro', 'success'].includes(step.key),
    onPrevMiss: () => flow.set('prev'),
    t: ({step}) => (key, subs, ref) =>
      t(`ft-donation.steps.${(ref || step || {}).index}.${key}`, subs)
  })

  const {step, setStep, isStep, getSteps} = flux

  // amount logic
  const isTipActive = (price_int) => (order.item.price_int == price_int ? 'active' : '')
  const isCustomActive = () => (tips.includes(order.item.price_int) ? '' : 'active')
  const setAmount = (price_int) => order.setItem({price_int, amount: price_int * 100})
  const clickCustom = () => {
    $('#custom-amount').focus()
    order.setItem({price: '', price_int: 0, amount: 0})
  }

  const submit = async () => {
    if (failValidation('client.firstname,client.lastname,client.email', t)) return
    setLoading(true)
    try {
      const payload = order.vanilla()

      let update = await api.post(`/pages/${page.slug}/donation`, {
        body: {...payload, validate: true}
      })
      if (!update.success) {
        // exit flow if update fails
        setErrors(update)
        return setLoading(false)
      }

      // pass auto generated order_id
      payload.order_id = update.order_id

      const paymentParams = [
        stripe,
        stripeCard,
        `${process.env.API_URI}/pages/${page.slug}/payment`,
        {billing_details: {name: `${order.client.firstname} ${order.client.lastname}`}},
        payload
      ]

      // try payment
      const payment = await PayToEndpoint(...paymentParams)

      // payment error
      if (!(payment && payment.status == 'succeeded')) {
        console.log('payment.error', payment)
        toaster.error(t('common.errors.missing.payment'))
        return setLoading(false)
      }

      // assign tx info to payload
      payload.tx = payment

      // save order
      let donation = await api.post(`/pages/${page.slug}/donation`, {body: payload})
      let errs = setErrors(donation)
      if (errs) throw errs

      order.setTx(donation.tx)

      Page.reloadPage()
      setStep('next')
    } catch (e) {
      console.log('err.submit', e)
    }
    setLoading(false)
  }

  const submitIntro = () => {
    if (
      withInvoice &&
      failValidation('invoice.name,invoice.address,invoice.zipcode,invoice.city,invoice.email', t)
    )
      return

    if (withInvoice) order.setClient({email: order.invoice.email, phone: order.invoice.phone})

    if (!order.item.price_int) order.setItem({price_int: tips[1]})
    setStep('next')
  }

  const reset = () => {
    order.reset()
    flow.set('tab-infos')
  }

  const tips = useMemo(() => {
    return (Products.Donation.product.ctx && Products.Donation.product.ctx.tips) || []
  }, [Products.Donation.product])

  useEffect(() => {
    ;(async () => {
      // init stripe
      setStripe(await loadStripe(process.env.STRIPE_KEY))
    })()
  }, [])

  useEffect(() => {
    if (tips.length) order.setItem({price_int: tips[1]})
  }, [tips])

  if (!step) return <div className='py-5 text-center'>{`step "${query.step}"does not exist`}</div>

  return (
    <Flux flux={flux} debug={env.debug}>
      <div className='card'>
        {false && JSON.stringify(order.vanilla())}

        <NavBar onClick={() => setStep('prev')}>{step.title}</NavBar>

        <div className='card-body errors-scope'>
          {!isStep('success') && (
            <FluxBar steps={getSteps('success', true)} current={step} flux={flux} />
          )}

          <CardTop title={flux.t('title')} subtitle={flux.t('subtitle')} />

          {
            /* STEP */ isStep('intro') && (
              <div>
                <div className='amount col-12 mx-auto mb-5'>
                  <div className='tips d-flex flex-wrap justify-content-center'>
                    {tips.map((tip, i) => {
                      return (
                        <div
                          key={i}
                          className={`tip ${isTipActive(
                            tip
                          )} f2 d-flex justify-content-center align-items-center cursor`}
                          onClick={() => setAmount(tip)}>
                          {tip.money(currency.ticker, 0)}
                        </div>
                      )
                    })}
                  </div>
                  <div className='tips d-flex flex-wrap justify-content-center'>
                    <div
                      className={`tip ${isCustomActive()} f2 d-flex justify-content-center align-items-center cursor`}
                      onClick={(e) => clickCustom(e)}>
                      {t('ft-donation.labels.custom')}
                    </div>
                    <div
                      className={`tip custom-amount ${isCustomActive()} d-flex justify-content-center align-items-center`}>
                      <input
                        id='custom-amount'
                        name='price_int'
                        value={order.item.price_int}
                        onChange={order.onChange('item')}
                        type='number'
                        min='1'
                        className='form-control'
                        placeholder={t('ft-donation.ph.custom', {
                          currency: `${currency.symbol}${currency.value.toUpperCase()}`
                        })}
                      />
                    </div>
                  </div>
                </div>

                {page.donation.beneficiary != 'family' && (
                  <div className='text-center'>
                    <div className='form-group d-inline-block'>
                      <label className='switch switch-smal w-100 d-flex align-items-center justify-content-start cursor m-0'>
                        <span className='mr-2'>{t('order.invoice.withFiscal')}</span>
                        <input
                          type='checkbox'
                          name='privacy'
                          checked={withInvoice}
                          onChange={() => setWithInvoice(!withInvoice)}
                        />
                        <span className='switcher'></span>
                      </label>
                    </div>
                  </div>
                )}

                {withInvoice && (
                  <div>
                    <div className='form-group'>
                      <input
                        errkey='invoice.name'
                        name='name'
                        value={order.invoice.name}
                        onChange={order.onChange('invoice')}
                        type='text'
                        className='form-control'
                        placeholder={t('common.name')}
                      />
                    </div>
                    <div className='form-group'>
                      <input
                        name='vat'
                        value={order.invoice.vat}
                        onChange={order.onChange('invoice')}
                        type='text'
                        className='form-control'
                        placeholder={t('common.vat')}
                      />
                    </div>
                    <div className='form-group'>
                      <input
                        errkey='invoice.address'
                        name='address'
                        value={order.invoice.address}
                        onChange={order.onChange('invoice')}
                        type='text'
                        className='form-control'
                        placeholder={t('common.address')}
                      />
                    </div>
                    <div className='form-group row'>
                      <div className='col-6' style={{paddingRight: '8px'}}>
                        <input
                          errkey='invoice.zipcode'
                          name='zipcode'
                          value={order.invoice.zipcode}
                          onChange={order.onChange('invoice')}
                          type='text'
                          className='form-control'
                          placeholder={t('common.zipcode')}
                        />
                      </div>
                      <div className='col-6' style={{paddingLeft: '8px'}}>
                        <input
                          errkey='invoice.city'
                          name='city'
                          value={order.invoice.city}
                          onChange={order.onChange('invoice')}
                          type='text'
                          className='form-control'
                          placeholder={t('common.city')}
                        />
                      </div>
                    </div>
                    <div className='form-group row'>
                      <div className='col-6' style={{paddingRight: '8px'}}>
                        <input
                          errkey='invoice.email'
                          name='email'
                          value={order.invoice.email}
                          onChange={order.onChange('invoice')}
                          type='email'
                          className='form-control'
                          placeholder={t('common.email')}
                        />
                      </div>
                      <div className='col-6' style={{paddingLeft: '8px'}}>
                        <input
                          errkey='invoice.phone'
                          name='phone'
                          value={order.invoice.phone}
                          onChange={order.onChange('invoice')}
                          type='text'
                          className='form-control'
                          placeholder={t('common.phone')}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          }

          {
            /* STEP */ isStep('payment') && (
              <div className='row'>
                <div className='col-12 col-sm-6 col-md-12 col-lg-6 mb-5'>
                  <div className='form-group row'>
                    <div className='col-6' style={{paddingRight: '8px'}}>
                      <input
                        errkey='client.firstname'
                        name='firstname'
                        value={order.client.firstname}
                        onChange={order.onChange('client')}
                        type='text'
                        className='form-control'
                        placeholder={t('common.firstname')}
                      />
                    </div>
                    <div className='col-6' style={{paddingLeft: '8px'}}>
                      <input
                        errkey='client.lastname'
                        name='lastname'
                        value={order.client.lastname}
                        onChange={order.onChange('client')}
                        type='text'
                        className='form-control'
                        placeholder={t('common.lastname')}
                      />
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-12'>
                      <input
                        errkey='client.email'
                        name='email'
                        value={order.client.email}
                        onChange={order.onChange('client')}
                        type='email'
                        className='form-control'
                        placeholder={t('common.email')}
                      />
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-12'>
                      <input
                        name='phone'
                        value={order.client.phone}
                        onChange={order.onChange('client')}
                        type='text'
                        className='form-control'
                        placeholder={t('common.phone')}
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <div className='form-control stripe-control mb-2'>
                      <StripeInput onReady={setStripeCard} stripe={stripe} locale={lang} />
                    </div>
                    <div className='stripe-error d-none alert alert-danger-light'></div>
                    <div className='err-message d-none alert alert-danger-light'>
                      future error message
                    </div>
                  </div>
                  <div className='text-center'>
                    <img
                      src='https://res.cloudinary.com/inmemori/image/upload/v1591786960/web-assets/misc/cards.png'
                      height='38px'></img>
                    <img src='https://res.cloudinary.com/inmemori/image/upload/v1591786760/web-assets/misc/powered_by_stripe.svg'></img>
                  </div>
                </div>
                <div className='col-12 col-sm-6 col-md-12 col-lg-6'>
                  <Cart order={order} />
                </div>
              </div>
            )
          }

          {
            /* STEP */ isStep('success') && (
              <div className='flux-success form text-center'>
                <img src='https://res.cloudinary.com/inmemori/image/upload/v1589456088/web-assets/Don.svg' />
                <div className='col-12 col-md-8 col-lg-6 mx-auto mt-4 '>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='text-light'>{t(`order.payment.amount`)}</div>
                    <div>{order.getTotalPrice()}</div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='text-light'>{t(`order.payment.ref`)}</div>
                    <small>{order.tx.ch_id || 'ch_xxxxxxxxxxxxxxxxxxxxxxxx'}</small>
                  </div>
                </div>
                <Btn onClick={() => reset()} className='btn btn-primary mt-5'>
                  {flux.t('cta')}
                </Btn>
              </div>
            )
          }
        </div>
        {/* END CARD-BODY */}

        {/* CARD-FOOTER */}
        <div className='card-footer d-flex justify-content-end'>
          {!isStep('success') && (
            <button
              onClick={() => setStep('prev')}
              className='d-none d-md-block m-0 btn btn-outline-primary mr-2'>
              {t('common.back')}
            </button>
          )}
          {isStep('intro') && (
            <BtnSpin
              isLoading={isLoading}
              onClick={() => submitIntro()}
              className='btn btn-primary m-0 '>
              {flux.t('cta')}
            </BtnSpin>
          )}
          {isStep('payment') && (
            <BtnSpin
              isLoading={isLoading}
              onClick={() => submit()}
              className='btn btn-primary m-0 '>
              {flux.t('cta', {total: order.getTotalPrice()})}
            </BtnSpin>
          )}
        </div>
      </div>

      <style jsx>
        {`
          .item-pic {
            max-height: 100px;
          }
          .item-pics {
            max-height: 100px;
            margin: 5px 0;
          }

          .flux-success > div {
            border-top: 1px solid #dadada;
          }
          .flux-success > div > div {
            padding-top: 20px;
          }

          .tip {
            border: 1px solid #9e9e9e;
            // padding: 20px 30px;
            border-radius: 8px;
            margin: 5px;
            height: 60px;
            width: 100px;
            text-align: center;
          }
          .tip.active {
            border: 1px solid #32735f;
            background: #eff2f2;
            color: #32735f;
          }
          .custom-amount {
            width: 116px;
            margin-left: -12px;
            background: #fff !important;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
          .custom-amount.active {
            border-color: #33745f;
          }
          .custom-amount input {
            border: none !important;
          }
        `}
      </style>
    </Flux>
  )
}

export default withGlobal(Block)
