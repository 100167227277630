const Banner = ({className = '', text, icon, cta, onCta = () => {}, ctb, onCtb = () => {}}) => {
  return (
    <div
      className={`banner no-select bg-white p-2 d-flex flex-column flex-lg-row justify-content-between align-items-center ${className}`}>
      <div className='d-flex banner-content align-items-center'>
        {icon && <img className='mx-3' src={icon} />}
        <span className='mr-3'>{text}</span>
      </div>

      <div
        className='d-flex justify-content-center justify-content-md-end mt-3 mt-lg-0'
        style={{minWidth: '220px'}}>
        {ctb && (
          <button onClick={() => onCtb()} className='action banner-ctb btn btn-link text-primary'>
            {ctb}
          </button>
        )}
        {cta && (
          <button onClick={() => onCta()} className='action banner-cta btn btn-primary'>
            {cta}
          </button>
        )}
      </div>

      <style jsx>
        {`
          .banner {
            border: 1px solid #32735f;
            border-radius: 8px;
          }
          .banner .action {
            margin-right: 10px;
          }
          .banner .action:last-child {
            margin-right: 0px;
          }
        `}
      </style>
    </div>
  )
}

export default Banner
