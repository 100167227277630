import React from 'react'
import {withGlobal} from '../shared/app'

const Block = ({title = 'unauthorized access'}) => {
  return (
    <div className='card'>
      <div className='card-body text-center mb-5 pb-5 text-center' style={{minHeight: '200px'}}>
        <h3 className='mt-4'>{title}</h3>
      </div>
    </div>
  )
}

export default withGlobal(Block)
