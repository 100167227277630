import {confirmAlert} from 'react-confirm-alert'
import ImageGallery from '../components/misc/image-gallery'

const Popup = {}

Popup.areYouSure = (data = {}, yesHandler = () => {}) => {
  const {title, message, btns = []} = data

  confirmAlert({
    title, //'Confirm to submit',
    message, //'Are you sure to do this.',
    buttons: [
      {
        label: btns[0] || 'Yes',
        onClick: yesHandler
      },
      {
        label: btns[1] || 'No',
        onClick: () => {}
      }
    ]
  })
}

Popup.letterValidation = (data, yesHandler = () => {}) => {
  const {title, message, btns = [], config, Products, t} = data

  confirmAlert({
    customUI: ({onClose}) => (
      <div className='container mx-auto text-center' style={{maxWidth: '400px'}}>
        <h1>{title}</h1>
        <div className='my-4 '>
          <ImageGallery
            className=''
            images={Products.Letter.slides(config, t)}
            optimize={false}
            shadow
            zoomMargin={150}
          />
        </div>
        <p className="py-4">{message}</p>
        <div className='d-flex justify-content-around mt-2'>
          <button onClick={() => onClose()} className='btn btn-sm btn-outline-primary'>
            {btns[1]}
          </button>
          <button
            onClick={() => {
              onClose()
              yesHandler()
            }}
            className='btn btn-sm btn-primary'>
            {btns[0]}
          </button>
        </div>
      </div>
    )
  })
}

export default Popup
