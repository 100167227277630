import React from 'react'

// transform string containing link with actual html tag
export const linkify = (str, {substr = 50, target = '_blank'} = {}) => {
  // str = "hello www.inddd.com?dhhf=ee  kjhd  http://xxx.xkdd.dd?foo=bar"
  let text = str

  //URLs starting with http://, https://, or ftp://
  const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
  //URLs starting with www. (without // before it, or it'd re-link the ones done above)
  const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim
  //Change email addresses to mailto:: links
  const replacePattern3 = /(([a-zA-Z0-9_\-.]+)@[a-zA-Z_]+?(?:\.[a-zA-Z]{2,6}))+/gim

  let linkName = (s) => s
  if (substr)
    linkName = (s) => {
      const isCropped = s.length > substr
      s = s.substr(0, substr)
      return isCropped ? `${s}...` : s
    }

  text = text.replace(
    replacePattern1,
    (x) => `<a href="${x}" target="${target}">${linkName(x)}</a>`
  )
  text = text.replace(
    replacePattern2,
    (x) => `<a href="http://${x.trim()}" target="${target}">${linkName(x)}</a>`
  )
  text = text.replace(
    replacePattern3,
    (x) => `<a href="${x}" target="${target}">${linkName(x)}</a>`
  )
  return text
}

const LinkifyText = ({className = '', text = '', substr, target}) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: linkify(text, {substr, target})
      }}
    />
  )
}

export default LinkifyText
