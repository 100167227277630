import {withGlobal} from '../../shared/app'

const Block = ({page, t}) => {
  return (
    <div className='my-3 no-select'>
      <div className='d-flex flex-wrap justify-content-around py-md-2'>
        <div className='cell my-2 d-flex flex-column text-center'>
          <img src='https://res.cloudinary.com/inmemori/image/upload/v1589456094/web-assets/View.svg' />
          <span className='text d-flex flex-column'>
            <h1>{page.stats.views.users}</h1>
            <span>{t('card-stats.labels.views')}</span>
          </span>
        </div>
        <div className='cell my-2 d-flex flex-column text-center'>
          <img src='https://res.cloudinary.com/inmemori/image/upload/v1589456091/web-assets/Messages.svg' />
          <span className='text d-flex flex-column'>
            <h1>{page.stats.memories.count}</h1>
            <span>{t('card-stats.labels.memories')}</span>
          </span>
        </div>
        {/* page.donation.state != 0 && <div className="cell my-2 d-flex flex-column text-center">
        <i className="far fa-heart text-primary" />
        <span className="text d-flex flex-column">
          <h1>{page.stats.donations.count}</h1>
          <span>{t('card-stats.labels.donations')}</span>
        </span>
      </div>}
      { page.donation.state != 0 && <div className="cell my-2 d-flex flex-column text-center">
        <i className="far fa-money-bill-alt text-primary" />
        <span className="text d-flex flex-column">
          <h1>{page.stats.donations.total_f}</h1>
          <span>{t('card-stats.labels.collected')}</span>
        </span>
      </div>*/}
      </div>

      <style jsx>
        {`
          i {
            font-size: 24px;
            margin-bottom: 10px;
          }

          .cell {
            margin: 15px 0;
          }

          .text h1 {
            margin-top: 15px;
            margin-bottom: 4px;
          }
          .text span {
            font-size: 18px !important;
          }

          @media (max-width: 605px) {
            // sm down
            .cell {
              flex-grow: 1;
              flex: 1 1 25%;
            }
            .cell div {
              font-size: 14px;
            }
            .cell i {
              font-size: 20px;
            }
          }

          // // md down
          // @media (max-width: 767px) {
          //   .card {
          //     margin-top: -16px;
          //     border-radius: 0;
          //   }
          //   .card-title {
          //     font-size: 16px;
          //   }
          //   img {
          //     max-height: 70px;
          //     margin: 10px;
          //   }
          // }
          // // md up
          // @media (min-width: 768px) {
          //   img {
          //     width: 100%;
          //     object-fit: cover;
          //     max-height: 250px;
          //     border-top-left-radius: .25rem;
          //     border-top-right-radius: .25rem;
          //   }
          // }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Block)
