import React from 'react'
import {withGlobal} from '../../shared/app'

const Block = ({flow, t, uiref = {}}) => {
  const onClick = () => {
    flow.set(`tab-memories`, {uiref})
  }

  return (
    <div>
      <div onClick={onClick} className='actions py-3 mb-3 cursor tile-memories text-center'>
        <img width='100px' src={t(`tiles.memories.icon`)} />
        <h4 className='mt-3 mb-0'>{t(`tiles.memories.title`)}</h4>
      </div>

      <style jsx>
        {`
          .actions {
            border: 1px solid #dadada;
            border-radius: 8px;
          }
          .actions:hover {
            background: #eff2f2;
          }
        `}
      </style>
    </div>
  )
}

export const checkMemoriesTile = () => {
  return true
}

export default withGlobal(Block)
