import React from 'react'
import {withGlobal} from '../shared/app'
import Linkify from '../misc/linkify-text'
import {handlebars} from '../../lib/utils'
import _ from 'lodash'
import InputQuantities from '../misc/input-quantities'

const optionsOrder = ['upsize', 'ribbon', 'card', 'premiumPaper', 'premiumLetter', 'sticker']

const Cart = ({
  order,
  editQty = false,
  showDesc = false,
  showDetails = true,
  showPrice = true,
  showShippingPrice = true,
  showItemDesc = false,
  showOptions = true,
  readOnlyOptions = false,
  customComponent,
  t,
  lang,
  page
}) => {
  if (!order) return <div>empty cart</div>

  const {item, onChange, feature: ns} = order

  let optionsList = order.getOptionsList()
  if (readOnlyOptions) optionsList = _.filter(order.getOptionsList(true), {selected: true})

  const desc =
    (order.item.desc && order.item.desc[lang]) || (order.item.info && order.item.info[lang])
  const shippingPrice = order.getShippingPrice(false)

  // name can be i18n spaced
  const itemName = typeof item.name == 'object' ? item.name[lang] : item.name
  const itemSubs = {item: {...item, name: itemName}}

  const title = t(`order.ns.${ns}.title`, true) && t(`order.ns.${ns}.title`, itemSubs)
  const subtitle =
    ns === 'flower'
      ? t(`ft-flowers.types.${item.specs.type}`)
      : t(`order.ns.${ns}.subtitle`, true) && t(`order.ns.${ns}.subtitle`, itemSubs)

  return (
    <div className=''>
      <div className='cart-item item'>
        <div className='d-flex justify-content-between align-items-start mb-3'>
          <div>
            <h3>{title}</h3>
            {subtitle && (
              <small className='d-block text-light' style={{marginTop: '-8px'}}>
                {subtitle}
              </small>
            )}
          </div>
          <h3>{showPrice && order.getItemPrice()}</h3>
        </div>

        {showDesc && desc && (
          <div className='desc pre-line text-light py-2'>
            <Linkify text={handlebars(desc, order.item)} />
          </div>
        )}

        {editQty &&
          order.item.metagrid &&
          order.item.metagrid[order.item.format] &&
          Object.keys(order.item.metagrid[order.item.format]).length > 1 && (
            <div className='qty d-flex justify-content-between align-items-center pb-3'>
              <select
                errkey='qty'
                name='qty'
                value={item.qty}
                onChange={(e) => order.setItem({qty: parseInt(e.target.value, 10)})}
                className={`form-control`}>
                <option value='' selected hidden>
                  {t('order.cart.qty', page)}
                </option>
                {Object.keys(order.item.metagrid[order.item.format]).map((qty, i) => {
                  const q = order.item.metagrid[order.item.format][qty]
                  return (
                    <option key={i} value={q.qty}>
                      {t(`order.qty.${ns}.placeholder`, q)}
                    </option>
                  )
                })}
              </select>
            </div>
          )}


        {editQty &&
          (!order.item.metagrid[order.item.format] ||
            Object.keys(order.item.metagrid[order.item.format]).length <= 1) && (
            <div className='qty d-flex justify-content-between align-items-center pb-3'>
              {/* <div>{t(`order.cart.qty`)}</div> */}
              <InputQuantities
                value={item.qty}
                onChange={(value) => order.setItem({qty: parseInt(value, 10)})}
                min={1}
                max={20}
                step={1}
                className='form-control'
                textValue={(value) => t(`order.qty.${ns}.placeholder`, {...order.item, qty: value})}
              />
            </div>
          )}

        {!!customComponent && typeof customComponent === 'function' && customComponent()}
        {!!customComponent && typeof customComponent !== 'function' && customComponent}

        {showDetails && (
          <React.Fragment>
            {showItemDesc && (
              <div className='cart-item text-light d-flex justify-content-between align-items-center mt-2'>
                {item.descriptions[lang]}
              </div>
            )}
            {!!(order.hasDiscount() && item.qty > 1 && order.getDiscount(false)) && (
              <div className='cart-item cart-shipping text-light d-flex justify-content-between align-items-center'>
                <div>{t(`order.cart.discount`)}</div>
                <div>{order.getDiscount()}</div>
              </div>
            )}
            {!!(editQty && order.hasDiscount() && item.qty <= 1) && (
              <div className='notice notice-danger p-2'>
                <i className='fas fa-exclamation-circle mr-2' />
                {t(`order.cart.discountAvailable`)}
              </div>
            )}
            {showOptions && !!optionsList.length && (
              <div className='cart-item-options'>
                {optionsOrder.map((optName, i) => {
                  const opt = optionsList.find(
                    (o) =>
                      o.key === optName && (typeof o.active === 'undefined' || o.active === true)
                  )
                  if (!opt) return null

                  const optionKey = opt.key
                  const maxChars = t(`order.options.${optionKey}.max`, true) || ''
                  const message = t(`order.options.${optionKey}.message`, true)
                  const noPrice = t(`order.options.${optionKey}.noprice`, true)
                  const isSelected = order.options[optionKey].selected

                  return (
                    <div
                      key={i}
                      className={`cart-item cart-item-option ${isSelected ? 'selected' : ''}`}>
                      <div
                        onClick={() => !readOnlyOptions && order.toggleOption(opt)}
                        className='d-flex justify-content-between align-items-center cursor'>
                        <div className='d-flex flex-row align-items-center'>
                          {!readOnlyOptions && (
                            <input
                              type='checkbox'
                              checked={order.hasOption(opt)}
                              readOnly
                              className='mr-2'
                            />
                          )}

                          <div>{t(`order.options.${optionKey}.title`)}</div>
                        </div>
                        <div>
                          {(order.getOptionPrice(optionKey, false) &&
                            order.getOptionPrice(optionKey)) ||
                            noPrice}
                        </div>
                      </div>
                      {!readOnlyOptions && message && isSelected && (
                        <div className='mt-2 pt-2 cart-item-option-expend'>
                          <textarea
                            errkey={`options.${optionKey}.message`}
                            name='message'
                            maxLength={maxChars}
                            value={order.options[optionKey].message}
                            onChange={order.onOptionChange(opt)}
                            className='form-control form-control-sm'
                            placeholder={message}
                          />
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            )}
            {showShippingPrice && shippingPrice > 0 && (
              <div className='cart-item cart-shipping text-light d-flex justify-content-between align-items-center'>
                <div>{t(`order.cart.shipping`)}</div>
                <div>{order.getShippingPrice()}</div>
              </div>
            )}
            <div className='cart-item cart-total d-flex justify-content-between align-items-center'>
              <h4>{t(`order.cart.total`)}</h4>
              <h4>
                {showShippingPrice ? order.getTotalPrice() : order.getTotalPriceWithoutShipping()}
              </h4>
            </div>
          </React.Fragment>
        )}
      </div>

      <style jsx>
        {`
          .cart-item {
            padding-bottom: 20px;
          }
          .cart-item-options {
            padding: 0;
            margin-bottom: 20px;
            margin-left: -10px;
            margin-right: -10px;
          }
          .cart-item-option {
            font-size: 14px;
            padding: 10px;
            border-bottom: 1px solid #eee;
          }

          .cart-item-option:last-child {
            border-bottom: 0px;
          }

          .cart-item-option:hover,
          .cart-item-option.selected {
            background: #fbfbfb;
          }
          .cart-item-option-expend {
            border-top: 1px dashed #eee;
          }

          .item {
            margin-bottom: 20px;
            ${(false && showDetails && 'border-bottom: 1px solid #dadada;') || ''}
          }
          .cart-shipping {
            border-bottom: 1px solid #dadada;
          }
          .cart-total {
            padding-top: 20px;
          }
          .desc {
            margin-top: 10px;
            font-size: 14px;
          }
          .discount {
            color: #32735f !important;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Cart)
