import React from 'react'
import {withGlobal} from '../../../shared/app'
import {Btn} from '../../../misc/buttons'

const ThankHelper = ({t, panel}) => {
  const close = () => {
    panel.hide()
  }

  return (
    <div className='modal-content panel-container p-5 p-sm-3 align-items-center'>
      <h1 className='mt-1 mb-3'>Remerciements</h1>
      <div className=' mx-2'>Remerciez les proches de John Doe pour leurs hommages</div>
      <img
        src='https://res.cloudinary.com/inmemori/image/upload/v1607423173/web-assets/helper-thank.png'
        className='img-fluid mt-3'
      />
      <Btn onClick={close} value={t(`common.ok`)} />

      <style jsx>
        {`
          .panel-container {
            width: 400px !important;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(ThankHelper)
