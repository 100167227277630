import React from 'react'
import {withGlobal} from '../../shared/app'

const Confirm = ({t, panel, type = 'email', confirms}) => {
  return (
    <div className='modal-content panel-container align-items-center p-4'>
      <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center'>
        {t(`profile.confirm.icon`, true) && (
          <img
            className='d-block mt-4 mb-3 mx-auto'
            width='150px;'
            src={t(`profile.confirm.icon`)}
          />
        )}
        <h3 className='d-block mt-4 mb-3 d-none d-md-block'>{t(`profile.confirm.title`)}</h3>
        <p
          className='text-light m-auto pb-4 pre-line text-center'
          dangerouslySetInnerHTML={{__html: t(`profile.confirm.subtitle.${type}`, confirms)}}></p>

        <button
          onClick={() => panel.hide()}
          className='btn btn-border mt-3 mt-md-4 mx-auto mx-md-none'>
          {t(`profile.confirm.cta`)}
        </button>
      </div>
      <style jsx>
        {`
          .panel-container {
            position: relative;
            overflow: hidden;
            width: 600px;
          }

          @media (max-width: 605px) {
            // sm down
            .panel-container {
              width: 360px;
            }
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Confirm)
