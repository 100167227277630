import React, {useEffect, useState} from 'react'
import {withGlobal} from '../../shared/app'
import {withQuery} from '../../../lib/utils'
import Spinner from '../../misc/spinner'
import {Btn} from '../../misc/buttons'

const Block = ({page, query, api, Auth, t, utms}) => {
  const {key} = query
  const {slug} = page
  let redirect = slug ? `/${slug}` : `/`

  const [error, setError] = useState(false)
  const [jwt, setJwt] = useState(false)

  const tryConfirm = async () => {
    const result = await api.put(`/lookup/approve`, {body: {jwt: key, slug}})

    const {jwt, err} = result

    if (jwt) {
      setJwt(jwt)
    } else if (err) {
      setError(err === 'jwt expired' ? 'expired' : 'other')
    } else {
      Auth().logout({redirect})
    }
  }

  const login = async () => {
    redirect = withQuery(redirect, utms) // add utms
    await Auth.login(jwt, {redirect})
  }

  const logout = async () => {
    redirect = withQuery(redirect, utms) // add utms
    await Auth.logout(jwt, {redirect})
  }

  useEffect(() => {
    ;(async () => {
      tryConfirm()
    })()
  }, [])

  if (!error && !jwt)
    return (
      <div className='card'>
        <div className='card-body text-center mb-5 pb-5 text-center' style={{minHeight: '200px'}}>
          <Spinner />
          <h3 className='mt-4'>{t(`common.loading`) || 'Loading'}</h3>
        </div>
      </div>
    )
  else if (jwt)
    return (
      <div className='card'>
        <div className='card-body text-center mb-5 pb-5 text-center' style={{minHeight: '200px'}}>
          <h1 className='mt-4'>{t(`access-confirm.success.title`) || 'Loading'}</h1>
          <p>{t(`access-confirm.success.subtitle`)}</p>
          <Btn
            className='btn btn-primary mt-3'
            onClick={login}
            value={t(`access-confirm.success.cta`)}
          />
        </div>
      </div>
    )
  else
    return (
      <div className='card'>
        <div className='card-body text-center mb-5 pb-5 text-center' style={{minHeight: '200px'}}>
          <h1 className='mt-4'>{t(`access-confirm.${error}.title`) || 'Loading'}</h1>
          <p>{t(`access-confirm.${error}.subtitle`)}</p>
          <Btn
            className='btn btn-primary mt-3'
            onClick={logout}
            value={t(`access-confirm.${error}.cta`)}
          />
        </div>
      </div>
    )
}

export default withGlobal(Block)
