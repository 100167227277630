import React from 'react'

const DynamicFeatures = ({dsl = {}, features, flow, page, hasRole, Products} = {}) => {
  dsl.getTile = (name, index, entity) => {
    const uiref = {page: flow.current, name, entity, type: 'tile', position: index}
    const {Tiles, checkTile} = require('../components/app/tiles/index')

    if (checkTile(name, page, hasRole, Products) && Tiles[name])
      return React.createElement(Tiles[name], {uiref, key: name})
    return null
  }

  dsl.getCard = (name, index, entity) => {
    const uiref = {page: flow.current, name, entity, type: 'card', position: index}
    const {Cards} = require('../components/app/cards/index')

    if (Cards[name]) return React.createElement(Cards[name], {uiref, key: name})
    return null
  }

  dsl.tabInfos = () => {
    if (features && features['tab-infos'])
      return features['tab-infos']
        .map((name, index) => dsl.getCard(name, index, 'tab-infos'))
        .filter(Boolean)
    return []
  }

  dsl.sidebar = () => {
    if (features && features.sidebar && features.sidebar[flow.current])
      return features.sidebar[flow.current]
        .map((name, index) => dsl.getTile(name, index, 'sidebar'))
        .filter(Boolean)
        .slice(0, 2)
    return []
  }

  return dsl
}

export default DynamicFeatures
