import React, {useState} from 'react'
import {withGlobal} from '../../shared/app'
import ProfilesList from '../../widgets/profiles-list'
import CardTop from '../../widgets/card-top'
import NavBar from '../../widgets/nav-bar'
import NoAccess from '../../widgets/no-access'

const Block = ({flow, t, hasRole}) => {
  if (!hasRole('manager,user')) return <NoAccess />

  const [search, setSearch] = useState({})

  return (
    <div className='card'>
      <NavBar onClick={() => flow.set('tab-share')}>{t(`invitation-list.title`)}</NavBar>

      <div className='card-body'>
        <CardTop
          title={t('invitation-list.title')}
          subtitle={t('invitation-list.subtitle', {count: search.pager && search.pager.total})}
        />
        <ProfilesList onChange={setSearch} />
      </div>

      <div className='card-footer text-right'>
        <button
          onClick={() => flow.set('tab-share')}
          className='d-none d-md-inline m-0 btn btn-outline-primary'
        >
          {t('common.back')}
        </button>
      </div>
    </div>
  )
}

export default withGlobal(Block)
