import React, {useState, useEffect} from 'react'
import _ from 'lodash'

const Search = ({
  data = [],
  onChange = () => {},
  indexes = ['key', 'name', 'title'],
  placeholder,
  className = '',
  style
}) => {
  const [value, setValue] = useState('')

  const filter = (data, value) => {
    return data.filter((d) => {
      let match = false
      for (let i = 0; i < indexes.length; i++) {
        let text = _.get(d, indexes[i])
        if (typeof text == 'string' && text.match(new RegExp(`${value}`, 'gi'))) {
          match = true
          break
        }
      }
      return match
    })
  }

  const onValueChange = (value) => {
    const filteredData = value ? filter(data, value) : data
    onChange(filteredData)
    setValue(value)
  }

  useEffect(() => {
    onValueChange(value)
  }, [data])

  return (
    <div className={className} style={style}>
      <input
        type='text'
        name='search'
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
        className='form-control'
        placeholder={placeholder}
        autoComplete='off'
      />
    </div>
  )
}

export default Search
