import React, {useState, useEffect} from 'react'
import {withGlobal} from '../shared/app'
import SearchInput from '../misc/search-input'
import {string} from '../../lib/utils'

const List = ({t, list, className = ''}) => {
  const [filteredList, setFilteredList] = useState(list)

  useEffect(() => {
    setFilteredList(list)
  }, [list])

  return (
    <div className={className}>
      {list.length > 10 && (
        <SearchInput
          className=''
          placeholder={t('invitations.ph.search')}
          data={list}
          indexes={['client.firstname', 'client.email']}
          onChange={setFilteredList}
        />
      )}

      <ul className='list-group list-group-flush'>
        {filteredList.map((donation, i) => {
          const {client, tx, invoice = {}} = donation
          return (
            <li key={i} className='list-group-item'>
              <div className='d-flex align-items-center justify-content-between'>
                <span className='info-block d-flex align-items-center'>
                  {string.name(`${client.firstname} ${client.lastname}`) || client.email}
                </span>
                <div>
                  {invoice.address && (
                    <span className='d-none d-md-inline badge badge-primary mr-3'>
                      Reçu fiscal demandé
                    </span>
                  )}
                  <span className='amount badge badge-light'>{tx.price}</span>
                </div>
              </div>
            </li>
          )
        })}
      </ul>

      <style jsx>
        {`
          .amount {
            min-width: 90px;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(List)
