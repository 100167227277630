import React, {useEffect} from 'react'
import {withGlobal} from '../../shared/app'

import Banners from './banners'
import DynamicFeatures from '../../../lib/dynamicFeatures'

const Block = ({
  flow,
  authed,
  _bnr,
  page,
  _profile,
  hasRole,
  _tribe,
  t,
  api,
  toaster,
  tempValues,
  features,
  Products,
  Memories
}) => {
  // Banners cfg
  const pageTime = new Date(page.cat)
  const launchTime = new Date('2020-07-02T00:00:00.000Z') // real launch date is 01 july 2020 at 2PM paris, but we offset day for showacse to not overlap with legacy created pages.

  let withBanner = authed && pageTime.getTime() > launchTime.getTime() && hasRole('user')

  const startBanner = () =>
    setTimeout(() => {
      withBanner &&
        _bnr.init({
          page,
          profile: _profile,
          tribe: _tribe,
          t,
          api,
          Memories
        })
    }, 2000)

  useEffect(() => {
    startBanner()
  }, [])

  useEffect(() => {
    if (_bnr.inited) _bnr.setNext()
  }, [_bnr.inited])

  return (
    <React.Fragment>
      {withBanner && _bnr.banner && (
        <Banners
          banner={_bnr.banner}
          flow={flow}
          withOverlay={false}
          toaster={toaster}
          t={t}
          page={page}
          tempValues={tempValues}
        />
      )}

      {DynamicFeatures({features: features, flow, page, hasRole, Products}).tabInfos()}
    </React.Fragment>
  )
}

export default withGlobal(Block)
