import React, {useState, useEffect, useMemo} from 'react'
import {withGlobal} from '../../shared/app'
import {Btn, BtnSpin} from '../../misc/buttons'
import Banner from '../../misc/banner'

import EmailInput from '../../misc/email-input'
import {CardTopKeepSubtitle} from '../../widgets/card-top'
import NavBar from '../../widgets/nav-bar'
import {Flux, useFlux} from '../../misc/flux'
import * as ToastsTemplates from '../../shared/toasts'
import {copy2clipboard, withQuery} from '../../../lib/utils'
import CardProfile from '../cards/card-profile'

const Block = ({
  authed,
  page,
  t,
  flow,
  _profile,
  _tribe,
  isManager,
  _bnr,
  toaster,
  api,
  env,
  hasRole,
  trackEvent,
  uiref = {}
}) => {
  const [isLoading, setLoading] = useState(false)
  const [copied, setCopied] = useState(false)
  const [invites, setInvites] = useState(0)

  const [emails, setEmails] = useState([])
  const [canShare, setCanShare] = useState(false)
  const [senderName, setSenderName] = useState(_profile.name || '')
  const [senderEmail, setSenderEmail] = useState(_profile.email || '')
  const [message, setMessage] = useState(t(`tab-share.message`, page))

  const reflink = _tribe.reflink || 'https://link.inmemori.com/fakelink'

  const flux = useFlux({
    steps: t(`tab-share.steps`, true) || [],
    defaultStep: () => 'intro',
    onChange: () => {
      document.documentElement.scrollTop = 0
      setCopied(false)
    },
    hasPrev: ({step}) => !['intro', 'success'].includes(step.key),
    onPrevMiss: () => flow.set('prev'),
    t: ({step}) => (key, subs, ref) =>
      t(`tab-share.steps.${(ref || step || {}).index}.${key}`, subs)
  })

  const {step = {}, setStep, isStep} = flux

  const loadInvites = async () => {
    try {
      const body = {slug: page.slug, isManager: false}
      const search = await api.get(`/profiles/list`, {body})
      if (search.err) throw search.err
      setInvites(search.pager.total || search.data.length)
    } catch (e) {
      return
    }
  }

  const copyLink = () => {
    copy2clipboard(`${reflink}?utm_medium=clipboard`)
    setCopied(true)
    trackEvent(`link:Copied`, {medium: 'clipboard', uiref}, 'profile')
  }

  const shareLink = async () => {
    const shareData = {
      title: t(`tab-share.data.title`, page),
      text: t(`tab-share.data.text`, {...page, link: encodeURI(`${reflink}?utm_medium=native`)}),
      url: encodeURI(`${reflink}?utm_medium=native`)
    }

    try {
      await navigator.share(shareData)
      trackEvent(`link:Copied`, {medium: 'native', uiref}, 'profile')
      toaster.success()
    } catch (err) {
      toaster.error()
    }
  }

  const submitEmail = async () => {
    const sender = {name: senderName, email: senderEmail}
    const invitations = emails.map((identifier) => ({identifier}))
    const body = {sender, message, invitations}

    if (!invitations.length) {
      toaster.create({
        element: ToastsTemplates.ToastDefault({
          toaster,
          ...toaster.props,
          label: t('common.errors.missing.emails')
        }),
        className: 'rounded bg-error'
      })()
      return
    }

    setLoading(true)
    try {
      const {wins, fails} = await api.post(`/pages/${page.slug}/invite`, {body})

      if (wins[0]) {
        setEmails([])

        // clear banner
        _bnr.clearBanner('has-invited')

        setStep('success')
        loadInvites()
      } else {
        throw fails
      }
    } catch (e) {
      console.log('err.submit', e)
      toaster.create({
        element: ToastsTemplates.ToastDefault({
          toaster,
          ...toaster.props,
          label: t('snackbar.errors.sent')
        }),
        className: 'rounded bg-error'
      })()
    }
    setLoading(false)
  }

  const openMailbox = () => {
    const shareData = {
      title: t(`tab-share.data-email.title`, page),
      text: t(`tab-share.data-email.text`, {
        ...page,
        link: encodeURIComponent(`${reflink}?utm_source=mail`)
      })
    }
    // const mailtoUrl = withQuery('mailto:', {subject: shareData.title, body: shareData.text})
    const mailtoUrl = `mailto:?subject=${shareData.title}&body=${shareData.text.replace(
      /(?:\r\n|\r|\n)/g,
      '%0D'
    )}`
    trackEvent(`emailInvit:clicked`, {medium: 'mail', uiref}, 'profile')
    var win = window.open(mailtoUrl, '_blank')
    win.focus()
  }

  const onButtonList = (
    <Btn
      className='btn btn-link text-primary'
      onClick={() => flow.set('invitations')}
      value={`${t('tab-share.cta.list')} (${invites})`}
      icon='https://res.cloudinary.com/inmemori/image/upload/v1607940785/web-assets/picto/list-primary.svg'
    />
  )
  const offButtonList = (
    <Btn
      className='btn btn-link text-lighter'
      onClick={() => {}}
      value={`${t('tab-share.cta.list')} (0)`}
      icon='https://res.cloudinary.com/inmemori/image/upload/v1590589493/web-assets/picto/list.svg'
    />
  )

  useEffect(() => {
    loadInvites()
    setCanShare(!!window.navigator.share)
  }, [])

  if (!authed) return null

  return (
    <Flux flux={flux} debug={env.debug}>
      <div className='card mb-5'>
        {!isStep('intro') && (
          <NavBar
            showPrev={!isStep('intro,success')}
            onClick={() => {
              setStep('intro')
            }}>
            {flux.t(`title`)}
          </NavBar>
        )}

        {isStep('intro') && <CardProfile forceDisplay className='d-block d-md-none' />}

        {isStep('intro') && isManager && (
          <div className='p-3 mb-3'>
            <Banner
              {...t('banners.help-invite-manager', true)}
              onCta={() => flow.set('managers', {currentStep: 1})}
            />
          </div>
        )}

        <React.Fragment>
          <div className='card-body'>
            <CardTopKeepSubtitle
              title={flux.t(`title`)}
              subtitle={step.subtitle && flux.t(`subtitle`, page)}
              icon={isStep('success') && flux.t(`icon`)}
            />

            {isStep('intro') && (
              <div className='text-center mx-auto col-12 col-md-11'>
                <div className='d-none d-md-flex row justify-content-between w-100 mx-auto'>
                  <div
                    onClick={() => openMailbox()}
                    className='actions py-3 mb-3 cursor col-12 col-md-5 action-mailbox'>
                    <img
                      className='action-mailbox'
                      width='100px'
                      src='https://res.cloudinary.com/inmemori/image/upload/v1589456093/web-assets/Email.svg'
                    />
                    <h4 className='mt-3 mb-0 action-mailbox'>{t(`tab-share.steps.1.title`)}</h4>
                  </div>
                  <div
                    onClick={() => setStep('link')}
                    className='actions py-3 mb-3 cursor col-12 col-md-5 action-link'>
                    <img
                      className='action-link'
                      width='100px'
                      src='https://res.cloudinary.com/inmemori/image/upload/v1589456093/web-assets/SMS.svg'
                    />
                    <h4 className='mt-3 mb-0 action-link'>{t(`tab-share.steps.2.title`)}</h4>
                  </div>
                </div>
                <div className='d-flex d-md-none row justify-content-between w-100 mx-auto'>
                  <div
                    onClick={() => setStep('link')}
                    className='actions py-3 mb-3 cursor col-12 col-md-5 action-link'>
                    <img
                      className='action-link'
                      width='100px'
                      src='https://res.cloudinary.com/inmemori/image/upload/v1589456093/web-assets/SMS.svg'
                    />
                    <h4 className='mt-3 mb-0 action-link'>{t(`tab-share.steps.2.title`)}</h4>
                  </div>
                  <div
                    onClick={() => openMailbox()}
                    className='actions py-3 mb-3 cursor col-12 col-md-5 action-mailbox'>
                    <img
                      className='action-mailbox'
                      width='100px'
                      src='https://res.cloudinary.com/inmemori/image/upload/v1589456093/web-assets/Email.svg'
                    />
                    <h4 className='mt-3 mb-0 action-mailbox'>{t(`tab-share.steps.1.title`)}</h4>
                  </div>
                </div>
                {hasRole('manager') && page.meta.status !== 'active' && (
                  <div className='err-alert alert alert-danger-light text-left w-100 px-3 py-2 my-2 my-md-3 mx-0'>
                    <h4 className='mb-1 f1 bold'>{t('tab-share.manager-helper.title')}</h4>
                    <div>{t('tab-share.manager-helper.text')}</div>
                    <div className='text-right w-100'>
                      <Btn
                        className='btn btn-link text-danger mx-2'
                        onClick={() =>
                          flow.set('managers', {currentStep: hasRole('broker') ? 1 : 0})
                        }
                        value={t('tab-share.manager-helper.cta')}
                      />
                    </div>
                  </div>
                )}
                {!invites && offButtonList}
                {!!invites && onButtonList}
              </div>
            )}

            {isStep('email') && (
              <div className='mx-auto col-12 py-0'>
                <div className='form-group mb-3'>
                  <h3>{t('tab-share.labels.email-from')}</h3>
                  <input
                    type='text'
                    name='senderName'
                    value={senderName}
                    onChange={(e) => setSenderName(e.target.value)}
                    className='form-control'
                    placeholder={t('tab-share.ph.email-from-name')}
                  />
                  {!_profile.email && (
                    <input
                      type='text'
                      name='senderEmail'
                      value={senderEmail}
                      onChange={(e) => setSenderEmail(e.target.value)}
                      className='form-control mt-2'
                      placeholder={t('tab-share.ph.email-from-email')}
                    />
                  )}
                </div>
                <div className='form-group'>
                  <h3>{t('tab-share.labels.email-to', {total: emails.length})}</h3>
                  <EmailInput
                    placeholder={t('tab-share.ph.email-to')}
                    formatCreateLabel={(label) =>
                      t('tab-share.ph.email-select', {value: label.substr(0, 25)})
                    }
                    emails={emails}
                    onChange={setEmails}
                  />
                </div>
                <div className='form-group'>
                  {false && <h3>{t('tab-share.labels.email-message')}</h3>}
                  <textarea
                    placeholder=''
                    name='message'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows='10'
                    className='form-control'></textarea>
                </div>
              </div>
            )}

            {isStep('link') && (
              <div className='mx-auto col-12 col-md-9 py-0'>
                <div className='form-group'>
                  <h3>{t('tab-share.labels.link-from')}</h3>
                  <input
                    type='text'
                    name='link'
                    value={reflink}
                    onChange={() => {}}
                    disabled
                    className='form-control input-link mb-2'
                  />
                  {!canShare && (
                    <BtnSpin
                      icon={copied ? 'fas fa-check' : 'fas fa-copy'}
                      pristine={copied}
                      onClick={() => copyLink()}
                      className='m-0 mb-3 w-100 share-cta-link btn btn-primary'>
                      {t(copied ? 'tab-share.cta.copied' : 'tab-share.cta.copy')}
                    </BtnSpin>
                  )}
                  {canShare && (
                    <BtnSpin
                      icon={'fas fa-share'}
                      onClick={() => shareLink()}
                      className='m-0 mb-3 w-100 share-cta-link btn btn-primary'>
                      {t('tab-share.cta.share-link')}
                    </BtnSpin>
                  )}
                </div>
              </div>
            )}

            {isStep('success') && (
              <div className='text-center'>
                <div className='d-md-none my-4 pb-3'>
                  <img width='150px;' src={flux.step.icon} />
                </div>
                <button onClick={() => setStep('intro')} className='m-0 mt-3 btn btn-primary'>
                  {t('common.back')}
                </button>
                {!hasRole('agent') && <div className='mt-3'>{onButtonList}</div>}
              </div>
            )}
          </div>

          <div className='card-footer'>
            {!isStep('intro,success') && (
              <button
                onClick={() => {
                  setStep('intro')
                }}
                className='d-none d-md-inline m-0 btn btn-outline-primary mr-3'>
                {t('common.back')}
              </button>
            )}
            {isStep('email') && (
              <BtnSpin
                icon='fas fa-envelope'
                isLoading={isLoading}
                onClick={() => submitEmail()}
                className='share-cta-email m-0 btn btn-primary'>
                {t('tab-share.cta.email')}
              </BtnSpin>
            )}
          </div>
        </React.Fragment>

        <style jsx>
          {`
            .nav-link {
              // color: #4a4c52 !important;
            }
            .classic-tabs .nav li:first-child {
              margin-left: 0px;
            }

            .card-footer {
              text-align: right;
            }

            .sep-or {
              font-size: 12px;
            }

            .input-link {
              border: 1px solid #9e9e9e !important;
              font-size: 14px;
              text-align: center;
              border-radius: 0.25rem !important;
            }
            .input-link:disabled {
              background: #fff !important;
            }

            @media (max-width: 767px) {
              // md down
              .card-footer {
                text-align: center;
              }
            }

            .actions {
              border: 1px solid #dadada;
              border-radius: 8px;
            }
            .actions:hover {
              background: #eff2f2;
            }
          `}
        </style>
      </div>
    </Flux>
  )
}

export default withGlobal(Block)
