import React, {useState} from 'react'
import {withGlobal} from '../../shared/app'
import {withAuth} from '../../shared/auth'
import {Btn, BtnSpin} from '../../misc/buttons'
import NavBar from '../../widgets/nav-bar'
import CardTop from '../../widgets/card-top'
import {setErrors} from '../../../lib/utils'

import moment from 'moment'

const Block = ({page, setPage, t, flow, api, toaster, _bnr}) => {
  const [isLoading, setLoading] = useState(false)
  const banner = _bnr.getCurrent('check-intro')

  const onFormChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value})
  }

  const getIntro = (index, page = {}) => {
    // console.log('get', `edit-intro.templates.${index}`)
    return t(`edit-intro.templates.${index}`, {
      firstname: page.firstname,
      fullname: page.fullname,
      dod: (page.dod && moment(page.dod).format(t('dates.short'))) || '',
      age: page.age || (page.dob && page.dod && moment(page.dod).diff(page.dob, 'years'))
    })
  }

  const setTemplate = (index) => {
    const intro = getIntro(index, page)
    setForm({...form, intro})
  }

  const [form, setForm] = useState({
    intro: page.intro || getIntro(0, page)
  })

  const save = async () => {
    setLoading(true)
    try {
      const update = await api.put(`/pages/${page.slug}`, {body: form})
      const errs = setErrors(update)
      if (errs) throw errs

      setPage(update)
      flow.set('tab-infos')

      // clear banner
      _bnr.clearBanner('check-intro')
      // if not on current banner, toaster
      if (!banner) toaster.success()
    } catch (e) {
      toaster.error()
      console.log('err.save', e)
    }
    setLoading(false)
  }

  return (
    <div className='card'>
      <NavBar onClick={() => flow.set('prev')}>{t(`edit-intro.title`)}</NavBar>

      <div className='card-body'>
        <CardTop title={t('edit-intro.title')} subtitle={t('edit-intro.subtitle')} />

        <div className='form-group'>
          <textarea
            name='intro'
            value={form.intro}
            onChange={onFormChange}
            rows='8'
            className='form-control'
          >
          </textarea>
        </div>

        {t(`edit-intro.templates.1`, true) && (
          <div className='templates'>
            {t(`edit-intro.templates`, true).map((tpl, i) => (
              <Btn
                key={i}
                icon='https://res.cloudinary.com/inmemori/image/upload/v1592489689/web-assets/picto/plus.svg'
                onClick={() => setTemplate(i)}
                className='btn btn-sm btn-link mr-3'
              >
                {t(`common.suggest`)} {i + 1}
              </Btn>
            ))}
          </div>
        )}
      </div>

      <div className='card-footer text-right'>
        <button
          onClick={() => flow.set('prev')}
          className='d-none d-md-inline m-0 btn btn-outline-primary mr-3'
        >
          {t('common.back')}
        </button>
        <BtnSpin
          pristine={false}
          isLoading={isLoading}
          onClick={() => save()}
          className='m-0 btn btn-primary'
        >
          {t('common.save')}
        </BtnSpin>
      </div>
    </div>
  )
}

export default withGlobal(withAuth(Block))
