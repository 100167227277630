import React, {useState, useMemo} from 'react'
import {withGlobal} from '../shared/app'
import {materialTheme} from '../../lib/utils'
import Zoom, {Controlled as ControlledZoom} from 'react-medium-image-zoom'
import {LetterImageUpload} from '../../lib/fileupload'
import {BtnSpin, Btn} from './buttons'
import Radio from '@material-ui/core/Radio'
import {ThemeProvider} from '@material-ui/styles'

const PreviewSlide = ({t, config = {}, settings, Letter}) => {
  const {index} = settings

  const [previewOpened, setPreviewOpened] = useState(false)
  const [previewIsLoading, setPreviewIsLoading] = useState(false)
  const [previewURL, setPreviewURL] = useState('https://plchldr.co/i/1275x895?&bg=ffffff')

  const previewSlide = async () => {
    const url = Letter.generateSlide(index, {...config}, t)
    const hasDiff = url != previewURL
    if (hasDiff) {
      setPreviewIsLoading(true)
      const result = await fetch(url, {method: 'get'})
      if (result.status == 200) {
        setPreviewURL(url)
        setTimeout(() => openPreview(), 200)
      }
      setPreviewIsLoading(false)
    } else {
      openPreview()
    }
  }

  const openPreview = () => {
    setPreviewOpened(true)
  }

  return (
    <React.Fragment>
      <BtnSpin
        onClick={previewSlide}
        isLoading={previewIsLoading}
        className={`btn btn-sm btn-primary rounded-lg`}>
        {t(`ft-letter.labels.preview`)}
      </BtnSpin>

      <div className='preview-container'>
        <ControlledZoom
          isZoomed={previewOpened}
          onZoomChange={setPreviewOpened}
          overlayBgColorStart='rgba(0, 0, 0, 0.1)'
          overlayBgColorEnd='rgba(0, 0, 0, 0.9)'
          transitionDuration={0}
          zoomMargin={150}>
          <img src={previewURL} className='preview-img' />
        </ControlledZoom>
      </div>

      <style jsx>{`
        .preview-container {
          height: 0rem !important;
          overflow: hidden;
          aspect-ratio: 1;
          position: fixed;
          top: 0;
        }
      `}</style>
    </React.Fragment>
  )
}

const Mode = ({t, modes, mode, config = {}, settings, Letter, cloudinary, changeValue, subs}) => {
  const {index} = settings

  const setProcessedImage = (error, file) => {
    if (!error)
      changeValue({
        ...(mode == 'cover' && {value2: file.serverId.hash}),
        ...(mode == 'image' && {value: file.serverId.hash})
      })
  }

  const setImages = (files) => {
    changeValue({...(mode == 'cover' && {value2: ''}), ...(mode == 'image' && {value: ''}), files})
  }

  if (mode === 'empty') return null

  return (
    <div className='mode-options'>
      {mode === 'empty' && <div />}
      {mode === 'icon' && (
        <div className='d-flex flex-column'>
          <p className='text-light'>{t(`ft-letter.slides.${index}.modes.${mode}.label`, subs)}</p>
          <div className='d-flex flex-row justify-content-start w-100 mx-auto'>
            {Letter.icons
              .filter((icn) => icn.key != 'none')
              .map((icn) => (
                <div
                  key={`icon-picker-${icn.key}`}
                  className={`icon-picker-container cursor text-center p-1 p-lg-3 mr-2 ${
                    config.value === icn.key ? 'active' : ''
                  }`}
                  onClick={() => changeValue({value: icn.key})}>
                  <img className='icon-picker-img' src={cloudinary.url(icn.path)} />
                </div>
              ))}
          </div>
        </div>
      )}
      {mode === 'cover' && (
        <div className='d-flex flex-column'>
          <p className='text-light'>
            1 - {t(`ft-letter.slides.${index}.modes.${mode}.image.label`, subs)}
          </p>
          <div className='w-100 mx-auto'>
            <LetterImageUpload
              files={config.files || []}
              onprocessfile={setProcessedImage}
              onupdatefiles={setImages}
              aspectRatio={modes[mode].aspectRatio}
            />
          </div>
          <p className='text-light mt-3'>
            2 - {t(`ft-letter.slides.${index}.modes.${mode}.icon.label`, subs)}
          </p>
          <div className='d-flex row justify-content-start w-100 mx-auto'>
            {Letter.icons.map((icn) => (
              <div
                key={`cover-icon-picker-${icn.key}`}
                className={`cover-icon-picker-container cursor text-center p-1  p-lg-3 mr-2 ${
                  config.value === icn.key ? 'active' : ''
                }`}
                onClick={() => changeValue({value: icn.key})}>
                {icn.key != 'none' && (
                  <img className='cover-icon-picker-img' src={cloudinary.url(icn.path)} />
                )}
                {icn.key == 'none' && (
                  <p className='text-lighter mb-0'>
                    {t(`ft-letter.slides.${index}.modes.${mode}.icon.none`, subs)}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {mode === 'image' && (
        <div className='d-flex flex-column'>
          <p className='text-light'>{t(`ft-letter.slides.${index}.modes.${mode}.label`, subs)}</p>
          <div className='w-100 mx-auto'>
            <LetterImageUpload
              files={config.files || []}
              onprocessfile={setProcessedImage}
              onupdatefiles={setImages}
              aspectRatio={modes[mode].aspectRatio}
            />
          </div>
        </div>
      )}
      {mode === 'text' && (
        <div className='d-flex flex-column'>
          <div className='d-flex flex-row justify-content-end mb-3 mr-1'>
            <div className='text-light text-italic'>
              {config.value ? config.value.length : '0'}/
              {t(`ft-letter.slides.${index}.modes.${mode}.limit`, true) || '400'}
            </div>
          </div>

          <div className='w-100 mx-auto'>
            <textarea
              placeholder={t(`ft-letter.slides.${index}.modes.${mode}.ph`, subs)}
              value={config.value}
              onChange={(e) => {
                changeValue({mode: 'text', value: e.target.value})
              }}
              rows='5'
              maxLength={t(`ft-letter.slides.${index}.modes.${mode}.limit`, true) || 400}
              className='form-control'
            />
            <div className='d-flex flex-column flex-md-row mt-2'>
              {t(`ft-letter.slides.${index}.modes.${mode}.examples`, true).map((ex, i) => (
                <Btn
                  key={`text-example-${i}`}
                  onClick={() => {
                    changeValue({
                      mode: 'text',
                      value: t(`ft-letter.slides.${index}.modes.${mode}.examples.${i}`, subs)
                    })
                  }}
                  value={`${t(`ft-letter.slides.${index}.modes.${mode}.example`)} ${i + 1}`}
                  icon='fas fa-plus'
                  className={`btn btn-link text-primary ml-3 text-left`}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      {mode === 'address' && (
        <div className='d-flex flex-column'>
          <div className='d-flex flex-row justify-content-between mb-3 mr-1'>
            <div className='text-light'>
              {t(`ft-letter.slides.${index}.modes.${mode}.label`, subs)}
            </div>

            <div className='text-light text-italic'>
              {config.value ? config.value.length : '0'}/
              {t(`ft-letter.slides.${index}.modes.${mode}.limit`, true) || '40'}
            </div>
          </div>

          <div className='w-100 mx-auto'>
            <input
              type='text'
              placeholder={t(`ft-letter.slides.${index}.modes.${mode}.ph`, subs)}
              value={config.value}
              maxLength={t(`ft-letter.slides.${index}.modes.${mode}.limit`, true) || 50}
              onChange={(e) => {
                changeValue({
                  value: e.target.value
                })
              }}
              className='form-control'
            />
          </div>
        </div>
      )}

      <style jsx>{`
        .icon-picker-container {
          width: 20%;
          aspect-ratio: calc(4 / 3);
          border-radius: 6px;
          border: 2px solid rgb(158 158 158 / 12%);
        }

        .icon-picker-container.active {
          border: 2px solid #c8dfe0;
          background: #c8dfe026;
        }

        .icon-picker-container:hover {
          border: 2px solid #c8dfe0;
        }

        .icon-picker-img {
          max-width: 100%;
          max-height: 100%;
        }

        .cover-icon-picker-container {
          width: 20%;
          aspect-ratio: calc(4 / 3);
          border-radius: 6px;
          border: 2px solid rgb(158 158 158 / 12%);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .cover-icon-picker-container.active {
          border: 2px solid #c8dfe0;
          background: #c8dfe026;
        }

        .cover-icon-picker-container:hover {
          border: 2px solid #c8dfe0;
        }

        .cover-icon-picker-img {
          max-width: 100%;
          max-height: 100%;
        }

        @media (max-width: 767px) {
          .icon-picker-container {
            width: 30%;
          }

          .cover-icon-picker-container {
            width: 45%;
            margin-top: 10px;
          }
        }
      `}</style>
    </div>
  )
}

const LetterSlideBuilder = ({
  className = '',
  t,
  page,
  settings,
  config: mainConfig = {},
  onChange = () => {},
  Products,
  cloudinary,
  subs
}) => {
  const {modes, index, preview} = settings

  const config = useMemo(() => mainConfig[`slide${index}`], [mainConfig])

  const singleMode = Object.keys(modes).length == 1
  const pickedMode = singleMode
    ? Object.keys(modes)[0]
    : config && config.mode
    ? config.mode
    : false

  const selectMode = (mode) => {
    if (!config.mode || config.mode != mode) {
      mainConfig[`slide${index}`] = {mode}
      onChange(mainConfig)
    }
  }

  const changeValue = (obj) => {
    mainConfig[`slide${index}`] = {...mainConfig[`slide${index}`], ...obj}
    onChange(mainConfig)
  }

  const defaultConfigForMode = (mode) => {
    switch (mode) {
      case 'icon':
        return {mode, value: 'flower'}
      case 'cover':
        return {mode, value: 'flower', value2: 'nopic'}
      case 'image':
        return {mode, value: 'letter/letter-last-cover-front-default'}
      case 'empty':
        return {mode}
      case 'address':
        return {mode, value: t(`ft-letter.slides.${index}.modes.${mode}.ph`, subs)}
      default:
        return {}
    }
  }

  const status = useMemo(() => {
    return Products.Letter.slideStatus(index, mainConfig)
  }, [mainConfig, index])

  return (
    <ThemeProvider theme={materialTheme}>
      <div className={`${className} my-3 slide-builder status-${status}`}>
        <div className={`d-flex flex-row align-items-center slide-builder-header status-${status}`}>
          <img
            src={`https://res.cloudinary.com/inmemori/image/upload/web-assets/picto/letter/letter-page${index}-${status}`}
          />
          <h3 className='ml-3 mb-0'>{t(`ft-letter.slides.${index}.title`, subs)}</h3>
        </div>

        <div className='mt-3'>{t(`ft-letter.slides.${index}.subtitle`, subs)}</div>

        {!singleMode && (
          <div className='slide-builder-mode-picker mt-3'>
            {Object.keys(modes).map((mode) => {
              const active = mode == pickedMode
              return (
                <div
                  key={`slide-${index}-mode-${mode}`}
                  className='mode-container d-flex flex-column'>
                  <div
                    className={`mode cursor pl-md-2 ${active ? 'active' : ''}`}
                    onClick={() => {
                      selectMode(mode)
                    }}>
                    <div className='d-flex mode-selector flex-row align-items-center'>
                      <Radio checked={active} onChange={() => selectMode(mode)} size='small' />
                      <div className='d-flex flex-column flex-shrink-1 ml-lg-3'>
                        <h4 className='mb-0'>
                          {t(`ft-letter.slides.${index}.modes.${mode}.title`, subs)}
                        </h4>
                        {t(`ft-letter.slides.${index}.modes.${mode}.subtitle`, true) && (
                          <div>{t(`ft-letter.slides.${index}.modes.${mode}.subtitle`, subs)}</div>
                        )}
                      </div>
                    </div>

                    {modes[mode].preview && (
                      <div className='preview-container ml-auto'>
                        <Zoom
                          overlayBgColorStart='rgba(0, 0, 0, 0.1)'
                          overlayBgColorEnd='rgba(0, 0, 0, 0.9)'
                          zoomMargin={150}>
                          <img
                            className='preview-img'
                            src={Products.Letter.generateSlide(
                              index,
                              defaultConfigForMode(mode),
                              t
                            )}
                          />
                        </Zoom>
                        {status === 'on' && preview && active && (
                          <div className='preview-preview d-flex align-items-center justify-content-center'>
                            <PreviewSlide
                              config={config}
                              settings={settings}
                              t={t}
                              Letter={Products.Letter}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {active && (
                    <Mode
                      key={`modebuilder-slide-${index}-mode-${pickedMode}`}
                      t={t}
                      modes={modes}
                      mode={pickedMode}
                      cloudinary={cloudinary}
                      Letter={Products.Letter}
                      config={config}
                      settings={settings}
                      changeValue={changeValue}
                      subs={subs}
                    />
                  )}
                </div>
              )
            })}
          </div>
        )}

        {singleMode && (
          <div className='d-flex flex-column mt-3'>
            <Mode
              key={`modebuilder-slide-${index}-mode-${pickedMode}`}
              t={t}
              modes={modes}
              mode={pickedMode}
              page={page}
              cloudinary={cloudinary}
              Letter={Products.Letter}
              config={config}
              settings={settings}
              changeValue={changeValue}
              subs={subs}
            />
            {status === 'on' && preview && (
              <div className='mt-3'>
                <PreviewSlide config={config} settings={settings} t={t} Letter={Products.Letter} />
              </div>
            )}
          </div>
        )}
      </div>

      <style jsx>{`
        .mode {
          border: 2px solid rgb(158 158 158 / 12%);
          margin-bottom: -2px;
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .mode.active {
          border: 2px solid #c8dfe0;
          background: #c8dfe026;
        }

        .mode:hover {
          border: 2px solid #c8dfe0;
        }

        .mode-selector {
          min-height: 120px;
          max-height: 130px;
        }

        .preview-container {
          width: 228px;
          border-left: 1px solid rgb(158 158 158 / 12%);
          border-top: 0px solid rgb(158 158 158 / 12%);
          position: relative;
          align-items: center !important;
          display: flex;
        }

        .preview-img {
          max-width: 100%;
          max-height: 100%;
        }

        .preview-preview {
          position: absolute;
          top: 0;
          left: 0;
          bottom 0;
          right: 0;
          background-color: #9e9e9eaa !important;
          z-index: 10;
        }

        .slide-builder-header.status-off > h3 {
          color: #9e9e9e !important;
        }

        .slide-builder-header.status-on > h3 {
          color: var(--primary) !important;
        }

        .slide-builder-header.status-error > h3 {
          color: #ffa47e !important;
        }

        .slide-builder {
          padding-top: 3rem;
          margin-top: 4rem;
          border-top: 1px solid #dddddd;
        }

        .slide-builder:first-child {
          padding-top: 0rem;
          margin-top: 0rem;
          border-top: 0px solid #9e9e9e;
        }

        @media (max-width: 767px) {
          .slide-builder-header > img {
            max-height: 20px;
          }

          .mode {
            flex-direction: column;
            min-height: 90px;
            justify-content: center;
            align-items: start;
          }

          .mode.active {
            justify-content: between;
            min-height: 100px;
          }

          .mode .preview-container {
            display: none;
          }

          .mode .mode-selector {
            min-height: 90px;
          }
          .mode.active .preview-container {
            display: flex;
            width: 100%;
            height: auto;
            border-left: 0px solid rgb(158 158 158 / 12%);
            border-top: 1px solid rgb(158 158 158 / 12%);
          }
        }
      `}</style>
    </ThemeProvider>
  )
}

export default withGlobal(LetterSlideBuilder)
