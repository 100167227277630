import React, {useState, useEffect, useMemo} from 'react'
import _ from 'lodash'
import {withGlobal} from '../../shared/app'
import {StripeInput, PayToEndpoint} from '../../../lib/stripe'
import {setErrors, failValidation} from '../../../lib/utils'
import {CardTopKeepSubtitle} from '../../widgets/card-top'
import NavBar from '../../widgets/nav-bar'
import Spinner from '../../misc/spinner'
import {Btn, BtnSpin} from '../../misc/buttons'
import {Flux, FluxBar, useFlux} from '../../misc/flux'
import {useOrder} from '../../store/order'
import {loadStripe} from '@stripe/stripe-js'
import moment from 'moment'
import ImageGallery from '../../misc/image-gallery'
import Cart from '../../widgets/cart'
import Dropdown from '../../misc/dropdown'

const Block = ({
  page,
  Page,
  flow,
  t,
  lang,
  toaster,
  api,
  query,
  env,
  _profile,
  tracker,
  Products,
  uiref = {page: flow.prev},
  locations,
  cloudinary,
  trackEvent
}) => {
  const [isLoading, setLoading] = useState(false)
  const [isLoaded, setLoaded] = useState(false)
  const [stripe, setStripe] = useState(null)
  const [stripeCard, setStripeCard] = useState(null)
  const {catalog, run} = Products.Flower
  const [withInvoice, setWithInvoice] = useState(false)
  const {currency} = page
  const [sort, setSort] = useState('recommended')
  const [sortOpen, setSortOpen] = useState(false)

  let order = useOrder(
    {
      client: {email: _profile.email},
      meta: {uiref}
    },
    {
      feature: 'flower',
      currency,
      env
    }
  )

  const tz = moment.tz.guess()

  const flux = useFlux({
    steps: t(`ft-flowers.steps`, true) || [],
    defaultStep: () => query.step,
    onCheckpoint: tracker.funnelOrder('flower-central', false, order),
    onChange: () => {
      document.documentElement.scrollTop = 0
    },
    hasPrev: ({step}) => !['catalog', 'success'].includes(step.key),
    onPrevMiss: () => flow.set('prev'),
    t: ({step}) => (key, subs, ref) =>
      t(`ft-flowers.steps.${(ref || step || {}).index}.${key}`, {...page, ...subs})
  })

  const {step, setStep, isStep, getSteps} = flux

  const selectItem = async (item, nextStep = true) => {
    nextStep && setStep('next')
    trackEvent(`flower:selected`, {id: item._id}, 'profile')
    order.setItem({...item, qty: 1})
    order.setOptions(order.sanitizeOptions(item.options), false)
    selectLocation(item.ctx.locations[0]._id, item)
  }

  const selectLocation = (id, item = null) => {
    order.setShipping(getLocation(id, item))
  }

  const getLocation = (id, item = null) => {
    const pageLocation = locations.find((l) => l._id === id)
    const shipLocation = (item ? item : order.item).ctx.locations.find((l) => l._id === id)

    if (!pageLocation || !shipLocation) return {}

    const location = {
      ..._.pick(pageLocation, ...'_id,name,address,city,zipcode,tz,meta'.split(',')),
      ...{date: pageLocation.meta.date},
      ...{latlng: pageLocation.latlng.string},
      ...{title: pageLocation.meta.customType || t(`places.types.${pageLocation.meta.type}`)},
      ...shipLocation.shipping
    }

    return location
  }

  const submit = async () => {
    if (failValidation('client.firstname,client.lastname,client.email', t)) return
    setLoading(true)
    try {
      const payload = order.vanilla()

      let update = await api.post(`/pages/${page.slug}/flower`, {
        body: {...payload, validate: true}
      })
      if (!update.success) {
        // exit flow if update fails
        setErrors(update)
        return setLoading(false)
      }

      // pass auto generated order_id
      payload.order_id = update.order_id

      const paymentParams = [
        stripe,
        stripeCard,
        `${process.env.API_URI}/pages/${page.slug}/payment`,
        {billing_details: {name: `${order.client.firstname} ${order.client.lastname}`}},
        payload
      ]

      // try payment
      const payment = await PayToEndpoint(...paymentParams)

      // payment error
      if (!(payment && payment.status == 'succeeded')) {
        console.log('payment.error', payment)
        toaster.error(t('common.errors.payment'))
        return setLoading(false)
      }

      // assign tx info to payload
      payload.tx = payment

      // save order
      let flowerOrder = await api.post(`/pages/${page.slug}/flower`, {body: payload})
      let errs = setErrors(flowerOrder)
      if (errs) throw errs

      order.setTx(flowerOrder.tx)
      Page.reloadPage()
      setStep('next')
    } catch (e) {
      console.log('err.submit', e)
    }
    setLoading(false)
  }

  const submitShipping = async () => {
    setLoading(true)

    if (!(await Products.Flower.isItemAvalaibleForLocation(order.item._id, order.shipping._id))) {
      setLoading(false)
      setStep('catalog')
      toaster.error('Item not available anymore.')
      return
    }

    if (
      withInvoice &&
      failValidation('invoice.name,invoice.address,invoice.email,invoice.zipcode,invoice.city', t)
    )
      return

    if (withInvoice) {
      order.setClient({email: order.invoice.email, phone: order.invoice.phone})
    }

    setStep('next')
    setLoading(false)
  }

  const submitCart = async () => {
    if (failValidation('options.ribbon.message, options.card.message', t)) return
    setStep('next')
  }

  const reset = () => {
    order.reset()
    flow.set('tab-infos')
  }

  useEffect(() => {
    const select = Products.Flower.get(query.flower)
    if (select) selectItem(select)
  }, [Products.Flower.catalog])

  useEffect(() => {
    ;(async () => {
      // init stripe
      setStripe(await loadStripe(process.env.STRIPE_KEY))
    })()
  }, [])

  useEffect(() => {
    order.setMeta({...order.meta, print: run.print})
  }, [run.id])

  const sortedCatalog = useMemo(() => {
    if (sort !== 'recommended') return Products.sorted('flower', 'price_int', sort)
    else return Products.feature('flower')
  }, [catalog, sort])

  if (!step) return <div className='py-5 text-center'>{`step "${query.step}"does not exist`}</div>

  const CtaBar = (
    <div className='d-flex justify-content-center justify-content-md-between'>
      {!isStep('success') && (
        <button
          onClick={() => setStep('prev')}
          className='d-none d-md-block m-0 btn btn-outline-primary mr-2'>
          {t('common.back')}
        </button>
      )}
      {isStep('cart') && (
        <BtnSpin
          isLoading={isLoading}
          onClick={() => submitCart()}
          className='btn btn-primary m-0 '>
          {flux.t('cta')}
        </BtnSpin>
      )}
      {isStep('shipping') && (
        <BtnSpin
          isLoading={isLoading}
          onClick={() => submitShipping()}
          className='btn btn-primary m-0 '>
          {flux.t('cta')}
        </BtnSpin>
      )}
      {isStep('payment') && (
        <BtnSpin isLoading={isLoading} onClick={() => submit()} className='btn btn-primary m-0 '>
          {flux.t('cta', {total: order.getTotalPrice()})}
        </BtnSpin>
      )}
    </div>
  )

  return (
    <Flux flux={flux} debug={env.debug}>
      <div className='card'>
        <NavBar onClick={() => setStep('prev')}>{step.title}</NavBar>

        <div className='card-body errors-scope'>
          {!isStep('catalog,success') && (
            <FluxBar steps={getSteps('catalog,success', true)} current={step} flux={flux} />
          )}

          <CardTopKeepSubtitle title={flux.t('title')} subtitle={flux.t('subtitle')} />

          {isLoaded && <Spinner />}

          {!isLoaded && (
            <React.Fragment>
              {
                /* STEP */ isStep('catalog') && (
                  <React.Fragment>
                    <div>
                      {sortedCatalog.length > 0 && (
                        <React.Fragment>
                          <div className='d-flex flex-row justify-content-end px-1'>
                            <Dropdown
                              isOpen={sortOpen}
                              onClose={() => setSortOpen(false)}
                              target={
                                <div className='cursor' onClick={() => setSortOpen(!sortOpen)}>
                                  {t('ft-flowers.sort.title')}
                                  <span className='text-primary ml-2'>
                                    {t(`ft-flowers.sort.${sort}`)}
                                    <i className='ml-1 fas fa-chevron-down'></i>
                                  </span>
                                  <div className='d-none flower-catalog-sort-order'>{sort}</div>
                                </div>
                              }>
                              <div className='sort-dropdown-container f-flex flex-column'>
                                {['recommended', 'desc', 'asc'].map((s, i) => (
                                  <div
                                    key={i}
                                    onClick={() => {
                                      setSortOpen(false)
                                      setSort(s)
                                    }}
                                    className={`cursor px-3 py-2 sort-dropdown-item ${
                                      sort === s ? 'active' : ''
                                    }`}>
                                    {t(`ft-flowers.sort.${s}`)}
                                  </div>
                                ))}
                              </div>
                            </Dropdown>
                          </div>
                          <div className='grid mt-2'>
                            {sortedCatalog.map((flower, i) => (
                              <div
                                className='item pb-3 pt-2 cursor'
                                {...(flower.fishout &&
                                  env.debug && {
                                    style: {backgroundColor: '#ffa47e', borderColor: '#FF8F60'}
                                  })}
                                key={i}
                                onClick={() => selectItem(flower)}>
                                <div className='d-flex flex-column align-items-start'>
                                  <div className='item-pic-container'>
                                    <img
                                      className='img-fluid item-pic'
                                      src={cloudinary.url(
                                        Products.Flower.imagePathFromCatalog(
                                          flower,
                                          flower.images[0]
                                        ),
                                        {
                                          filter: 'c_fit,h_1000,q_auto:best,w_1000,fl_lossy,f_auto'
                                        }
                                      )}
                                    />
                                    {flower.tags && (
                                      <div className='item-tag-list d-flex flex-column'>
                                        {flower.tags.map((tag) => {
                                          return (
                                            <div
                                              key={`flower-index-${i}-tag-${tag}`}
                                              className='d-inline-block text-primary py-1 px-2 mb-1 rounded bg-white'
                                              style={{width: 'fit-content'}}>
                                              {t(`products.tags.${tag}`)}
                                            </div>
                                          )
                                        })}
                                      </div>
                                    )}
                                  </div>
                                  <h4 className='item-name f2 text-center mt-2'>{flower.name}</h4>
                                  <span>
                                    {t('common.startingAt')}{' '}
                                    <span className='text-primary font-weight-bold'>
                                      {flower.price}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </React.Fragment>
                )
              }

              {
                /* STEP */ isStep('cart') && order.item.name && (
                  <React.Fragment>
                    <div className='row d-flex align-items-top'>
                      <ImageGallery
                        className='col-12 col-sm-6 col-md-12 col-lg-6 px-md-5 mb-2 mb-md-0 '
                        images={order.item.images.map((img) =>
                          Products.Flower.imagePathFromCatalog(order.item, img)
                        )}
                        optimize={false}
                        whiteBackground
                      />
                      <div className='col-12 col-sm-6 col-md-12 col-lg-6'>
                        <Cart
                          order={order}
                          editQty={false}
                          showDesc
                          showItemDesc
                          showShippingPrice={false}
                        />
                        {CtaBar}
                      </div>
                    </div>
                  </React.Fragment>
                )
              }

              {
                /* STEP */ isStep('shipping') && (
                  <React.Fragment>
                    <div>
                      <ul className='shipping-places list-unstyled'>
                        {(order.item.ctx.locations || []).map((location, i) => {
                          location = getLocation(location._id)
                          if (!location) return null
                          if (location.meta?.disableFlower) return null

                          const isSelected = location._id == order.shipping._id
                          const date =
                            location.date &&
                            moment(location.date)
                              .tz(location.tz || tz)
                              .format(t('dates.medium'))

                          return (
                            <li
                              className={`cursor card mb-2 px-md-3 ${isSelected ? 'active' : ''}`}
                              key={i}
                              onClick={() => selectLocation(location._id)}>
                              <div className='py-3 px-3 px-lg-0 d-flex align-items-center'>
                                <div className='d-flex mr-md-3'>
                                  <div className='custom-control custom-radio'>
                                    <input
                                      readOnly
                                      checked={isSelected}
                                      type='radio'
                                      className='custom-control-input'
                                    />
                                    <label className='custom-control-label'> </label>
                                  </div>
                                </div>
                                <div className='d-flex flex-column flex-lg-row justify-content-between align-items-lg-center flex-lg-fill'>
                                  <div>
                                    <div>
                                      <h3 style={{display: 'inline'}} className='mr-3'>
                                        {location.title}
                                      </h3>
                                      {date && <span>{date}</span>}
                                    </div>
                                    <div className='small text-light'>{location.name}</div>
                                    <div className='small text-light'>{location.address}</div>
                                  </div>
                                  <div className='d-flex align-items-center mt-3 mt-lg-0'>
                                    <span className='badge rounded-pill bg-white d-flex align-items-center mr-2'>
                                      <i className='fas fa-shipping-fast mr-2 text-primary' />
                                      <span
                                        style={{fontSize: '10px'}}
                                        className='d-flex flex-lg-column'>
                                        <span> LIVRAISON </span>
                                        <span> EXPRESS </span>
                                      </span>
                                    </span>
                                    {location.price}
                                  </div>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                      </ul>

                      <div className='form-group'>
                        <label className='switch switch-smal w-100 d-flex align-items-center justify-content-start cursor m-0'>
                          <span className='mr-2'>{t('order.invoice.with')}</span>
                          <input
                            type='checkbox'
                            name='privacy'
                            checked={withInvoice}
                            onChange={() => setWithInvoice(!withInvoice)}
                          />
                          <span className='switcher'></span>
                        </label>
                      </div>

                      {withInvoice && (
                        <div>
                          <hr />
                          <div className='form-group'>
                            <input
                              errkey='invoice.name'
                              name='name'
                              value={order.invoice.name}
                              onChange={order.onChange('invoice')}
                              type='text'
                              className='form-control'
                              placeholder={t('common.name')}
                            />
                          </div>
                          <div className='form-group'>
                            <input
                              name='vat'
                              value={order.invoice.vat}
                              onChange={order.onChange('invoice')}
                              type='text'
                              className='form-control'
                              placeholder={t('common.vat')}
                            />
                          </div>
                          <div className='form-group'>
                            <input
                              errkey='invoice.address'
                              name='address'
                              value={order.invoice.address}
                              onChange={order.onChange('invoice')}
                              type='text'
                              className='form-control'
                              placeholder={t('common.address')}
                            />
                          </div>
                          <div className='form-group row'>
                            <div className='col-6' style={{paddingRight: '8px'}}>
                              <input
                                errkey='invoice.zipcode'
                                name='zipcode'
                                value={order.invoice.zipcode}
                                onChange={order.onChange('invoice')}
                                type='text'
                                className='form-control'
                                placeholder={t('common.zipcode')}
                              />
                            </div>
                            <div className='col-6' style={{paddingLeft: '8px'}}>
                              <input
                                errkey='invoice.city'
                                name='city'
                                value={order.invoice.city}
                                onChange={order.onChange('invoice')}
                                type='text'
                                className='form-control'
                                placeholder={t('common.city')}
                              />
                            </div>
                          </div>
                          <div className='form-group row'>
                            <div className='col-6' style={{paddingRight: '8px'}}>
                              <input
                                errkey='invoice.email'
                                name='email'
                                value={order.invoice.email}
                                onChange={order.onChange('invoice')}
                                type='email'
                                className='form-control'
                                placeholder={t('common.email')}
                              />
                            </div>
                            <div className='col-6' style={{paddingLeft: '8px'}}>
                              <input
                                errkey='invoice.phone'
                                name='phone'
                                value={order.invoice.phone}
                                onChange={order.onChange('invoice')}
                                type='text'
                                className='form-control'
                                placeholder={t('common.phone')}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='mt-5'>{CtaBar}</div>
                  </React.Fragment>
                )
              }

              {
                /* STEP */ isStep('payment') && (
                  <React.Fragment>
                    <div className='row'>
                      <div className='col-12 col-sm-6 col-md-12 col-lg-6 mb-5'>
                        <div className='form-group row'>
                          <div className='col-6' style={{paddingRight: '8px'}}>
                            <input
                              errkey='client.firstname'
                              name='firstname'
                              value={order.client.firstname}
                              onChange={order.onChange('client')}
                              type='text'
                              className='form-control'
                              placeholder={t('common.firstname')}
                            />
                          </div>
                          <div className='col-6' style={{paddingLeft: '8px'}}>
                            <input
                              errkey='client.lastname'
                              name='lastname'
                              value={order.client.lastname}
                              onChange={order.onChange('client')}
                              type='text'
                              className='form-control'
                              placeholder={t('common.lastname')}
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <div className='col-12'>
                            <input
                              errkey='client.email'
                              name='email'
                              value={order.client.email}
                              onChange={order.onChange('client')}
                              type='email'
                              className='form-control'
                              placeholder={t('common.email')}
                            />
                          </div>
                        </div>
                        <div className='form-group row'>
                          <div className='col-12'>
                            <input
                              name='phone'
                              value={order.client.phone}
                              onChange={order.onChange('client')}
                              type='text'
                              className='form-control'
                              placeholder={t('common.phone')}
                            />
                          </div>
                        </div>
                        <div className='form-group'>
                          <div className='form-control stripe-control mb-2'>
                            <StripeInput onReady={setStripeCard} stripe={stripe} locale={lang} />
                          </div>
                          <div className='stripe-error d-none alert alert-danger-light'></div>
                          <div className='err-message d-none alert alert-danger-light'>
                            future error message
                          </div>
                        </div>
                        <div className='text-center'>
                          <img
                            src='https://res.cloudinary.com/inmemori/image/upload/v1591786960/web-assets/misc/cards.png'
                            height='38px'></img>
                          <img src='https://res.cloudinary.com/inmemori/image/upload/v1591786760/web-assets/misc/powered_by_stripe.svg'></img>
                        </div>
                      </div>
                      <div className='col-12 col-sm-6 col-md-12 col-lg-6'>
                        <Cart order={order} readOnlyOptions />
                        {CtaBar}
                      </div>
                    </div>
                  </React.Fragment>
                )
              }

              {
                /* STEP */ isStep('success') && (
                  <React.Fragment>
                    <div className='flux-success form text-center'>
                      <img src='https://res.cloudinary.com/inmemori/image/upload/v1589456087/web-assets/Ce%CC%81re%CC%81monie.svg' />
                      <div className='col-12 col-md-8 col-lg-6 mx-auto mt-4 '>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='text-light'>{t(`order.payment.amount`)}</div>
                          <div>{order.tx.price}</div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='text-light'>{t(`order.payment.ref`)}</div>
                          <small>{order.tx.ch_id || 'ch_xxxxxxxxxxxxxxxxxxxxxxxx'}</small>
                        </div>
                      </div>
                      <Btn onClick={() => reset()} className='btn btn-primary mt-5'>
                        {flux.t('cta')}
                      </Btn>
                    </div>
                  </React.Fragment>
                )
              }

              {/* CARD-FOOTER */}
            </React.Fragment>
          )}
        </div>
        {/* END CARD-BODY */}
      </div>

      <style jsx>
        {`
          .sort-dropdown-item:hover {
            background-color: #c9e0e0 !important;
          }

          .item:hover h4 {
            color: var(--primary);
          }

          .item-pic-container {
            position: relative;
            display: block;
            width: 100%;
            padding-top: calc(100%);
            background-color: #f8f5f3;
            border-radius: 16px;
            overflow: hidden;
          }

          .item-pic {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }

          .item-tag-list {
            position: absolute;
            top: 1em;
            left: 1em;
          }

          .flux-success > div {
            border-top: 1px solid #dadada;
          }
          .flux-success > div > div {
            padding-top: 20px;
          }

          .shipping-places li {
            border: 1px solid #9e9e9e;
            border-radius: 8px;
          }
          .shipping-places li.active {
            background: #eff2f2;
            border-color: #32735f;
          }

          .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 20px;
          }

          @media (max-width: 1800px) {
            // xl down
            .grid {
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 20px;
            }
          }

          @media (max-width: 991px) {
            // lg down
            .grid {
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 15px;
            }
          }

          @media (max-width: 767px) {
            // md down
            .grid {
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 10px;
            }
          }

          @media (max-width: 605px) {
            // sm down
            .grid {
              grid-template-columns: repeat(1, 1fr);
              grid-gap: 10px;
            }
          }
        `}
      </style>
    </Flux>
  )
}

export default withGlobal(Block)
