import React, {useEffect} from 'react'
import {withGlobal} from '../../shared/app'
import {withQuery} from '../../../lib/utils'
import Spinner from '../../misc/spinner'

const Block = ({page, query, api, Auth, t, utms, flow}) => {
  const {key, nextFlow} = query
  const {slug} = page

  const trySwap = async () => {
    const {jwt} = await api.post(`/auth/swap`, {jwt: key})

    if (jwt) {
      let redirect = `/${slug}`
      if (nextFlow && !['login', 'swap'].includes(nextFlow)) redirect += `/${nextFlow}`
      redirect = withQuery(redirect, utms) // add utms

      await Auth.login(jwt, {redirect})
    } else {
      flow.set('onboarding')
    }
  }

  useEffect(() => {
    ;(async () => {
      trySwap()
    })()
  }, [])

  return (
    <div className='card'>
      <div className='card-body text-center mb-5 pb-5 text-center' style={{minHeight: '200px'}}>
        <Spinner />
        <h3 className='mt-4'>{t(`common.loading`) || 'Loading'}</h3>
      </div>
    </div>
  )
}

export default withGlobal(Block)
