import React, {useState, useCallback, useMemo} from 'react'
import {createPortal} from 'react-dom'
import {Controlled as ControlledZoom} from 'react-medium-image-zoom'
import {useEvent} from 'react-use'

import {withGlobal} from '../shared/app'

const ImageGallery = ({
  images,
  selectedindex = 0,
  optimize = true,
  className = '',
  cloudinary,
  shadow = false,
  zoomMargin = 0,
  whiteBackground = false
}) => {
  const [imgPic, setImgPic] = useState(selectedindex)
  const [isZoomed, setIsZoomed] = useState(false)
  const [galleryControllerContainer, setGalleryControllerContainer] = useState(false)

  const handleKeyDown = useCallback(
    (e) => {
      if (!isZoomed) {
        return
      }

      if (e.key === 'ArrowLeft' || e.keyCode === 37) {
        prevImage()
      } else if (e.key === 'ArrowRight' || e.keyCode === 39) {
        nextImage()
      }
    },
    [imgPic, images.length, isZoomed]
  )

  const nextImage = () => {
    setImgPic(Math.min(imgPic + 1, images.length - 1))
  }

  const prevImage = () => {
    setImgPic(Math.max(imgPic - 1, 0))
  }

  useMemo(() => {
    if (isZoomed) {
      setTimeout(() => {
        if (isZoomed) {
          const elems = document.querySelectorAll('[data-rmiz-overlay="true"]')
          if (elems.length) {
            setGalleryControllerContainer(elems[0])
          }
        }
      }, 100)
    } else {
      setGalleryControllerContainer(false)
    }
  }, [isZoomed])

  useEvent('keydown', handleKeyDown, document)

  return (
    <div id='gallery' className={`${className} d-flex gallery text-center`}>
      {isZoomed &&
        galleryControllerContainer &&
        createPortal(
          <div>
            <div className='gallery-controller gallery-next cursor' onClick={nextImage}>
              <i className='far fa-2x fa-arrow-alt-circle-right text-lighter' />
            </div>
            <div className='gallery-controller gallery-prev cursor' onClick={prevImage}>
              <i className='far fa-2x fa-arrow-alt-circle-left text-lighter' />
            </div>
          </div>,
          galleryControllerContainer
        )}

      <div className='gallery-zoom'>
        <ControlledZoom
          zoomZindex={5000}
          isZoomed={isZoomed}
          onZoomChange={setIsZoomed}
          overlayBgColorStart='rgba(0, 0, 0, 0.1)'
          overlayBgColorEnd='rgba(0, 0, 0, 0.9)'
          zoomMargin={zoomMargin}>
          <img
            src={
              cloudinary
                ? cloudinary.url(
                    images[imgPic],
                    optimize
                      ? {
                          filter: 'f_auto,c_fit,h_2000,q_auto:good,w_2000,fl_lossy'
                        }
                      : {}
                  )
                : images[imgPic]
            }
            className={`img-fluid z-depth-1 gallery-main`}
          />
        </ControlledZoom>
      </div>
      {images.length > 1 && (
        <div className='d-flex gallery-thumbnails px-lg-4'>
          {images.map((img, i) => (
            <img
              key={i}
              onClick={() => setImgPic(i)}
              className={`cursor img-fluid gallery-thumbnail ${imgPic == i ? 'selected' : ''}`}
              src={
                cloudinary
                  ? cloudinary.url(
                      img,
                      optimize
                        ? {
                            filter: 'f_auto,c_fit,h_300,q_auto:good,w_300,fl_lossy'
                          }
                        : {}
                    )
                  : img
              }
            />
          ))}
        </div>
      )}

      <style jsx>
        {`
          .gallery-controller {
            position: fixed;
            top: calc(50% - 16px);
            z-index: 6000;
            padding: 10px;
          }

          .gallery-controller.gallery-next {
            right: 0;
            display: ${isZoomed && imgPic + 1 < images.length ? 'block' : 'none'};
          }

          .gallery-controller.gallery-next img {
            transform: rotateY(180deg);
          }

          .gallery-controller.gallery-prev {
            left: 0;
            display: ${isZoomed && imgPic - 1 >= 0 ? 'block' : 'none'};
          }

          .gallery-thumbnail {
            max-width: 45px;
            max-height: 100px;
            margin: 5px;
            border-radius: 4px;
            border: 1px solid #c7c7c7;
          }

          .gallery-thumbnail:hover {
            border: 1px solid #9e9e9e;
          }

          .gallery-thumbnail.selected {
            border: 1px solid #32735f;
          }

          .gallery {
            flex-direction: column !important;
            max-height: 500px;
          }

          .gallery-thumbnails {
            flex-direction: row !important;
            justify-content: center !important;
            margin-top: 10px;
            margin-left: 0px;
          }

          .gallery-main {
            max-height: 300px;
            ${shadow ? 'box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);' : ''}
            ${whiteBackground ? 'background-color: white !important' : ''}
          }

          // md down
          /*@media (max-width: 767px) {
            .gallery {
              flex-direction: row !important;
              max-height: 300px;
            }

            .gallery-zoom {
              // width: 100%;
            }

            .gallery-thumbnail {
              max-width: 100px;
              max-height: 45px;
            }

            .gallery-thumbnails {
              flex-direction: column !important;
              justify-content: space-around !important;
              margin-top: 0px;
              margin-left: 10px;
            }
          }*/
        `}
      </style>
    </div>
  )
}

export default withGlobal(ImageGallery)
