import React, {useState} from 'react'
import {withGlobal} from '../../shared/app'
import {withAuth} from '../../shared/auth'
import {BtnSpin} from '../../misc/buttons'
import PlacesEdit from '../../widgets/places-edit'
import {setErrors} from '../../../lib/utils'
import NavBar from '../../widgets/nav-bar'
import CardTop from '../../widgets/card-top'

const Block = (props) => {
  const {page, setPage, t, flow, api, toaster, _bnr, locations, setLocations, Page} = props
  const banner = _bnr.getCurrent('check-places')

  const [isLoading, setLoading] = useState(false)
  const [pristine, setPristine] = useState(false)
  const [updateErrors, setUpdateErrors] = useState([])

  const [info, setInfo_] = useState(page.info)
  const setInfo = (v) => {
    setPristine(false)
    setInfo_(v)
  }

  const [pendingLocations, setPendingLocations_] = useState(locations.length ? locations : false)
  const setPendingLocations = (v) => {
    setPristine(false)
    setPendingLocations_(v)
  }

  const save = async () => {
    setLoading(true)
    try {
      if (page.info != info) {
        const update = await api.put(`/pages/${page.slug}`, {
          body: {info}
        })
        const errs = setErrors(update)
        if (errs) throw errs
        setPage(update)
      }

      const update = await Page.updateLocations(pendingLocations)
      const errors = update.filter((u) => u.status === 'rejected') || []

      if (errors.length === 0) flow.set('tab-infos')
      else setUpdateErrors(errors)

      // clear banner
      _bnr.clearBanner('check-places')
      // if not on current banner, toaster
      if (!banner && errors.length === 0) toaster.success()
    } catch (e) {
      console.log('err.save', e)
      toaster.error()
    }
    setLoading(false)
  }

  return (
    <div className='card'>
      <NavBar onClick={() => flow.set('prev')}>{t(`edit-places.title`)}</NavBar>

      <div className='card-body'>
        <CardTop title={t('edit-places.title')} subtitle={t('edit-places.subtitle')} />

        <PlacesEdit
          pendingLocations={pendingLocations}
          setPendingLocations={setPendingLocations}
          updateErrors={updateErrors}
          theme='grid'
          itemClass='card p-3 shadow'
        />

        <div className='mt-5'>
          <textarea
            name='info'
            value={info}
            onChange={(e) => setInfo(e.target.value)}
            placeholder={t('edit-places.ph.info')}
            rows='8'
            className='form-control'></textarea>
        </div>
      </div>

      <div className='card-footer text-right'>
        <button
          onClick={() => flow.set('prev')}
          className='d-none d-md-inline m-0 btn btn-outline-primary mr-3'>
          {t('common.back')}
        </button>
        <BtnSpin
          pristine={pristine}
          isLoading={isLoading}
          onClick={() => save()}
          className='m-0 btn btn-primary'>
          {t('common.save')}
        </BtnSpin>
      </div>

      <style jsx>
        {`
          .templates button {
            margin: 0;
          }
          .templates button:first-child {
            padding-left: 0;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(withAuth(Block))
