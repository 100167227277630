import React, {useState} from 'react'
import {withGlobal} from '../../shared/app'
import {setErrors, failValidation} from '../../../lib/utils'
import Statecodes from '../../../lib/states-codes'
import CardTop from '../../widgets/card-top'
import NavBar from '../../widgets/nav-bar'
import {Btn, BtnSpin} from '../../misc/buttons'
import {Flux, FluxBar, useFlux} from '../../misc/flux'
import NoAccess from '../../widgets/no-access'
import InputPhone, {getPhone} from '../../misc/input-phone'
import DateInput from '../../misc/date-input'
import Popup from '../../../lib/popup'
import * as ToastsTemplates from '../../shared/toasts'

import Select from 'react-select'
import moment from 'moment'
import slugify from 'slug'
import {StripeDocumentUpload} from '../../../lib/fileupload'

const mockdata = () => {
  return ''
}

const defaultForm = ({donation, zoneInfo}) => ({
  state: donation.state,
  beneficiary: donation.beneficiary,
  org: donation.org,
  message: donation.message,
  firstname: donation.firstname || mockdata('firstname'),
  lastname: donation.lastname || mockdata('lastname'),
  email: donation.email || mockdata('email'),
  phone: donation.phone || mockdata('phone'),
  dob: donation.dob || mockdata('dob'),
  country: donation.country || zoneInfo.country,
  address: donation.address || mockdata('address'),
  statecode: donation.statecode || mockdata('statecode'),
  city: donation.city || mockdata('city'),
  zipcode: donation.zipcode || mockdata('zipcode'),
  documentid: donation.documentid || '',
  documentid2: donation.documentid2 || '',
  ssnlast4: donation.ssnlast4 || '',
  iban: donation.iban || mockdata('iban'),
  routing: donation.routing || ''
})

const Block = ({
  page,
  setPage,
  env,
  hasRole,
  query,
  flow,
  t,
  lang,
  toaster,
  api,
  zones,
  currentStep,
  countries,
  defaultCountry,
  tracker
}) => {
  if (!hasRole('manager')) return <NoAccess />

  const [isLoading, setLoading] = useState(false)
  const [form, setForm] = useState(defaultForm(page))
  const [phone, setPhone] = useState(getPhone(form.phone, defaultCountry))
  const [doc1, setDoc1] = useState(null)
  const [doc2, setDoc2] = useState(null)
  const [docsErrors, setDocsErrors] = useState([])

  const flux = useFlux({
    steps: t(`ft-donation-settings.steps`, true) || [],
    // , excludeSteps: ['config']
    onCheckpoint: tracker.funnel('donation-settings'),
    defaultStep: () =>
      query.step || currentStep || (form.state == 1 && 'active') || (form.state == 2 && 'closed'),
    onChange: () => {
      document.documentElement.scrollTop = 0
    },
    hasPrev: ({step}) => ['config', 'bank'].includes(step.key),
    onPrevMiss: () => flow.set('prev'),
    t: ({step}) => (key, subs, ref) =>
      t(`ft-donation-settings.steps.${(ref || step || {}).index}.${key}`, subs)
  })

  const {step = {}, setStep, isStep, getSteps} = flux

  // handy alias
  const isUSD = form.country == 'us'
  const {total_f} = page.stats.donations

  const isPristine = (step = 0) => {
    if (step == 'config')
      return form.beneficiary && (form.beneficiary == 'org' ? form.org.name : true) ? false : true
    if (step == 'bank')
      return form.firstname &&
        form.lastname &&
        form.email &&
        phone.clean &&
        form.address &&
        form.zipcode &&
        form.city &&
        form.dob &&
        form.iban &&
        (doc1 || form.documentid) &&
        (doc2 || form.documentid2)
        ? false
        : true
    if (step == 'active') return form.documentid ? false : true
    return true
  }

  const getChange = (e) => {
    const {target} = e
    const value = target.type === 'checkbox' ? target.checked : target.value
    return {[target.name]: value}
  }

  const onFormChange = (e) => {
    setForm({...form, ...getChange(e)})
  }

  const onDateChange = (field) => (date) => {
    if (!date) {
      setForm({...form, [field]: ''})
      return
    }

    date.add(moment().utcOffset(), 'minutes')
    setForm({...form, [field]: date.toDate()})
  }

  const transformData = (items) => {
    items = items.map((item) => ({
      value: item,
      label: `${item}`
    }))

    items = [...items, {value: 'custom', label: t(`common.other`)}]
    return items
  }

  const handleBeneficiaryChange = (e) => {
    const change = getChange(e)
    const message = getTemplate(0, change)
    setForm({...form, ...change, message, org: {}})
  }

  const handleOrgChange = (data, meta) => {
    if (!['create-option', 'select-option'].includes(meta.action)) return

    if ('create-option' === meta.action || data.value === 'custom') {
      const org = {
        slug: 'custom',
        name: ''
      }
      const message = getTemplate(0, {org})
      setForm({...form, org, message})
      return
    }

    const org = {
      slug: slugify(data.value, {lower: true}),
      name: data.label
    }
    const message = getTemplate(0, {org})
    setForm({...form, org, message})
  }

  const handleCustomOrgChabge = (e) => {
    const org = {
      slug: 'custom',
      name: e.target.value
    }
    const message = getTemplate(0, {org})
    setForm({...form, org, message})
  }

  const getTemplate = (index, {org = {}, beneficiary} = {}) => {
    return t(`ft-donation-settings.templates.${beneficiary || form.beneficiary}.${index}`, {
      firstname: page.firstname,
      fullname: page.fullname,
      org: org.name || form.org.name || '[---]'
    })
  }

  const setTemplate = (index) => {
    setForm({...form, message: getTemplate(index)})
  }

  const updateDonation = async () => {
    if (failValidation('email', t)) return

    setLoading(true)
    try {
      const body = {
        slug: page.slug,
        noBankProcess: !['bank', 'active'].includes(step.key)
      }

      // only send non empty fields
      Object.keys(form).map((k) => {
        if (form[k] || form[k] === 0) body[k] = form[k]
      })
      if (doc1) body.documentid = doc1.serverId
      if (doc2) body.documentid2 = doc2.serverId
      if (phone.clean) body.phone = phone.clean

      // we switch state to active
      if (step.key == 'bank') body.state = 1

      // update
      let update = await api.put(`/donations/activate`, {body})
      let errs = setErrors(update)
      if (errs) {
        if (errs.err) toaster.error(errs.err)
        else toaster.error()
        throw errs
      }

      if (step.key == 'config') setStep('next')
      else if (step.key == 'active') {
        toaster.success()
        flow.set('prev')
      } else if (step.key == 'bank') {
        toaster.create({
          element: ToastsTemplates.ToastDefault({
            toaster,
            ...toaster.props,
            label: t('snackbar.default.donationActivated')
          })
        })()
        setStep('active')
      }

      setPage(update)
      setForm(defaultForm(update))
      setDocsErrors([])
    } catch (e) {
      console.log('err.updateDonation', e)
    }
    setLoading(false)
  }

  const closeDonation = async () => {
    Popup.areYouSure(t(`popups.close-donation`, true), async () => {
      setLoading(true)
      try {
        const body = {slug: page.slug}
        // update
        let update = await api.post(`/donations/end`, {body})
        let errs = setErrors(update)
        if (errs) {
          errs.err && toaster.error(errs.err)
          throw errs
        }

        setPage(update)
        setStep('closed')
      } catch (e) {
        console.log('err.closeDonation', e)
        toaster.error(e)
      }
      setLoading(false)
    })
  }

  const setProcessedDocument1 = (error, file) => {
    if (!error) {
      setDoc1(file)
      toaster.success()
    }
  }

  const updateDocument1 = (files) => {
    if (files.length == 0) setDoc1(null)
  }

  const setProcessedDocument2 = (error, file) => {
    if (!error) {
      setDoc2(file)
      toaster.success()
    }
  }

  const updateDocument2 = (files) => {
    if (files.length == 0) setDoc2(null)
  }

  return (
    <Flux flux={flux} debug={env.debug}>
      <div className='card'>
        <NavBar onClick={() => setStep('prev')}>{step.title}</NavBar>

        <div className='card-body errors-scope'>
          {isStep('intro,config,bank') && (
            <FluxBar steps={getSteps('intro,config,bank')} current={step} flux={flux} />
          )}

          <CardTop title={flux.t('title')} subtitle={flux.t('subtitle')} />

          {isStep('active') && (
            <div className='meta-info text-center'>
              <h3 className='total'>{total_f}</h3>
              <Btn
                className='btn btn-link text-primary'
                onClick={() => flow.set('donators')}
                value={t('donators.title')}
                icon='https://res.cloudinary.com/inmemori/image/upload/v1595428468/web-assets/picto/list-green.svg'
              />
            </div>
          )}

          {
            /* STEP */ step.key == 'intro' && (
              <div className='flux-intro d-flex align-items-center justify-content-center'>
                <div className='mx-4'>
                  <img src='https://res.cloudinary.com/inmemori/image/upload/v1589456088/web-assets/long-arrow-down.svg' />
                </div>
                <div className='d-flex flex-column'>
                  {(t('ft-donation-settings.tips', true) || [{}, {}, {}, {}]).map((tip, i) => (
                    <div key={i} className='tips text-left d-flex mt-3'>
                      <h4 className='mr-3'>{i + 1}</h4>
                      <div className='d-flex flex-column justify-content-between' style={{flex: 1}}>
                        <h4>{t(`ft-donation-settings.tips.${i}.title`)}</h4>
                        <div className='subtitle text-light'>
                          {t(`ft-donation-settings.tips.${i}.subtitle`)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          }

          {
            /* STEP */ isStep('config,active') && (
              <div className=''>
                <div className='form-group'>
                  <h3>{t('ft-donation-settings.labels.beneficiary')}</h3>
                  <div className='custom-control custom-radio'>
                    <input
                      onChange={handleBeneficiaryChange}
                      checked={form.beneficiary == 'family' && 'checked'}
                      type='radio'
                      className='custom-control-input'
                      id='beneficiary-family'
                      name='beneficiary'
                      value='family'
                    />
                    <label className='custom-control-label cursor' htmlFor='beneficiary-family'>
                      {t('ft-donation-settings.labels.family')}
                    </label>
                  </div>

                  <div className='custom-control custom-radio'>
                    <input
                      onChange={handleBeneficiaryChange}
                      checked={form.beneficiary == 'org' && 'checked'}
                      type='radio'
                      className='custom-control-input'
                      id='beneficiary-org'
                      name='beneficiary'
                      value='org'
                    />
                    <label className='custom-control-label cursor' htmlFor='beneficiary-org'>
                      {t('ft-donation-settings.labels.org')}
                    </label>
                  </div>
                </div>

                {form.beneficiary == 'org' && (
                  <div className='form-group'>
                    <Select
                      isClearable
                      onChange={handleOrgChange}
                      onInputChange={handleOrgChange}
                      options={transformData(t('organisations', true))}
                      placeholder={t('ft-donation-settings.ph.org')}
                      defaultValue={
                        form.org.slug
                          ? form.org.slug === 'custom'
                            ? {value: form.org.slug, label: t(`common.other`)}
                            : {value: form.org.slug, label: form.org.name}
                          : undefined
                      }
                    />
                    {form.org.slug === 'custom' && (
                      <input
                        onChange={handleCustomOrgChabge}
                        type='text'
                        className='form-control mt-2'
                        placeholder={t('ft-donation-settings.ph.custom-org')}
                        value={form.org.name}
                      />
                    )}
                  </div>
                )}

                {((form.beneficiary == 'org' && form.org.name) || form.beneficiary == 'family') && (
                  <div className='mb-5'>
                    <h3 className='mt-5'>{t('ft-donation-settings.labels.message')}</h3>
                    <div className='notes text-light'>
                      {t('ft-donation-settings.notes.message')}
                    </div>

                    <textarea
                      name='message'
                      value={form.message}
                      onChange={onFormChange}
                      rows='6'
                      className='form-control'>
                      {/*t('edit:info.tabs.4.input-message-placeholder')*/}
                    </textarea>
                    {t(`ft-donation-settings.${form.beneficiary}.templates.1`, true) && (
                      <div className='templates'>
                        <button onClick={() => setTemplate(0)} className='btn btn-sm btn-link'>
                          {t(`common.suggest`)} 1
                        </button>
                        <button onClick={() => setTemplate(1)} className='btn btn-sm btn-link'>
                          {t(`common.suggest`)} 2
                        </button>
                        <button onClick={() => setTemplate(2)} className='btn btn-sm btn-link'>
                          {t(`common.suggest`)} 3
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )
          }

          {
            /* STEP */ isStep('bank,active') && (
              <div className='form'>
                {false && !page.donations.length && (
                  <div className='form-group'>
                    <select
                      disabled={form.country && 'disabled'}
                      className='form-control'
                      name='country'
                      value={form.country}
                      onChange={onFormChange}>
                      <option value=''>{t('common.country')}</option>
                      {zones.map((z, i) => (
                        <option key={i} value={z.country}>
                          {z.name} - {z.currency.ticker} ({z.currency.value})
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {isStep('bank') && (
                  <React.Fragment>
                    <h3>{t('ft-donation-settings.labels.identity')}</h3>
                    <div className='form-group row'>
                      <div className='col-6' style={{paddingRight: '8px'}}>
                        <input
                          type='text'
                          name='firstname'
                          value={form.firstname}
                          onChange={onFormChange}
                          className='form-control'
                          placeholder={t('common.firstname')}
                        />
                      </div>
                      <div className='col-6' style={{paddingLeft: '8px'}}>
                        <input
                          type='text'
                          name='lastname'
                          value={form.lastname}
                          onChange={onFormChange}
                          className='form-control'
                          placeholder={t('common.lastname')}
                        />
                      </div>
                    </div>

                    <div className='form-group row'>
                      <div className='col-6' style={{paddingRight: '8px'}}>
                        <input
                          errkey='email'
                          name='email'
                          value={form.email}
                          onChange={onFormChange}
                          type='email'
                          className='form-control'
                          placeholder={t('common.email')}
                        />
                      </div>
                      <div className='col-6' style={{paddingLeft: '8px'}}>
                        <InputPhone
                          status={!!phone.clean}
                          defaultPhone={phone}
                          onChange={setPhone}
                          countries={countries}
                          defaultCountry={form.country}
                          placeholder={t(`common.phone`)}
                        />
                      </div>
                    </div>

                    <div className='form-group' errkey='dob'>
                      <DateInput
                        value={form.dob ? moment(form.dob) : null}
                        onChange={onDateChange('dob')}
                        locale={lang}
                        maxDate={moment().add(-18, 'years')}
                        disableFuture
                        placeholderText={t('common.dob')}
                      />
                    </div>

                    <div className='form-group'>
                      <input
                        name='address'
                        value={form.address}
                        onChange={onFormChange}
                        type='text'
                        className='form-control'
                        placeholder={t('common.address')}
                      />
                    </div>

                    <div className='form-group row'>
                      <div className='col-6' style={{paddingRight: '8px'}}>
                        <input
                          name='zipcode'
                          value={form.zipcode}
                          onChange={onFormChange}
                          type='text'
                          className='form-control'
                          placeholder={t('common.zipcode')}
                        />
                      </div>
                      <div className='col-6' style={{paddingLeft: '8px'}}>
                        <input
                          name='city'
                          value={form.city}
                          onChange={onFormChange}
                          type='text'
                          className='form-control'
                          placeholder={t('common.city')}
                        />
                      </div>
                    </div>
                    {isUSD && (
                      <div className='form-group row'>
                        <div className='col-6' style={{paddingRight: '8px'}}>
                          <select
                            name='statecode'
                            onChange={onFormChange}
                            value={form.statecode}
                            className='form-control'>
                            <option value=''>-- State Code --</option>
                            {Statecodes.map((s, i) => (
                              <option key={i} value={s.code}>
                                {s.code} - {s.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='col-6' style={{paddingLeft: '8px'}}>
                          <input
                            name='ssnlast4'
                            value={form.ssnlast4}
                            onChange={onFormChange}
                            type='text'
                            className='form-control'
                            placeholder={t('common.ssn')}
                          />
                        </div>
                      </div>
                    )}

                    <h3 className='mt-5'>{t('ft-donation-settings.labels.bank')}</h3>
                    <div className='form-group'>
                      <div className='notes text-light'>{t('ft-donation-settings.notes.iban')}</div>
                      <input
                        name='iban'
                        value={form.iban}
                        onChange={onFormChange}
                        type='text'
                        className='form-control'
                        placeholder={t('ft-donation-settings.ph.iban')}
                      />
                      <div className='err-alert alert alert-danger-light p-1 my-2 d-none'>
                        iban err zone
                      </div>
                    </div>
                    {isUSD && (
                      <div className='form-group'>
                        <input
                          name='routing'
                          value={form.routing}
                          onChange={onFormChange}
                          type='text'
                          className='form-control'
                          placeholder={t('ft-donation-settings.ph.routing')}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )}

                {true && (
                  <React.Fragment>
                    <h3 className='mt-5'>{t('ft-donation-settings.labels.kyc')}</h3>
                    <div className='form-group'>
                      <div className='notes text-light'>
                        {t('ft-donation-settings.notes.documents')}
                      </div>
                      <ul>
                        {form.documentid && (
                          <li>
                            Document 1 <i className='fa fa-check text-primary' />
                          </li>
                        )}
                        {form.documentid2 && (
                          <li>
                            Document 2 <i className='fa fa-check text-primary' />
                          </li>
                        )}
                      </ul>

                      {!(form.documentid && form.documentid2) && (
                        <React.Fragment>
                          <div className='alert alert-danger-light pre-line'>
                            {t('ft-donation-settings.warning.files')}
                          </div>
                          <div className='d-flex flex-row align-self-center justify-content-center'>
                            {!form.documentid && (
                              <div className='flex-fill m-1'>
                                <StripeDocumentUpload
                                  files={doc1 ? [doc1] : []}
                                  placeholder={t('ft-donation-settings.cta.doc-recto')}
                                  onprocessfile={setProcessedDocument1}
                                  onupdatefiles={updateDocument1}
                                  onprocessfiles={() => {
                                    setLoading(false)
                                  }}
                                  onaddfile={() => {
                                    setLoading(true)
                                  }}
                                  name='document1'
                                  mock={
                                    process.env.APP_ENV != 'production' &&
                                    process.env.APP_ENV != 'local'
                                  }
                                  purpose='identity_document'
                                  pk={process.env.STRIPE_KEY}
                                />
                              </div>
                            )}

                            {!form.documentid2 && (
                              <div className='flex-fill m-1'>
                                <StripeDocumentUpload
                                  files={doc2 ? [doc2] : []}
                                  placeholder={t('ft-donation-settings.cta.doc-verso')}
                                  onprocessfile={setProcessedDocument2}
                                  onupdatefiles={updateDocument2}
                                  onprocessfiles={() => {
                                    setLoading(false)
                                  }}
                                  onaddfile={() => {
                                    setLoading(true)
                                  }}
                                  name='document2'
                                  mock={
                                    process.env.APP_ENV != 'production' &&
                                    process.env.APP_ENV != 'local'
                                  }
                                  purpose='identity_document'
                                  pk={process.env.STRIPE_KEY}
                                />
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      )}

                      {docsErrors.length > 0 && (
                        <div className='alert alert-danger-light'>
                          {docsErrors.map((err, i) => (
                            <div className='d-block' key={i}>
                              - {err}
                            </div>
                          ))}
                        </div>
                      )}
                      {/*<div className="alert alert-danger-light">{t('ft-donation-settings.warning.documents')}</div>*/}
                    </div>
                  </React.Fragment>
                )}
              </div>
            )
          }

          {
            /* STEP */ isStep('active') && (
              <div className='form'>
                <h3 className='mt-5'>{t('ft-donation-settings.labels.close')}</h3>
                <div className='notes text-light'>{t('ft-donation-settings.notes.close')}</div>
                {isPristine(step.key) && (
                  <div className='alert alert-danger-light'>
                    {t('ft-donation-settings.warning.close')}
                  </div>
                )}
                <BtnSpin
                  pristine={isPristine(step.key)}
                  isLoading={isLoading}
                  onClick={() => closeDonation()}
                  className='btn btn-outline-light m-0'>
                  {t('ft-donation-settings.cta.close')}
                </BtnSpin>
              </div>
            )
          }

          {
            /* STEP */ isStep('closed') && (
              <div className='form text-center'>
                <img src='https://res.cloudinary.com/inmemori/image/upload/v1589456088/web-assets/Gerer_v2.svg' />
                <div className='col-12 col-md-10 col-lg-9 mx-auto mt-4 text-light'>
                  {t('ft-donation-settings.notes.closed', page)}
                </div>
                <Btn
                  className='btn btn-link text-primary mt-4'
                  onClick={() => flow.set('donators')}
                  value={t('donators.title')}
                  icon='https://res.cloudinary.com/inmemori/image/upload/v1595428468/web-assets/picto/list-green.svg'
                />
              </div>
            )
          }
        </div>
        {/* END CARD-BODY */}

        {/* CARD-FOOTER */}
        {false && env.debug && (
          <div>
            <button onClick={() => setStep('prev')} className='m-0 p-1 btn btn-md'>
              {'<-'}
            </button>
            <button onClick={() => setStep('next')} className='m-0 p-1 btn btn-md'>
              {'->'}
            </button>
          </div>
        )}
        {true && (
          <div className='card-footer d-flex justify-content-end'>
            <button
              onClick={() => setStep('prev')}
              className='d-none d-md-block m-0 btn btn-outline-primary mr-2'>
              {t('common.back')}
            </button>
            {isStep('intro') && (
              <BtnSpin onClick={() => setStep('next')} className='btn btn-primary m-0'>
                {flux.t('cta')}
              </BtnSpin>
            )}
            {isStep('config') && (
              <BtnSpin
                pristine={isPristine(step.key)}
                isLoading={isLoading}
                onClick={() => updateDonation()}
                className='btn btn-primary m-0 '>
                {flux.t('cta')}
              </BtnSpin>
            )}
            {isStep('bank') && (
              <BtnSpin
                pristine={isPristine(step.key)}
                isLoading={isLoading}
                onClick={() => updateDonation()}
                className='btn btn-primary m-0 '>
                {flux.t('cta')}
              </BtnSpin>
            )}
            {isStep('active') && (
              <BtnSpin
                isLoading={isLoading}
                onClick={() => updateDonation()}
                className='btn btn-primary m-0 '>
                {flux.t('cta')}
              </BtnSpin>
            )}
          </div>
        )}
      </div>

      <style jsx>
        {`
          .notes {
            margin-bottom: 20px;
          }

          .flux-intro .subtitle {
            font-size: 14px;
          }

          .tips {
            margin-right: 26px;
          }
          .tips:lats-child {
            margin-right: 0px;
          }

          .meta-info {
            margin-top: -20px;
            margin-bottom: 80px;
          }
        `}
      </style>
    </Flux>
  )
}

export default withGlobal(Block)
