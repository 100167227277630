import {withGlobal} from '../shared/app'
import {Btn} from '../misc/buttons'

export const BtnEdit = withGlobal(({className, onClick, t}) => (
  <Btn
    onClick={onClick}
    value={t('common.edit')}
    icon='https://res.cloudinary.com/inmemori/image/upload/v1589806556/web-assets/picto/edit.svg'
    className={`btn btn-link text-primary ${className}`}
  />
))
