export const withAuth = (Component) => {
  return (props) => {
    const {authed, flow} = props

    if (!authed) {
      flow.set('onboarding')
      return null
    } else return <Component {...props} />
  }
}
