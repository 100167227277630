import React, {useState} from 'react'
import {withGlobal} from '../../shared/app'
import {withAuth} from '../../shared/auth'
import {BtnSpin} from '../../misc/buttons'
import InputPhone, {getPhone} from '../../misc/input-phone'

import {failValidation} from '../../../lib/utils'
import {FlowStep} from '../../../lib/constants'
import {CardTopKeepSubtitle} from '../../widgets/card-top'
import NavBar from '../../widgets/nav-bar'
import AddressForm from '../../misc/address-form'

const Block = ({
  page,
  _profile,
  t,
  flow,
  toaster,
  _tribe,
  canSkip = false,
  Profile,
  countries,
  defaultCountry,
  hasRole
}) => {
  const [isLoading, setLoading] = useState(false)

  const defaultForm = {
    name: _profile.name || '',
    email: _profile.email || '',
    phone: getPhone(_profile.phone, defaultCountry),
    tribeData: {
      relationship: _tribe.relationship || ''
    },
    locationData: _profile.location || {},
    slug: page.slug
  }

  const [form, setForm] = useState(defaultForm)

  const onFormChange = (e) => {
    const newForm = {...form, [e.target.name]: e.target.value}
    setForm(newForm)
  }

  const onPhoneChange = (phone) => {
    const newForm = {...form, phone}
    setForm(newForm)
  }

  const [address, _setAddress] = useState(_profile.location || {})

  const setAddress = (add) => {
    _setAddress(add)
    const newForm = {
      ...form,
      locationData: add
    }
    setForm(newForm)
  }
  const onRelationshipChange = (e) => {
    const newForm = {
      ...form,
      tribeData: {
        relationship: e.target.value
      }
    }
    setForm(newForm)
  }

  const save = async () => {
    if (failValidation('email,name,relationship', t, {selector: '.errors-scope', page})) return
    setLoading(true)

    try {
      await Profile.updateProfile({...form, phone: form.phone.clean})
      flow.set(FlowStep.default)
    } catch (e) {
      toaster.error()
      console.log('err.save', e)
    }
    setLoading(false)
  }

  const skip = () => {
    flow.set(FlowStep.default)
  }
  const missing =
    !_profile.email && !_tribe.relationship
      ? 'all'
      : !_profile.email
      ? 'email'
      : !_tribe.relationship
      ? 'relationship'
      : 'all'

  const subtitle = hasRole('manager')
    ? t(`profile-setup.subtitle.manager.${missing}`, page)
    : t(`profile-setup.subtitle.user.${missing}`, page)

  return (
    <div className='card'>
      <NavBar showPrev={false} onClick={() => flow.set('prev')}>
        {t(`profile-setup.title`)}
      </NavBar>

      <div className='card-body d-flex flex-column col errors-scope'>
        <CardTopKeepSubtitle title={t('profile-setup.title', page)} subtitle={subtitle} />

        {(!_profile.email || !_profile.name || !_profile.phone) && (
          <div>
            {!_profile.name && (
              <div className='form-group'>
                <input
                  type='text'
                  name='name'
                  value={form.name}
                  onChange={onFormChange}
                  className='form-control'
                  placeholder={t('profile.ph.name')}
                />
              </div>
            )}

            {!_profile.email && (
              <div className='form-group'>
                <input
                  errkey='email'
                  type='email'
                  name='email'
                  value={form.email}
                  onChange={onFormChange}
                  className='form-control'
                  placeholder={t('profile.ph.email')}
                  required
                />
              </div>
            )}

            {!_profile.phone && (
              <div className='form-group'>
                <InputPhone
                  errkey='phone'
                  status={form.phone.raw ? (form.phone.clean ? true : false) : ''}
                  defaultPhone={form.phone}
                  onChange={onPhoneChange}
                  countries={countries}
                  defaultCountry={defaultCountry}
                  placeholder={t(`profile.ph.phone`)}
                />
              </div>
            )}
          </div>
        )}

        {!_tribe.relationship && (
          <div className='form-group'>
            <select
              errkey='relationship'
              name='relationship'
              value={form.tribeData.relationship}
              onChange={onRelationshipChange}
              className={`form-control ${form.tribeData.relationship.length ? '' : 'text-light'}`}>
              <option value='' selected hidden>
                {t('profile.ph.relationship', page)}
              </option>
              {Profile.relationshipCases(t, page).map((r) => (
                <option key={`relationships.option.${r}`} value={r}>
                  {t(`relationships.list.${r}.${page.defunct.gender || 'm'}`)}
                </option>
              ))}
            </select>
          </div>
        )}

        {!_profile.location && (
          <div>
            <AddressForm form={address} onChange={setAddress} />
          </div>
        )}
      </div>

      <div className='card-footer text-right'>
        {canSkip && (
          <BtnSpin onClick={() => skip()} className='mr-3 btn btn-border'>
            {t('common.skip')}
          </BtnSpin>
        )}
        <BtnSpin isLoading={isLoading} onClick={() => save()} className='m-0 btn btn-primary'>
          {t('common.save')}
        </BtnSpin>
      </div>

      <style jsx>
        {`
          .form-label {
            margin-top: 1rem;
            margin-bottom: 0.75rem;
            color: var(--light);
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(withAuth(Block))
