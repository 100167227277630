import React from 'react'
import {withGlobal} from '../../shared/app'

const Block = ({flow, t, uiref = {}}) => {
  const onClick = () => {
    flow.set(`request-managers`, {currentStep: 1, uiref})
  }

  return (
    <div>
      <div onClick={onClick} className='actions py-3 mb-3 cursor tile-request-manager text-center'>
        <img width='100px' src={t(`tiles.request-manager.icon`)} />
        <h4 className='mt-3 mb-0'>{t(`tiles.request-manager.title`)}</h4>
      </div>

      <style jsx>
        {`
          .actions {
            border: 1px solid #dadada;
            border-radius: 8px;
          }
          .actions:hover {
            background: #eff2f2;
          }
        `}
      </style>
    </div>
  )
}

export const checkRequestManagerTile = () => {
  return false
}

export default withGlobal(Block)
