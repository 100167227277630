import React from 'react'

const parseItems = (menu) => {
  if (Array.isArray(menu)) return menu
  if (typeof menu == 'object') {
    Object.keys(menu).map((key) => (menu[key].key = key))
    return Object.values(menu)
  }
  return []
}

export const BigMenu = ({
  items = [],
  onClick = () => {},
  className = '',
  hideIndexes = [],
  namespace = 'big-menu',
  justify = 'between'
}) => {
  const hide = (i) => hideIndexes.includes(i)
  return (
    <div className={`${className}`}>
      <div className={`d-flex justify-content-${justify}`} style={{height: '250px'}}>
        {parseItems(items).map((item, index) => (
          <div
            key={index}
            className={`${hide(index) ? 'd-none' : 'd-flex'} flex-column justify-content-between`}
          >
            <div className='text-center'>
              {item.title && <h3>{item.title}</h3>}
              {item.icon && <img className='mt-3 img-fluid' src={item.icon} />}
            </div>
            <div className=''>
              <button
                className={`${namespace}-${item.key} btn btn-outline-primary mt-3 mx-1`}
                onClick={() => onClick(item, index)}
              >
                {item.cta}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

// # items exemple
// [
//     { cta: "Envoyer un email", icon: "..." }
//   , { cta: "M’envoyer le lien par sms" }
//   , { cta: "Whatsapp" }
// ]
//
// # onClick=(item, index) => {}

export const ActionMenu = ({
  items = [],
  onClick = () => {},
  show,
  toggle,
  className = '',
  namespace = 'action-menu'
}) => {
  return (
    <div
      className={`actions d-none ${
        show ? 'open d-flex' : ''
      } d-md-none flex-column justify-content-end ${className}`}
    >
      <div
        className='void d-flex flex-column justify-content-end align-items-center'
        onClick={() => toggle(!show)}
      >
        <img
          className='cursor'
          width='34px'
          src='https://res.cloudinary.com/inmemori/image/upload/v1590091800/web-assets/picto/add.svg'
        />
      </div>

      <div className='list-group cursor'>
        {parseItems(items).map((item, index) => (
          <a
            key={index}
            onClick={() => onClick(item, index)}
            className={`${namespace}-${item.key} list-group-item list-group-item-action text-primary f1 bold py-4 text-center`}
          >
            {item.cta}
          </a>
        ))}
      </div>
      <style jsx>
        {`
          .actions {
            position: fixed;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }

          .void {
            background: rgba(34, 34, 34, 0.7);
            flex: 1;
          }
          .void img {
            transform: rotate(-45.45deg);
            margin-bottom: 20px;
          }

          .list-group {
            margin-bottom: -140px;
            transition: all 0.3s ease-in-out 0s;
            background: rgba(34, 34, 34, 0.7);
          }
          .actions.open .list-group {
            margin-bottom: 69px;
          }

          .list-group-item:first-child {
            border-top-left-radius: 16px !important;
            border-top-right-radius: 16px !important;
          }
          .list-group-item:last-child {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          }
        `}
      </style>
    </div>
  )
}

export const DotBtn = ({onClick}) => (
  <div className='toggle cursor d-flex d-md-none justify-content-center' onClick={onClick}>
    <img
      width='24px'
      src='https://res.cloudinary.com/inmemori/image/upload/v1590091800/web-assets/picto/add.svg'
    />
    <style jsx>
      {`
        .toggle {
          position: fixed;
          bottom: 120px;
          right: 20px;
          z-index: 2;
          background: #32735f;
          width: 56px;
          height: 56px;
          border-radius: 100%;
        }
      `}
    </style>
  </div>
)
