import React, {useState, useEffect} from 'react'
import {withGlobal} from '../../shared/app'
import {Btn} from '../../misc/buttons'
import DonatorsList from '../../widgets/donators-list'
import CardTop from '../../widgets/card-top'
import NavBar from '../../widgets/nav-bar'
import NoAccess from '../../widgets/no-access'

const downloadCSV = (filename, csvstring = '') => {
  const e = window.document.createElement('a')
  e.href = window.URL.createObjectURL(new Blob([csvstring], {type: 'text/csv'}))
  e.download = filename || 'file.csv'

  document.body.appendChild(e)
  e.click()
  e.remove()
}

const exportList = (list) => {
  const head = 'Prénom;Nom;Email;Adresse postale;Donation'
  let total = 0
  const data = list.map((d) => {
    total += d.tx.price_less_fee_int
    return [
      d.client.firstname,
      d.client.lastname,
      d.client.email,
      (d.invoice &&
        `${d.invoice.address || ''} ${d.invoice.zipcode || ''} ${d.invoice.city || ''}`.trim()) ||
        '',
      d.tx.price_less_fee
    ].join(';')
  })
  data.push(['', '', '', 'TOTAL', total].join(';'))
  const csv = [head, ...data].join('\n')
  downloadCSV('donators-export.csv', csv)
}

const Block = ({page, Page, hasRole, orders, flow, t, toaster}) => {
  const [list, setList] = useState(
    orders ? orders.filter((order) => order.feature === 'donation') : []
  )

  useEffect(() => {
    ;(async () => {
      try {
        setList(await Page.fetchOrders('donation'))
      } catch (e) {
        toaster.success(e.message)
      }
    })()
  }, [])

  useEffect(() => {
    if (list && list.length && list.length != page.stats.donations.count)
      console.log('Error: Invalid diff between donations stats and orders')
  }, [list])

  if (!hasRole('manager')) return <NoAccess />

  return (
    <div className='card'>
      <NavBar onClick={() => flow.set('prev')}>{t(`donators.title`)}</NavBar>

      <div className='card-body'>
        <CardTop
          title={t(`donators.title`)}
          subtitle={t(`donators.subtitle`, {count: list.length})}
        />

        <React.Fragment>
          <DonatorsList list={list} />
        </React.Fragment>
      </div>

      <div className='card-footer d-flex justify-content-between'>
        <Btn
          className='btn btn-link text-primary'
          onClick={() => exportList(list)}
          value={t('donators.cta.export')}
          icon='https://res.cloudinary.com/inmemori/image/upload/v1595428350/web-assets/picto/download.svg'
        />
        {true && (
          <button
            onClick={() => flow.set('prev')}
            className='d-none d-md-inline m-0 btn btn-outline-primary'>
            {t('common.back')}
          </button>
        )}
      </div>
    </div>
  )
}

export default withGlobal(Block)
