import TabInfos from './tab-infos'
import TabMemories from './tab-memories'
import TabShare from './tab-share'
import TabSettings from './tab-settings'
import TabHelp from './tab-help'

import EditIntro from './edit-intro'
import EditProfile from './edit-profile'
import EditPlaces from './edit-places'
import EditBroadcast from './edit-broadcast'

import Profile from './profile'
import ProfileSetup from './profile-setup'

import Unsubscribe from './unsubscribe'
import StopAssistance from './stop-assistance'

import FtFlower from './ft-flower'
import FtBook from './ft-book'
import FtLetter from './ft-letter'
import FtDonation from './ft-donation'
import FtDonationSettings from './ft-donation-settings'
import Donators from './donators'

import NewMemory from './new-memory'
import Managers from './managers'
import Swap from './swap'
import Confirm from './confirm'
import AccessConfirm from './access-confirm'
import Logout from '../../shared/logout'
import Invitations from './invitations'
import Thank from './thank'
import Onboarding from './onboarding'
import Splash from './splash'

import {FlowStep} from '../../../lib/constants'

const B = {}

B[FlowStep.tabInfos] = TabInfos
B[FlowStep.tabMemories] = TabMemories
B[FlowStep.tabShare] = TabShare
B[FlowStep.tabSettings] = TabSettings
B[FlowStep.tabHelp] = TabHelp

B[FlowStep.onboarding] = Onboarding
B[FlowStep.splash] = Splash

B[FlowStep.ftFlower] = FtFlower
B[FlowStep.ftFlowers] = FtFlower
B[FlowStep.ftLetter] = FtLetter
B[FlowStep.ftBook] = FtBook
B[FlowStep.ftDonation] = FtDonation
B[FlowStep.ftDonationSettings] = FtDonationSettings
B[FlowStep.donators] = Donators

B[FlowStep.editIntro] = EditIntro
B[FlowStep.editProfile] = EditProfile
B[FlowStep.editPlaces] = EditPlaces
B[FlowStep.editBroadcast] = EditBroadcast

B[FlowStep.unsubscribe] = Unsubscribe
B[FlowStep.stopAssistance] = StopAssistance

B[FlowStep.profile] = Profile
B[FlowStep.profileSetup] = ProfileSetup

B[FlowStep.newMemory] = NewMemory
B[FlowStep.managers] = Managers
B[FlowStep.swap] = Swap
B[FlowStep.logout] = Logout
B[FlowStep.invitations] = Invitations
B[FlowStep.thank] = Thank
B[FlowStep.confirm] = Confirm
B[FlowStep.accessConfirm] = AccessConfirm

export default B
