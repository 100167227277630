import React from 'react'
import {withGlobal} from '../../shared/app'
import {H1} from '../../misc/headings'

const Block = ({flow, t, page, uiref = {}}) => {
  const onClick = () => {
    flow.set(`tab-share`, {
      uiref
    })
  }

  return (
    <div className='d-block d-md-none card'>
      <div className='card-body'>
        <H1>{t(`card-share.h1`)}</H1>

        <div className='d-flex flex-column flex-lg-row align-items-center'>
          <div className='z1'>
            <img className='mb-3 img-fluid' src={t(`card-share.icon`)} />
          </div>
          <div className='z2'>
            {t(`card-share.title`, true) && <h3>{t(`card-share.title`, page)}</h3>}
            <div className='text-light pre-line'>{t(`card-share.text`, page)}</div>
            <button className='btn btn-primary mt-3' onClick={() => onClick()}>
              {t(`card-share.cta`)}
            </button>
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .z1 {
            flex: 1;
          }
          .z2 {
            flex: 2;
          }

          img {
            width: 170px;
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Block)
