import React, {useEffect} from 'react'
import {withGlobal} from '../../shared/app'
import {Btn} from '../../misc/buttons'
import Timeline from '../../widgets/timeline'
import TOS from '../../widgets/tos'
import GDPR from '../../widgets/gdpr'
import {string} from '../../../lib/utils'

const Header = () => (
  <div className='col-12 text-center mx-auto py-3'>
    <img
      src='/img/brand/logo.png'
      className='logo'
      alt='inmemori.com'
      height='80px'
      style={{filter: 'invert(0)'}}
    />
    <style jsx>
      {`
        img {
          width: 110px;
        }
        @media (max-width: 767px) {
          // md down
          img {
            width: 90px !important;
          }
        }
      `}
    </style>
  </div>
)

const HomeFooter = ({t, btn, onClickMobile, onClickDesktop}) => {
  const tosContent = t(`onboarding.tos.text`)
  const tosBtn = t(`onboarding.tos.btn`)
  const link = t(`onboarding.tos.link`)

  const fullContent = tosContent.split(tosBtn)

  fullContent.splice(
    1,
    0,
    <a rel='noreferrer' target='_blank' className='link' href={link}>
      {tosBtn}
    </a>
  )

  return (
    <div className='col-11 col-md-10 mx-auto text-center'>
      <Btn
        onClick={onClickMobile}
        value={btn}
        style={{width: 'fit-content'}}
        className='d-block d-md-none splash-cta btn btn-sm btn-primary m-auto'
      />
      <Btn
        onClick={onClickDesktop}
        value={btn}
        style={{width: 'fit-content'}}
        className='d-none d-md-block splash-cta btn btn-sm btn-primary m-auto'
      />

      <style jsx>
        {`
          span {
            font-size: 10px;
            color: #9e9e9e;
          }

          .link {
            cursor: pointer;
            color: #32735f;
            text-decoration: underline !important;
            text-align: justify;
          }

          @media (max-width: 500px) {
            // sm down
            span {
              font-size: 8px;
            }
          }
        `}
      </style>
    </div>
  )
}

const BaseLine = ({page, t, managerKey}) => {
  return (
    <div className='mt-auto mb-auto d-flex flex-column mx-auto w-100'>
      <div className='col-11 col-md-10 mx-auto text-left text-md-left'>
        <div
          className='h1'
          dangerouslySetInnerHTML={{__html: t(`onboarding.${managerKey}.h1`, page)}}
        />
        <p>{t(`onboarding.${managerKey}.subtitle`, page)}</p>
      </div>

      <img
        height='100px'
        className='mx-auto mt-4'
        src='https://res.cloudinary.com/inmemori/image/upload/v1589456088/web-assets/Don.svg'
      />

      <style jsx>
        {`
          img {
            height: 100px;
          }
          @media (max-width: 991px) {
            // lg down
            h1 {
              font-size: 28px;
            }
          }
          @media (max-width: 320px) {
            // sm down
            h1 {
              font-size: 20px;
            }
            p {
              font-size: 14px;
            }
          }
        `}
      </style>
    </div>
  )
}

const Splash = ({t, page, api, _profile, hasRole, setFullscreen, flow, ABTest}) => {
  const managerKey = hasRole('manager')
    ? page.partner._id
      ? 'manager-partner'
      : 'manager-google'
    : 'user'

  const close = (f, p) => {
    p = p || {random: string.random()}
    flow.set(f, p)
    try {
      api.post(`/profiles/checklist/splash-manager/true`)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    setFullscreen(true)
    const h = $(window).height()
    $('.splash-right').height(h)
    const h2 = $('.splash-left').height()
    $('.splash-right').height(h2)
    return () => setFullscreen(false)
  }, [])

  return (
    <div className='splashbox'>
      <div className='d-flex h-100'>
        <div className='splash-left container-fluid d-flex flex-column justify-content-between'>
          <div className='row h-100'>
            <Header />
            <BaseLine page={page} t={t} managerKey={managerKey} />
            {/*<Marketing t={t} managerKey={managerKey} />*/}
            <HomeFooter
              t={t}
              btn={t(`onboarding.${managerKey}.cta.customize`)}
              onClickMobile={() => close('tab-memories', {splash: true})}
              onClickDesktop={() => close('tab-infos')}
            />
            <div className='col-11 col-md-10 mx-auto text-left text-md-left'>
              <TOS />
              <GDPR />
            </div>
          </div>
        </div>

        <div className='splash-right px-4'>
          <Timeline />
        </div>
      </div>

      <style jsx>
        {`
          .splashbox {
            background: rgb(255, 255, 255);
            // background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 100%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
          .splash-left {
            flex: 1;
          }
          .splash-right {
            flex: 1.4;
          }

          @media (max-width: 767px) {
            // md down
            .splash-right {
              display: none;
            }
          }

          @media (max-width: 991px) {
            // lg down
            .splash-right {
              flex: 1;
            }
          }
        `}
      </style>
    </div>
  )
}

export default withGlobal(Splash)
