const Content = ({children, className = ''}) => {
  return (
    <div className={`app-container container ${className}`}>
      {children}

      <style jsx global>
        {`
          // md down
          @media (max-width: 767px) {
            .app-container > .row > [class*='col-'] {
              padding: 0 !important;
            }
          }

          // lg down
          @media (max-width: 991px) {
            .app-container {
              min-width: 100%;
            }
          }
        `}
      </style>
    </div>
  )
}

export default Content
